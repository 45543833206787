import twitterImg from "../../../../static/img/twitterimg.png"
import ogImg from "../../../../static/img/ogimg.png"
export const setMeta = (data) => {
    let pagetitle = data.meta.seo_title;
    if (pagetitle) {
        document.title = pagetitle;
    }

    // Set meta description
    let pagedesc = data.meta.search_description;
    if (pagedesc) {
        const metaDescTag = document.createElement('meta');
        metaDescTag.setAttribute('name', 'description');
        metaDescTag.setAttribute('content', pagedesc);

        const existingMetaDescTag = document.querySelector('meta[name="description"]');
        if (existingMetaDescTag) {
            existingMetaDescTag.remove();
        }

        document.head.appendChild(metaDescTag);
    }

    // Set Open Graph tags
    const ogTags = [
        { property: 'og:title', content: pagetitle },
        { property: 'og:description', content: pagedesc },
        { property: 'og:image', content: "https://lundbergandassociates.com"+ogImg|| '' }, // Provide a default or dynamic image
        { property: 'og:url', content: window.location.href },
        { property: 'og:type', content: 'website' } // Default type, adjust if necessary
    ];

    ogTags.forEach(tag => {
        const existingTag = document.querySelector(`meta[property="${tag.property}"]`);
        if (existingTag) {
            existingTag.remove();
        }

        const metaTag = document.createElement('meta');
        metaTag.setAttribute('property', tag.property);
        metaTag.setAttribute('content', tag.content);
        document.head.appendChild(metaTag);
    });

    // Set Twitter card tags
    const twitterTags = [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: pagetitle },
        { name: 'twitter:description', content: pagedesc },
        { name: 'twitter:image', content:  "https://lundbergandassociates.com"+twitterImg  },
        { name: 'twitter:url', content: window.location.href }
    ];

    twitterTags.forEach(tag => {
        const existingTag = document.querySelector(`meta[name="${tag.name}"]`);
        if (existingTag) {
            existingTag.remove();
        }

        const metaTag = document.createElement('meta');
        metaTag.setAttribute('name', tag.name);
        metaTag.setAttribute('content', tag.content);
        document.head.appendChild(metaTag);
    });
};
