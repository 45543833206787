import OneLineContactForm from "../../../../components/generalwidgets/oneLineContact";
import React from "react";

import { colors } from "../../../../../static/colors";
import { useMediaQuery } from "react-responsive";
import { mainLarge, mainSmall, secondaryLarge } from "../../../../../static/fonts";
import { Link } from "react-router-dom";
import ReusableLottieControl from "../../../../../static/animations/reusableAnimator";

const LoadGraphic = (props) => {
  if (props.tjanster) {
    return (
      <ReusableLottieControl size={props.size} animationData={props.list.img} />
    );
  }
  if (props.omrade) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
        style={{ width: props.size, height: props.size, color: colors.accent }}
      >
        {props.list.img}
      </svg>
    );
  }
  if (props.bransch) {
    return (
      <img
        src={props.list.img}
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
          filter: "brightness(50%)",
          transition: "filter .4s ease",
          borderRadius: "10px",
        }}
        alt="Automotive"
      ></img>
    );
  }
};

const MobileCol = (props) => {
  const size = "100px";
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyItems: "center",
        width:"80%", maxWidth:"700px",

        margin:"0 auto"
      }}
    >
      <LoadGraphic
        list={props.list}
        size={size}
        omrade={props.omrade}
        tjanst={props.tjanst}
        bransch={props.bransch}
      />
      <h1 style={secondaryLarge}>{props.list.title}</h1>
      <p style={{textAlign:"left",...mainSmall}}>{props.list.text}</p>
      <OneLineContactForm small={true} sprak={props.sprak} />
      <br />
    </div>
  );
};

const DesktopCol = (props) => {
  return (
    <div
      style={{
 
        margin:"60px auto",
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        gap:"0px",
        marginTop: "auto",
      }}
    >
  
        
        <OneLineContactForm small={true} sprak={props.sprak} />
        <div style={{display:"flex", flexDirection:"column", margin:"0 auto", width:"300px", paddingTop:"0px"}}>
          <Link style={{textDecoration:"none",color:"black"}} to={props.sprak.nav.omoss.url}>{props.sprak.kundersbetyg}  &rarr;</Link>
        <span style={{color:"gold"}}>★★★★★</span>
        
        </div>
  
      <div style={{width:"80%", maxWidth:"700px",}}>
        <h1 style={mainLarge}>{props.list.title}</h1>
        <p style={{textAlign:"left",...mainSmall}}>{props.list.text}</p>
      </div>
    </div>
  );
};

const HeroFour = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div
    id="asd"
      className="displayfields"
      style={{width:"100%",display:"flex", flexDirection:"column",
        background: `linear-gradient(to bottom right, ${colors.heroGradOne}, ${colors.heroGradTwo})`,
      }}
    >
        {isMobile ? (
          <MobileCol
            sprak={props.sprak}
            omrade={props.omrade}
            bransch={props.bransch}
            tjanst={props.tjanst}
            list={props.list}
          />
        ) : (
          <DesktopCol
            omrade={props.omrade}
            bransch={props.bransch}
            tjanst={props.tjanst}
            sprak={props.sprak}
            list={props.list}
          />
        )}
 
    </div>
  );
};
export default HeroFour;
