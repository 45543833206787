import React, { useState, useEffect } from 'react';
import { canonUrl, cookiesUrl, headers } from "../../../static/urls";
import DesktopComponent from "../../main";
import Hero from '../screencomponents/hero';
import CanonicalTag from '../../components/canonicalTag';
import { setMeta } from '../screencomponents/widget/metadesc';


function Gtc(props) {
  const canonicalUrl = canonUrl+ "/allmanna-villkor"

  useEffect(()=>{
    window.scrollTo(0, 0);
      let data1={
          meta:{
            seo_title:props.sprak.allmanna_villkor.pagetitle,
            search_description:props.sprak.allmanna_villkor.pagedesc
          }
        }
        // Set meta description
        setMeta(data1)
  },[])

  const containerStyle = {
    maxWidth: '1000px',    paddingTop: '150px',
    margin: "auto",
    paddingBottom:"150px"
  };

  const listStyle = {
    listStyleType: 'none', // Removes bullets
    paddingLeft: 0, // Removes default padding
  };

  const textStyle = {
    textAlign: 'left', // Aligns text to the left
  };
  return (
    <DesktopComponent sprak={props.sprak} light={true}>
                                      <CanonicalTag canonicalUrl={canonicalUrl} />

 
    <div style={containerStyle}>
      <h1>{props.sprak.gtc.title}</h1>
      <ul style={listStyle}>
        {props.sprak.gtc.items.map((item, index) => (
          <li key={index}>
            <h2 style={textStyle}>{item.title}</h2>
            <p style={textStyle}>{item.text}</p>
          </li>
        ))}
      </ul>
    </div>
  
        
    </DesktopComponent>
  );
}
export default Gtc;