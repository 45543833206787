import React, { useEffect, useState } from "react";

import DesktopComponent from "../../main";
import { Link } from "react-router-dom";
import "../../components/generalwidgets/css/oneline.css";
import { useParams } from "react-router-dom";
import heroImg from "../../../static/img/hero1.webp";
import {
  canonUrl,
  exportMainUrl,
  findSlug,
  findSlugPage,
  headers,
  starttillslutUrl,
} from "../../../static/urls";
import CanonicalTag from "../../components/canonicalTag";
import { setMeta } from "../screencomponents/widget/metadesc";
import "../../../static/css/hero1.css";
import "../screencomponents/widget/css/heroimg.css";
import "./css/avtal.css";
import "./css/starttillslut.css";
import { secondaryMediumSmall, mainSmall } from "../../../static/fonts";
import "../../../static/css/colors.css";
import "../screencomponents/css/hero.css";

import Accordion from "../screencomponents/widget/accordion";
import Modal from "../screencomponents/widget/modal";
import { useMediaQuery } from "react-responsive";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { sendform } from "../../../static/urls";
import { FormControl, FormHelperText } from "@mui/material";
import HomeKunder from "../screencomponents/homeKunder";
import Benefits from "../screencomponents/widget/benefits";
import Form from "../../components/generalwidgets/form";
import Btn from "../../components/generalwidgets/btn";
let form = {
  title: "Vi hjälper ditt företag – Hör av dig",
  text: "Lämna gärna dina kontaktuppgifter så kontaktar vi dig inom en timme för att diskutera din situation och dina juridiska behov. Den första konsultationen är helt gratis och du är inte bunden att anlita oss. Vi är även nästan alltid tillgängliga för ett samtal om du inte vill vänta.",
  name: "Namn",
  newsletter:
    "Intresserad av att hålla dig uppdaterad om vad som händer i juridiken?",
  telefon: "Telefonnummer",
  email: "Email",
  number: "Telefonnummer",
  body: "Vill du lägga till något?",
  termstext: "Jag har läst och godkänner integritetspolicyn. ",
  url: "",
  termsLink: "Läs den här",
};

const steg = {
  1: {
    url: "",
    fetch: "",
    fordelar: [
      "<b>Immaterialrätt</b> - Behöver du skydda ditt varumärke? Eller design? Behövs patent?", 
      "<b>Dataskydd</b> - Ska du behandla persondata? Vad krävs och vad är tillåtet?", 
      "<b>Delägare</b> - Behövs ett aktieägaravtal? Finns något annat som behövs för att allt ska gå på räls?", 
      "<b>Avtal</b> - Vilka avtal behövs för det ni ska sälja? Vilka vanliga problem uppstår?"],
    herotitle: "Fått en",
    titleaccent: "Snilleblixt",
    titlelast: "och förbereder dig för start?",
    herotext: "Medan du lägger all kraft på att förverkliga din idé ser vi till att hålla dig trygg. Oavsett om det är första gången eller tionde, har du säkert tänkte på hur du ska skydda den - vi hjälper dig!",
    tjanster:{h2:"Vanliga tjänster för dig som just fått den stora idéen", p:"Här har vi sammanställt många av de tjänster som behövs för dig, du kan antingen köpa dem bitvis eller genom ett paket. Hör av dig så kan vi ge ett förslag på vad som är viktigast och i vilken ordning som passar just dig!"},
    cta:["Kostnadsfri utvärdering", "En chefsjurist <br> All juridik på ett ställe", "Struktuerad & proaktiv plan <br> Ge ditt företag bättre förutsättningar"],
    info:{h2:"Vanliga frågor, bloggar och guider", p:"För att hjälpa dig navigera i juridiken",fragor:{h2:"Vanliga frågor", p:"Korta frågor med simplare svar."}, blogg:{h2:"Bloggar och guider",p:"Längre och mer djupgående information."}},
    overview:{title:"Din situation", text:"Att förstå hur du skyddar dig juridiskt samtidigt som företaget har andra behov när du startar företag kan vara sådant som du är omedveten om. Det är viktigt att tidigt hantera är aktieägaravtal, anställningsavtal, immaterialrätt och säljavtal. <br>Med en stabil grund kan du starta ditt företag med de bästa förutsättning att skala upp en lönsam affär! <br></br> <b>Boka en gratis konsultation och få en affärsjuridisk plan för ditt företag.</b>"}
  },
  2: {
    url: "",
    fetch: "",
    fordelar: [
      "<b>Försäljning</b> - Allmänna villkor, säljavtal och regelefterlevnad. Se till att din försäljning inte blir vad som drar ner dig ", 
      "<b>Inköp</b> - Ansvarsfrågor, uppsägning och tvister. Har du koll på vad avtalen du skriver på har för konsekvenser om något går fel?", 
      "<b>Anställa</b> - Anställningsavtal, policys, uppsägning och immateriella rättigheter. Hur minskar du risken att fastna med en anställd du vill bli av med? Har du koll på arbetsrättslagstiftning och har du reglerat så att anställda inte äger din immaterialrätt?"],
    herotitle: "Just",
    titleaccent: "Startat",
    titlelast: "och vill lägga en stabil grund?",
    herotext: "Just kommit i gång? Intäkterna har börjat komma likväl tankarna om att anställa. Nu är det viktiga att den nyvunna framgången inte blir en börda. ",
    tjanster:{h2:"Vanliga tjänster för dig som just kommit igång", p:"Här har vi sammanställt många av de tjänster som behövs för dig, du kan antingen köpa dem bitvis eller genom ett paket. Hör av dig så kan vi ge ett förslag på vad som är viktigast och i vilken ordning som passar just dig!"},
    cta:["Kostnadsfri utvärdering", "En chefsjurist <br> All juridik på ett ställe", "Struktuerad & proaktiv plan <br> Ge ditt företag bättre förutsättningar"],
    info:{h2:"Vanliga frågor, bloggar och guider", p:"För att hjälpa dig navigera i juridiken",fragor:{h2:"Vanliga frågor", p:"Korta frågor med simplare svar."}, blogg:{h2:"Bloggar och guider",p:"Längre och mer djupgående information."}},
    overview:{title:"Din situation", 
      text:"I hasten att komma i gång och få de första kunderna är det väldigt vanligt att juridiken åsidosätts. När du kommit igång är ett perfekt tillfälle att gå tillbaka och se över vilka luckor som lämnades. Med rätt råd kommer du kunna navigera den ökande komplexiteten som kommer med tillväxt utan problem. Ser du till att kunder och anställda inte orsakar problem om de är missnöjda eller ändrar sig kommer tillväxten gå gallant.<br></br> <b>Boka en gratis konsultation och få en affärsjuridisk plan för ditt företag.</b>"},
  },
    
  
  3: {
    url: "",
    fetch: "",
    fordelar: [
      "<b>Anställda</b> - I detta steget är det vanligt att anställda har blivit ett problem, vem får man sparka, vad krävs av er vid problem och har ni en plan för att undvika och lösa tvister?", 
      "<b>Styrelse och VD</b> - Vilket ansvar har en styrelse och är det dags att skaffa en VD? Se till att bolagsstämmorna är korrekt och att ni reglerar VD:ns ansvar och roll.", 
      "<b>Tvister</b> - När företag växer är det lätt att antalet tvister växer lika fort. Har ni en strategi för att minska tvister? Hur hanterar ni kundfordringar och får ni in tvistiga belopp?"],
    herotitle: "Dags för",
    titleaccent: "Tillväxt",
    titlelast: "- låt juridiken underlätta för dig!",
    herotext: "Snilleblixten har blivit ett 'riktigt' företag! Med mer att vinna kommer även mer att förlora, har ni koll på all juridik? Ofta börjar små konflikter uppstå i detta stadium. Medan företaget tuffar på är det viktigt att grundstenarna är på sin plats och att hålla kolla på nya lagstiftningar som kan påverka er.",

    tjanster:{h2:"Vanliga tjänster för dig som är i tillväxts stadiet", p:"Här har vi sammanställt många av de tjänster som behövs för dig, du kan antingen köpa dem bitvis eller genom ett paket. Hör av dig så kan vi ge ett förslag på vad som är viktigast och i vilken ordning som passar just dig!"},
    cta:["Kostnadsfri utvärdering", "En chefsjurist <br> All juridik på ett ställe", "Struktuerad & proaktiv plan <br> Ge ditt företag bättre förutsättningar"],
    info:{h2:"Vanliga frågor, bloggar och guider", p:"För att hjälpa dig navigera i juridiken",fragor:{h2:"Vanliga frågor", p:"Korta frågor med simplare svar."}, blogg:{h2:"Bloggar och guider",p:"Längre och mer djupgående information."}},
    overview:{title:"Din situation", text:"Företaget har börjat ta form, allt hårt slit har börjat löna sig. Ökad storlek innebär fler anställda, fler leverantörer, fler kunder och kanske fler konsulter – fler ställen konflikter kan uppstå. Med rätt avtal och rätt policys på plats är det inget att oroa sig för. På tal om policys har ni löpande koll på dataskydd? Eller har det hanterats en gång och lagts på hyllan, för nu börjar det vara dags att implementera löpande. Vi kan hjälpa er göra det mindre besvärligt. <br></br> <b>Boka en gratis konsultation och få en affärsjuridisk plan för ditt företag.</b>"},




  },
  4: {
    url: "",
    fetch: "",
    fordelar: 
      ["<b>Expansion</b> - Har ni börjat fundera på expansion? Franchise, licensavtal, agenter eller återföräljare. Är tanken nationell eller internationell?",
      "<b>Styrelse och VD</b> - Vilket ansvar har styrelsen? Vad är VD:ns uppgifter? Se till att ansvaret mellan olika bolagmän är tydligt definierat",
      "<b>Avtal</b> – Vid det här laget har företaget antagligen fler avtal med olika avtalspartners att hålla kolla på. Se till att ni har koll på dem så att ni inte förlorar intäkter eller har onödiga utgifter.",
      
    ],
    herotitle: "",
    titleaccent: "Scaleup",
    titlelast: "- att bli en marknadsledare börjar närma sig!",
    herotext: "Tåget är satt på räls, nu är det dags att sätta fart! Låt oss expandera och göra något stort! När intäkterna och arbetsbelastningen växer börjar det bli dags att anställa till chefspositioner. Ofta är expansion på tankarna, nya produkter, nya marknader, bli internationell? Vi hjälper dig med de nya utmaningarna! ",
    
    tjanster:{h2:"Vanliga tjänster för dig som är i scaleup stadiet", p:"Här har vi sammanställt många av de tjänster som behövs för dig, du kan antingen köpa dem bitvis eller genom ett paket. Hör av dig så kan vi ge ett förslag på vad som är viktigast och i vilken ordning som passar just dig!"},
    cta:["Kostnadsfri utvärdering", "En chefsjurist <br> All juridik på ett ställe", "Struktuerad & proaktiv plan <br> Ge ditt företag bättre förutsättningar"],
    info:{h2:"Vanliga frågor, bloggar och guider", p:"För att hjälpa dig navigera i juridiken",fragor:{h2:"Vanliga frågor", p:"Korta frågor med simplare svar."}, blogg:{h2:"Bloggar och guider",p:"Längre och mer djupgående information."}},
    overview:{title:"Din situation", text:"Vad är bästa sättet att fortsätta växa? Franchise, bilda en koncern eller växa genom tredje part som säljer åt dig? Vilken modell som funkar bäst beror på din branch, affärsmodell och dina ambitioner – oavsett vilken som passar har vi lång erfarenhet av att expandera stora företag. Utöver den trevligare problemställningen som just nämnts finns risken att tvistelösning blir en vanlig del av vardagen om dåliga avtal används eller personal inte koll på den juridik som är nödvändig i deras roll. <br> Att överväga en mer proaktiv juridisk strategi är ofta gynnsamt i detta steget. En chefsjurist som kan styra och allokera rätt kompetens vid rätt tillfälle.<br></br> <b>Boka en gratis konsultation och få en affärsjuridisk plan för ditt företag.</b>"},
  },
  5: {
    url: "",
    fetch: "",
    fordelar: [
    "<b>Expansion, fusion och vidareutveckling</b> - Finns det nya möjligheter till tillväxt, mjukvara som går att licensiera, nya säljkanaler eller är det dags att köpa en konkurrent eller leverantör? Det är antagligen dags att göra en större internationell expansion men vilka regioner och på vilket sätt?",
    "<b>Intrång och tvister</b> - Vare sig det är dataintrång, varumärkesintrång eller tvist, i den här storleken brukar det sitta en måltavla på ryggen. Är ni förberedda?",
    "<b>Avtal och avtalshantering</b> - Stora organisationer har många avtal, vem har koll på dem? När ska de sägas upp, har vad som utlovats mötts och finns behovet kvar? Se till att täcka de ekonomiska läckor som kommer med bortglömda avtal. ", 
    "<b>Regelefterlevnad (compliance)</b> - Allt från GDPR, sanktionslistor och konkurrensfrågor behöver bearbetas aktivt för att undvika att byta mot en av många lagstiftningar som ni måste följa. För att undvika böter.",
    ],    
    herotitle: "",
    titleaccent: "Enterprise",
    titlelast: "- för er stora företag som behöver styr på juridiken",
    herotext: "Det finns mycket som ska stämma, mycket ny lagstiftning som ska implementeras och ni flyger inte längre under myndigheters radar. Utan att ha tydlig juridisk strategi med en erfaren chefsjurist kan juridiken vara överväldigande. Vi har lång erfaranhet som både chefsjurist och styrelseledamot i enterprise bolag.",
    
    tjanster:{h2:"Vanliga tjänster för enterprise företag", p:"Här har vi sammanställt många av de tjänster som behövs för dig, du kan antingen köpa dem bitvis eller genom ett paket. Hör av dig så kan vi ge ett förslag på vad som är viktigast och i vilken ordning som passar just dig!"},
    cta:["Kostnadsfri utvärdering", "En chefsjurist <br> All juridik på ett ställe", "Struktuerad & proaktiv plan <br> Ge ditt företag bättre förutsättningar"],
    info:{h2:"Vanliga frågor, bloggar och guider", p:"För att hjälpa dig navigera i juridiken",fragor:{h2:"Vanliga frågor", p:"Korta frågor med simplare svar."}, blogg:{h2:"Bloggar och guider",p:"Längre och mer djupgående information."}},
    overview:{title:"Er situation", text:"Att hålla i de juridiska tyglarna mellan sälj, produkt, marknad etc. börjar ofta närma sig en heltidstjänst. Därutöver behövs kompetens i många rättsområden, vanligt är att det börjat plockats in externa konsulter i olika hörn och vanligtvis från många olika ställen. Att sätt en chefsjurist i centrum som hanterar, planerar och delegerar brukar vara bra lösning för att undvika en röra och en evigt växande konsult nota.   <br><br><b>Boka en gratis med en chefsjurist och få en affärsjuridisk plan för ert företag</b>, är mötet bra kan vi gå in och göra en granskning av hela företaget och hjälpa er få styr på juridiken.</b>"}
  },
  6: {
    url: "",
    fetch: "",
    fordelar: [
      
      "<b>Överlåtelse </b> - Se till att överlåtelsen går rätt till, slipp huvudvärken som annars kan komma efter.",
      "<b>Förhandling </b> - En bra förhandling leder till bättre villkor för dig när du säljer ditt företag.",
      "<b>IPO (börsintroduktion) </b> - Ett annat lönsamt sätt att sälja företaget är genom att börsintroducera det, vi kan hjälpa dig med föreberedelserna."
      
    ],
    herotitle: "Dags för en ",
    titleaccent: "Exit",
    titlelast: "",
    herotext: "Oavsett om det är dags för en sandstrand eller nästa stora idé om du vill göra en exit är det viktigt att det går rätt till, vi har lång erfarenhet av att sälja företag och hjälper dig till de bästa resultatet.",
    
    tjanster:{h2:"Vanliga tjänster för dig som vill göra en exit", p:"Här har vi sammanställt många av de tjänster som behövs för dig, du kan antingen köpa dem bitvis eller genom ett paket. Hör av dig så kan vi ge ett förslag på vad som är viktigast och i vilken ordning som passar just dig!"},
    cta:["Kostnadsfri utvärdering", "En chefsjurist <br> All juridik på ett ställe", "Struktuerad & proaktiv plan <br> Ge ditt företag bättre förutsättningar"],
    info:{h2:"Vanliga frågor, bloggar och guider", p:"För att hjälpa dig navigera i juridiken",fragor:{h2:"Vanliga frågor", p:"Korta frågor med simplare svar."}, blogg:{h2:"Bloggar och guider",p:"Längre och mer djupgående information."}},
    overview:{title:"Din situation", text:"Innan du har pengarna i handen är det flera steg som ska genomföras, allt från due diligence och förhandla till att skriva överlåtelseavtalet. Vilken form av överlåtelse, vilka exit klausuler och hur betalning ska ske är frågor att ta i beaktning. Vi har lång erfarenhet efter att ha sålt många företag, vi hjälper dig nå det resultat som passar dig bäst.  <br></br> <b>Boka en gratis konsultation och få en plan för hur du ska sälja ditt företag.</b>"}
  },
};

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}
const avtal = {
  fetchUrlAddon: "tjanst/",
  CTAs: {
    first_cta_url: "123",
    first_cta: "Beställ",
    second_cta: "Hör av dig!",
    second_cta_url: "asd",
  },
  modal: {
    title: "Boka möte",
    text: "Fyll i dina detaljer så återkommer vi med förslag på mötestid. Det gör vi oftast inom en timme!",
  },
  hur: {
    steg1: {
      title: "1. Boka möte",
      text: "Boka ett kostnadsfritt introduktionsmöte, då går vi igenom ditt företag för att få en inblick i vilka juridiska problem som rör ditt företag. Därefter gör vi en liten check på vad som finns och ger förslag på vad som bör göras. Du kan även passa på och ta med frågor du vill ha svar på.",
      img: "",
    },
    steg2: {
      title: "2. Beställ",
      text: "Har vi identifierat ett behov som du vill åtgärda skickar vi ett uppdragsavtal som du får signera, först därefter börjar vi jobba.",
      img: "",
    },
    steg3: {
      title: "3. Utför",
      text: "Vi utför jobbet du beställt.",
      img: "",
    },
    steg4: {
      title: "4. Leverans",
      text: "När vi är färdiga levererar vi produkten och tar ett kort möte och går igenom leveransen, om du vill.",
      img: "",
    },
  },
};

const HowItem = (props) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", flex:1 }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <h3 style={{ margin: 0 }}>{props.title}</h3>
      </div>
      <p style={{ margin: 0 }}>{props.text}</p>
    </div>
  );
};

const Hero = (props) => {
  if (!props.data) {
    return null; // or some loading spinner
  }
  return (
    <div className="avtal-hero-container">
      <div className="avtal-hero-left">
        <h1 className="avtal-main-hero-h1">
          {props.steg.herotitle}{" "}
          <span className="sts-accent-text">{props.steg.titleaccent}</span>{" "}
          {props.steg.titlelast}
        </h1>

        <p className="avtal-main-hero-p">{props.steg.herotext}</p>
        {props.steg && (
          <ul className="avtal-custom-list">
            {props.steg.fordelar.map((arg, index) => (
              <li dangerouslySetInnerHTML={{ __html:arg}} key={index}/>
            ))}
          </ul>
        )}
        <br></br>
        <Benefits sprak={props.sprak} small={true} />
      </div>
      <div className="avtal-hero-right">
        <div className="avtal-buy-now-card">
          <img
            src={props.img}
            className="avtal-hero-main-img"
            alt={props.img}
          />

          <p className="avtal-pris-p" style={{ textAlign: "center" }}>
            <b>{props.data.herotitle}</b>
          </p>
          <p className="avtal-pris">Hör av dig!</p>
          <p className="avtal-pris-p">
            Första mötet är gratis och kommer inte med ett köpkrav. Få hjälp med en juridisk plan för ditt företag så att du kan undvika onödiga tvister
          </p>

          <br></br>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={props.sprak.nav.omoss.url}
          >
            {props.sprak.kundersbetyg} &rarr;
          </Link>
          <span style={{ color: "gold" }}>★★★★★</span>
          <br></br>
          <div className="avtal-button-container">
            <button onClick={props.openModal} className="avtal-cta-button">
              Boka möte
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

function StartTillSlut(props) {
  const steget = props.steg;
  const [showMore, setShowMore] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(3);
  const [data, setData] = useState({});
  const canonicalUrl = canonUrl + steg[steget].url;
  let pageurl =  starttillslutUrl+steget;
  const csrftoken = getCookie("csrftoken");
  const [formData, setFormData] = useState({
    body: "",
    page: "",
    email: "",
    name: "",
    number: "",

    newsletter: false,
    terms: false,
  });

  const [errorData, setErrorData] = useState({
    body: false,
    email: false,
    terms: false,
    number: false,
    name: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("event", "conversion_event_submit_lead_form", {});
    let hasError = false;
    if (formData.terms === false) {
      setErrorData((prevState) => ({ ...prevState, terms: true }));
      hasError = true;
    }

    if (formData.number === "") {
      setErrorData((prevState) => ({ ...prevState, number: true }));
      hasError = true;
    }
    if (formData.name === "") {
      setErrorData((prevState) => ({ ...prevState, name: true }));
      hasError = true;
    }
    if (formData.email === "") {
      setErrorData((prevState) => ({ ...prevState, email: true }));
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },

      body: JSON.stringify(formData),
    };

    fetch(sendform, requestOptions)
      .then((response) => {
        if (response.ok) {
          window.location.href = props.sprak.tack.url;
        } else {
          console.error("Form submission failed");
        }
        return response.json();
      })
      .then((data) => console.log(data))
      .catch((error) => console.error("Error submitting form:", error));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchDataFromAPI = async () => {
      try {
        const response = await fetch(pageurl);
        const data = await response.json();
        setData(data);
        const path = window.location.pathname.split("/")[1];
        let data1={
          meta:{
            seo_title:props.sprak[path].pagetitle,
            search_description:props.sprak[path].pagedesc
          }
        }
        // Set meta description
        setMeta(data1)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataFromAPI();

       

    if (isSmallScreen2) {
      setShowMoreButton(3);
    } else if (isMediumScreen) {
      setShowMoreButton(6);
    } else {
      setShowMoreButton(11);
    }
  }, [pageurl]);

  const isSmallScreen = useMediaQuery({ maxWidth: 600 });

  const handleShowMoreToggle = () => {
    setShowMore(!showMore);
  };

  const visibleItems = showMore ? data?.tjanst : data?.tjanst?.slice(0, 12);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setFormData({ ...formData, page: data.title });

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const isSmallScreen2 = useMediaQuery({maxWidth:500});
  const isMediumScreen = useMediaQuery({maxWidth:800});
  
  // Determine number of items to show based on screen size
  
 

  return (
    <DesktopComponent sprak={props.sprak} light={true}>
      <CanonicalTag canonicalUrl={canonicalUrl} />
      <Hero
        data={data}
        steg={steg[steget]}
        img={heroImg}
        sprak={props.sprak}
        openModal={openModal}
      />
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div style={{ padding: 10 }}>
          <h4
            style={{
              ...secondaryMediumSmall,
              margin: " 0 0 5px 0",
              padding: 0,
              textAlign: "left",
            }}
          >
            {avtal.modal.title}
          </h4>
          {!props.notext && (
            <>
              <p
                style={{
                  ...mainSmall,
                  textAlign: "left",
                  margin: 0,
                  padding: 0,
                  widt: "90%",
                }}
              >
                {avtal.modal.text}
                <br></br>
                <br></br>
              </p>

              <div style={{ marginLeft: "-50px" }}></div>
            </>
          )}
          <div
            style={{
              padding: "0 20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form
              onSubmit={handleSubmit}
              noValidate
              style={{
                padding: "0 20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                style={{ flex: "1", marginRight: isSmallScreen ? 40 : "unset" }}
                error={errorData.name}
                helperText={errorData.name ? "Skriv in ditt namn." : ""}
                id="standard-basic"
                label={form.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                variant="standard"
              />

              <br></br>

              <TextField
                style={{ flex: "1", marginRight: isSmallScreen ? 40 : "unset" }}
                error={errorData.email}
                helperText={errorData.email ? "Fyll i din mail." : ""}
                id="standard-basic"
                label={form.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                variant="standard"
              />
              <br></br>
              <TextField
                style={{ flex: "1", marginRight: isSmallScreen ? 40 : "unset" }}
                error={errorData.number}
                helperText={
                  errorData.number ? "Fyll i ditt telefonnummer." : ""
                }
                id="standard-basic"
                label={form.number}
                onChange={(e) =>
                  setFormData({ ...formData, number: e.target.value })
                }
                variant="standard"
              />
              <br></br>

              <TextField
                style={{ flex: "1", marginRight: isSmallScreen ? 40 : "unset" }}
                id="standard-basic"
                onChange={(e) =>
                  setFormData({ ...formData, body: e.target.value })
                }
                label={form.body}
                error={errorData.body}
                helperText={errorData.body ? "Skriv ett meddelande." : ""}
                multiline
                maxRows={4}
                variant="standard"
              />

              <br></br>

              <FormControl
                required
                error={errorData.terms}
                helperText={errorData.terms ? "You must agree to terms." : ""}
                component="fieldset"
                sx={{ m: 0 }}
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.terms}
                        onChange={(e) =>
                          setFormData({ ...formData, terms: e.target.checked })
                        }
                      />
                    }
                    label={
                      <div style={{ textAlign: "left" }}>
                        <Typography>
                          {form.termstext}
                          <a href={props.sprak.privacy.url}>{form.termsLink}</a>
                        </Typography>
                      </div>
                    }
                  />
                </FormGroup>
                <FormHelperText>
                  {errorData.terms && <>Integritetspolicyn måste godkännas</>}
                </FormHelperText>
              </FormControl>

              {isSmallScreen && (
                <>
                  <br></br>
                  <br></br>
                </>
              )}

              <FormControl
                component="fieldset"
                sx={{ m: 0 }}
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.newsletter}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            newsletter: e.target.checked,
                          })
                        }
                      />
                    }
                    label={
                      <div style={{ textAlign: "left" }}>
                        <Typography>{form.newsletter}</Typography>
                      </div>
                    }
                  />
                </FormGroup>
              </FormControl>

              {isSmallScreen && (
                <>
                  <br></br>
                  <br></br>
                </>
              )}
              <Button
                variant="outlined"
                onClick={handleSubmit}
                endIcon={<ArrowForwardIcon />}
                style={{
                  width: "100px",
                  alignSelf: "end",
                  color: "black",
                  borderColor: "black",
                  marginRight: "20px",
                  marginBottom: "20px",
                }}
              >
                {props.sprak.skicka}
              </Button>
            </form>
          </div>
        </div>
      </Modal>

      <div className="avtal-second-container">
        <div className="avtal-second-column">
          <h2>{steg[steget].overview.title}</h2>
         
          <p dangerouslySetInnerHTML={{ __html: steg[steget].overview.text }} />
          <br></br>
          <br></br>
          <br></br>

          <div className="sts-cta-grid">
  <div className="sts-cta-card">
    <p dangerouslySetInnerHTML={{ __html: steg[steget].cta[0] }} />
  </div>
  <div className="sts-cta-card">
    <p dangerouslySetInnerHTML={{ __html: steg[steget].cta[1] }} />
  </div>

  <div className="sts-cta-card">
    <p dangerouslySetInnerHTML={{ __html: steg[steget].cta[2] }} />
  </div>
</div>
<br></br>
      <button onClick={openModal}  className='sts-cta-button'> 
        Boka möte
      </button>
          </div>
        <div className="avtal-second-side">
          {Object.values(avtal.hur).map((item, index) => (
            <>
              <br></br>
              <HowItem key={index} title={item.title} text={item.text} />
            </>
          ))}
          <br></br>
          <br></br>
        </div>
      </div>



      <HomeKunder trust={data.trust} />

<div className="sts-tjanster">
  <h2 className="sts-tjanster-h2">{steg[steget].tjanster.h2}</h2>
  <p className="sts-tjanster-p">{steg[steget].tjanster.p}</p>
        <div className="sts-tjanster-div">

        {data.tjanst && data.tjanst.length > 0 && visibleItems.map((item, index) => (
        <div className="sts-tjanster-div-child" key={index}>
          <h3>{item.title}</h3>
          <p>{item.intro}</p>
          <p>Pris {item.pris} kr</p>
          <Link className="sts-link" to={"/tjanster/" + item.slug}>Läs mer &rarr;</Link>
        </div>
      ))}

</div>
      {data.tjanst && data.tjanst.length > showMoreButton && (
        <>
        <br></br>
        <button className="sts-cta-button" onClick={handleShowMoreToggle}>
          {showMore ? 'Visa mindre' : 'Visa mer'}
        </button></>
      )}
    </div>
    <div className="sts-tjanster">
    <h2 className="margin-zeroish">{steg[steget].info.h2}</h2>
    <p className="margin-zeroish">{steg[steget].info.p}</p>
    <br></br>
    <h3 className="margin-zeroish">{steg[steget].info.fragor.h2}</h3>
    <p className="margin-zeroish">{steg[steget].info.fragor.p}</p>
        <div className="sts-tjanster-div">

    {data.questions && data.questions.length>0 &&data.questions.map((item, index) => (
        <div className="sts-tjanster-div-child" key={index}>
          <h3>{item.title}</h3>
          <p>{item.intro}</p>
          <p>Pris {item.pris} kr</p>
          <Link className="sts-link" to={"/vanliga-fragor/"+item.slug}>Läs mer &rarr;	</Link>
        </div>
      ))}
    </div>
    </div>

    <div className="sts-tjanster">
    <h3 className="margin-zeroish">{steg[steget].info.blogg.h2}</h3>
    <p className="margin-zeroish"> {steg[steget].info.blogg.p}</p>
    <br></br>
        
        <div className="sts-tjanster-div">

    {data.articles && data.articles.length>0 &&data.articles.map((item, index) => (
        <div className="sts-tjanster-div-child" key={index}>
          <h3>{item.title}</h3>
          <p>{item.intro}</p>
          <p>Pris {item.pris} kr</p>
          <Link className="sts-link" to={"/blogg/"+item.slug}>Läs mer &rarr;	</Link>
        </div>
      ))}
    </div>
    </div>


      {steg[steget].tjanstefraga && steg[steget].tjanstefraga.length > 0 && (
        <Accordion questions={steg[steget].tjanstefraga} />
      )}

<Form sprak={props.sprak} />
<br></br>
    </DesktopComponent>
  );
}

export default StartTillSlut;
