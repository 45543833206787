import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import "./css/priserform.css";
import { pricingform } from "../../../../static/urls";
import { FormControl, FormHelperText } from "@mui/material";

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + "=")) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const PriserForm = (props) => {
    return <BasicForm obj={props.obj} sprak={props.sprak} selected={props.selected} />;
};

const BasicForm = (props) => {
    const csrftoken = getCookie("csrftoken");
    const [formData, setFormData] = useState({
        pkg: null,
        body: "",
        page: "",
        email: "",
        name: "",
        number: "",
        newsletter: false,
        terms: false,
    });

    const [errorData, setErrorData] = useState({
        body: false,
        email: false,
        terms: false,
        number: false,
        name: false,
        pkg: null,
    });

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        // Update formData with selected pkg before submitting
        setFormData((prev) => {
            const updatedData = { ...prev, pkg: props.selected };
            submitForm(updatedData);
            return updatedData;
        });
    };

    const submitForm = (finalData) => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        gtag("event", "conversion_event_submit_lead_form", {});

        let hasError = false;

        if (!finalData.terms) {
            setErrorData((prev) => ({ ...prev, terms: true }));
            hasError = true;
        }
        if (finalData.body === "") {
            setErrorData((prev) => ({ ...prev, body: true }));
            hasError = true;
        }
        if (finalData.number === "") {
            setErrorData((prev) => ({ ...prev, number: true }));
            hasError = true;
        }
        if (finalData.name === "") {
            setErrorData((prev) => ({ ...prev, name: true }));
            hasError = true;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Validates standard email formats

        if (!emailRegex.test(finalData.email)) {
            setErrorData((prev) => ({ ...prev, email: true }));
            hasError = true;
        }
        

        if (hasError) return;

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrftoken,
            },
            body: JSON.stringify(finalData),
        };

        fetch(pricingform, requestOptions)
            .then((response) => {
                if (response.ok) {
                    window.location.href = props.sprak.tack.url;
                } else {
                    console.error("Form submission failed");
                }
                return response.json();
            })
            .then((data) => console.log(data))
            .catch((error) => console.error("Error submitting form:", error));
    };


    return (
        <div className="priserform-container">
            <div className="priserform-benefits">
                <h2>Ditt val</h2>
                <h3>{props.obj.title}</h3>
                <p>{props.obj.txt}</p>
            </div>

            <div style={{ padding: "0 20px", display: "flex", flexDirection: "column" }}>
                <form onSubmit={handleSubmit} noValidate style={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                        style={{ flex: "1" }}
                        error={errorData.name}
                        helperText={errorData.name ? "Skriv in ditt namn." : ""}
                        label="Namn"
                        onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))}
                        variant="standard"
                    />

                    <TextField
                        style={{ flex: "1" }}
                        error={errorData.email}
                        helperText={errorData.email ? "Fyll i din mail." : ""}
                        label="Email"
                        onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))}
                        variant="standard"
                    />

                    <TextField
                        style={{ flex: "1" }}
                        error={errorData.number}
                        helperText={errorData.number ? "Fyll i ditt telefonnummer." : ""}
                        label="Telefonnummer"
                        onChange={(e) => setFormData((prev) => ({ ...prev, number: e.target.value }))}
                        variant="standard"
                    />

                    <TextField
                        style={{ flex: "1" }}
                        label="Beskriv din situation"
                        onChange={(e) => setFormData((prev) => ({ ...prev, body: e.target.value }))}
                        error={errorData.body}
                        helperText={errorData.body ? "Skriv ett meddelande." : ""}
                        multiline
                        maxRows={4}
                        variant="standard"
                    />

                    <FormControl required error={errorData.terms} component="fieldset">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.terms}
                                        onChange={(e) => setFormData((prev) => ({ ...prev, terms: e.target.checked }))}
                                    />
                                }
                                label={
                                    <div style={{ textAlign: "left" }}>
                                        <Typography>
                                            Jag har läst och godkänner integritetspolicyn.{" "}
                                            <a href={props.sprak.privacy.url}>Läs den här</a>
                                        </Typography>
                                    </div>
                                }
                            />
                        </FormGroup>
                        {errorData.terms && <FormHelperText>Integritetspolicyn måste godkännas</FormHelperText>}
                    </FormControl>

                    <FormControl component="fieldset">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.newsletter}
                                        onChange={(e) =>
                                            setFormData((prev) => ({ ...prev, newsletter: e.target.checked }))
                                        }
                                    />
                                }
                                label={<Typography>Intresserad av att hålla dig uppdaterad?</Typography>}
                            />
                        </FormGroup>
                    </FormControl>

                    <Button
                        variant="outlined"
                        onClick={handleSubmit}
                        endIcon={<ArrowForwardIcon />}
                        style={{
                            width: "100px",
                            alignSelf: "end",
                            color: "black",
                            borderColor: "black",
                            marginRight: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        {props.sprak.skicka}
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default PriserForm;
