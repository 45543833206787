
import "../../../static/css/hero1.css";
import { useMediaQuery } from "react-responsive";
import growth from "../../../static/animations/growth.json";
import rocket from "../../../static/animations/rocket.json";
import enterprise from "../../../static/animations/enterprise.json";
import startup from "../../../static/animations/startup.json";
import lightbulb from "../../../static/animations/lightbulb.json";
import fly from "../../../static/animations/fly.json";
import ReusableLottieControl from "../../../static/animations/reusableAnimator.js";
import { mainSmall, secondarySmall } from "../../../static/fonts.js";
import "./css/affaren.css"
import { Link } from "react-router-dom";


const a = {
  ide: {
    title: "Idéen",
    link: "",
    url:"/ide",
    linkText: "Idé till företag",
    text: "Bra affärsidéer finns det många av, men det kan vara svårt att veta hur man går från ritbordet till att förverkliga den.",
    vadgorvi: ["Bolagsstruktur", "Tillstånd", "Skydda idéen"],
    icon: lightbulb,
  },
  start: {
    title: "Starten",
    link: "",
    url:"/start",
    linkText: "Fullfart framåt",
    text: "Starten av företaget består av många steg och mycket formalia som ska vara i ordning, att få allt rätt direkt kan vara klurigt.",
    vadgorvi: ["Avtal", "Registrera bolag", "Dataskydd"],
    icon: startup,
  },
  vaxer: {
    title: "Tillväxt",
    url:"/tillvaxt",
    link: "",
    linkText: "Tillväxt resan",
    text: "Att få tillväxt är många företagares mål, men hur man går till väga är inte alltid solklart.",
    vadgorvi: [
      "Anställningsavtal",
      "Sälj avtal",
      "Konsumentvillkor",
      "Distributörer",
      "Inköp",
    ],
    icon: growth,
  },
  scaling: {
    title: "Scaling",
    url:"/scale",
    link: "",
    linkText: "Bygg företaget",
    text: "Det är nu dags att sätta tåget på räls och öka farten.",
    vadgorvi: [
      "Expansionsstrategier",
      "Internationella marknader",
      "Tvister",
      "Försäljningsvillkor",
      "Compliance",
      "Dataskydd",
    ],
    icon: rocket,
  },
  enterprise: {
    title: "Enterprise",
    url:"/enterprise",
    link: "",
    linkText: "Storbolag",
    text: "I det här stadiet brukar juridiken bli mer påtaglig, och vikten av en bra juridisk grund mer uppenbar. ",
    vadgorvi: [
      "Avtal",
      "Tvister",
      "Compliance",
      "Konkurrens",
      "Dataskydd",
      "Personal",
    ],
    icon: enterprise,
  },

  exit: {
    title: "Exit",
    url:"/exit",
    link: "",
    linkText: "Gör en exit",
    text: "Dags för nästa utmaning? Se till att du avslutar på topp",
    vadgorvi: ["Företagsöverlåtelser", "Due diligence", "Förhandlingsstöd", ""],
    icon: fly,
  },
};
/*
      <div className="affaren-item-header-div" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div className="affaren-item-lottie">
          <ReusableLottieControl
            size={isMobile ? "75px" : "100px"}
            animationData={props.icon}
          />
        </div>
        <h3 style={{ textAlign: 'left' }}>{props.title}</h3>
      </div>
      <p style={{ marginTop: "0px", marginBottom: "auto", textAlign: "left" }}>
        {props.text}
      </p>
      <div style={{ width: "300px" }}>
        <h5 style={{ marginBottom: 5, textAlign: "left" }}>
          Vad vi kan hjälpa till med
        </h5>
        {props.vadgorvi.map((item, index) => (
          <p style={{ textAlign: "left", margin: 5 }} key={index}>
            {item}
          </p>
        ))}
      </div>
*/
const AffarenItem = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <Link className="affaren-item-container" to={props.url}>
      <div className="affaren-item-header-div">
        <div className="affaren-item-lottie">
          <ReusableLottieControl
            size={isMobile ? "75px" : "100px"}
            animationData={props.icon}
          />
        </div>
        <h3 className="affaren-item-title">{props.title}</h3>
      </div>
      <div className="affaren-item-content">
        <p className="affaren-item-text">
          {props.text}
        </p>
        <div className="affaren-item-arrow-container">
          <div className="affaren-item-arrow">
            &#8594;
          </div>
        </div>
      </div>
      <div className="affaren-item-help">
        <h5 className="affaren-item-help-title">Vad vi kan hjälpa till med</h5>
        {props.vadgorvi.map((item, index) => (
          <p className="affaren-item-help-list" key={index}>
            {item}
          </p>
        ))}
      </div>
    </Link>
  );
}


const Affaren = (props) => {
  return (
    <div className="affaren-container">
      {Object.values(a).map((item, index) => (
        <AffarenItem key={item.title} {...item} />
      ))}
    </div>
  );
};

export default Affaren;
