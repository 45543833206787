import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { useLocation } from 'react-router-dom';

import { colors } from "../../../static/colors";
import { mainSmall, secondaryMediumSmall } from "../../../static/fonts";
import { sendform } from "../../../static/urls";
import "./css/form.css"
import Benefits from "../../screens/screencomponents/widget/benefits";
import { FormControl, FormHelperText, FormLabel } from "@mui/material";
let form =  {
  title: "Vi hjälper ditt företag – Hör av dig",
  text: "Lämna gärna dina kontaktuppgifter så kontaktar vi dig inom en timme för att diskutera din situation och dina juridiska behov. Den första konsultationen är helt gratis och du är inte bunden att anlita oss. Vi är även nästan alltid tillgängliga för ett samtal om du inte vill vänta.",
  name: "Namn",
  newsletter:"Intresserad av att hålla dig uppdaterad om vad som händer i juridiken?",
  telefon: "Telefonnummer",
  email: "Email",
  number:"Telefonnummer",
  body: "Beskriv din situation",
  termstext: "Jag har läst och godkänner integritetspolicyn. ",
  url: "",
  termsLink: "Läs den här",
}
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const Form = (props) => {
  const csrftoken = getCookie("csrftoken");
  const [formData, setFormData] = useState({
    body: "",
    page:"asd",
    email: "",
    name:"",
    number:"",
    
    newsletter: false,
    terms: false,
    
  });
  const location = useLocation();
  const currentUrl = location.pathname; // URL path

  const [errorData, setErrorData] = useState({
    body: false,
    email: false,
    terms: false,
    number:false,
    name: false,
  });

  const handleSubmit = (e) => {

    setFormData({ ...formData, page:  currentUrl})
    
    e.preventDefault(); // Prevent default form submission behavior
    window.dataLayer = window.dataLayer || [];
    
    function gtag(){window.dataLayer.push(arguments);}
    gtag('event', 'conversion_event_submit_lead_form', {
    })
    let hasError = false;
    const updatedFormData = {
      ...formData,
      page: currentUrl,
    };
    
    if (formData.terms === false) {
      setErrorData((prevState) => ({ ...prevState, terms: true }));
      hasError = true;
    }
    if (formData.body === "") {
      setErrorData((prevState) => ({ ...prevState, body: true }));
      hasError = true;
    }
    if (formData.number === "") {
      setErrorData((prevState) => ({ ...prevState, number: true }));
      hasError = true;
    }
    if (formData.name === "") {
      setErrorData((prevState) => ({ ...prevState, name: true }));
      hasError = true;
    }
    if (formData.email === "") {
      setErrorData((prevState) => ({ ...prevState, email: true }));
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },

      body: JSON.stringify(updatedFormData),
    };

    
     fetch(sendform, requestOptions)
            .then((response) => {
                if (response.ok) {
                    window.location.href = props.sprak.tack.url;
                } else {
                    console.error('Form submission failed');
                }
                return response.json();
            })
            .then((data) => console.log(data))
            .catch(error => console.error('Error submitting form:', error));
        
            
  };

  const isSmallScreen = useMediaQuery({ maxWidth: 600 });

  return (
    <div
    className="form-container "
    >
<h4
        style={{
          ...secondaryMediumSmall,
          margin: " 0 0 5px 0",
          padding: 0,
          textAlign: "left",
        }}
        >
          {props.sprak.form.title}
          </h4>
     {!props.notext &&
<>
      <p style={{ ...mainSmall, textAlign: "left", margin: 0, padding: 0, widt:"90%" }}>
        {props.sprak.form.text}
      </p>
     

      <div style={{ marginLeft: "-50px" }}>
      </div>
        </>
      }
      <div
    style={{ padding: "0 20px", display: "flex", flexDirection: "column" }}
      >

      
        <form onSubmit={handleSubmit} noValidate style={{ padding: "0 20px", display: "flex", flexDirection: "column"}}>

        <TextField
          style={{ flex: "1", marginRight: isSmallScreen ? 40 : "unset" }}
          error={errorData.name}
          helperText={errorData.name ? "Skriv in ditt namn." : ""}
          id="standard-basic"
          label={form.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          variant="standard"
        />

        <br></br>

        <TextField
          style={{ flex: "1", marginRight: isSmallScreen ? 40 : "unset" }}
          
          error={errorData.email}
            helperText={errorData.email ? "Fyll i din mail." : ""}
            
          id="standard-basic"
          label={form.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          variant="standard"
        />
        <br></br>
        <TextField
          style={{ flex: "1", marginRight: isSmallScreen ? 40 : "unset" }}
          
          error={errorData.number}
            helperText={errorData.number ? "Fyll i ditt telefonnummer." : ""}
            
          id="standard-basic"
          label={form.number}
          onChange={(e) => setFormData({ ...formData, number: e.target.value })}
          variant="standard"
        />
        <br></br>

        <TextField
          style={{ flex: "1", marginRight: isSmallScreen ? 40 : "unset" }}
          id="standard-basic"
          onChange={(e) => setFormData({ ...formData, body: e.target.value })}
          label={form.body}

          error={errorData.body}
          helperText={errorData.body ? "Skriv ett meddelande." : ""}
 
          multiline
          maxRows={4}
          variant="standard"
        />

        <br></br>
     
            
 <FormControl
        required
        error={errorData.terms}   
             helperText={errorData.terms ? "You must agree to terms." : ""}

        component="fieldset"
        sx={{ m: 0 }}
        variant="standard"
      >
        
        <FormGroup>
         
          <FormControlLabel
            control={
              <Checkbox
       
                checked={formData.terms}
                onChange={(e) =>
                  setFormData({ ...formData, terms: e.target.checked })
                }
              />
            }
            label={
              <div style={{ textAlign: "left" }}>
                <Typography>
                  {form.termstext}
                  <a href={props.sprak.privacy.url}>
                    {form.termsLink}
                  </a>
                </Typography>
              </div>
            }
          />
        </FormGroup>
        <FormHelperText>
            
        {errorData.terms && <>
        Integritetspolicyn måste godkännas
        </>
        }
        </FormHelperText>
      </FormControl>
       
        {isSmallScreen && (
          <>
            <br></br>
            <br></br>
          </>
        )}
        
                   
 <FormControl


        component="fieldset"
        sx={{ m: 0}}
        variant="standard"
      >
        
        <FormGroup>

          <FormControlLabel
            control={
              <Checkbox
                 checked={formData.newsletter}
                onChange={(e) =>
                  setFormData({ ...formData, newsletter: e.target.checked })
                }
              />
            }
            label={
              <div style={{ textAlign: "left" }}>
                <Typography>{form.newsletter}</Typography>
              </div>
            }
          />
        </FormGroup>
        </FormControl>
   
        {isSmallScreen && (
          <>
            <br></br>
            <br></br>
          </>
        )}
        <Button
          variant="outlined"
          onClick={handleSubmit}
          endIcon={<ArrowForwardIcon />}
          style={{
            width: "100px",
            alignSelf: "end",
            color: "black",
            borderColor: "black",
            marginRight: "20px",
            marginBottom: "20px",
          }}
        >
          {props.sprak.skicka}
        </Button>
        </form>
      </div>
      
    </div>
  );
};

export default Form;
