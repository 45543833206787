


import React, { useState ,useEffect} from 'react';
import "./css/newsletter.css"
import { newsletter } from '../../../static/urls';
const NewsletterSignup = (props) => {

  const [theme, setTheme] = useState('light'); // light or dark
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');


  useEffect(() => {
    if (props.dark){
        setTheme('dark');
    }else{
        setTheme('light');
    }
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(newsletter, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email }),
    });

    if (response.ok) {
      // Navigate to the new site
      window.location.href = "/tack"; // Replace with the target URL
    } else {
      console.error('Form submission failed');
    }
  };

  return (
    <div className="newsletter-form-container">
   
      <h3 className="newsletter-h">Vill du ha vårt nyhetsbrev?</h3>

      <form onSubmit={handleSubmit} className='newsletterform-row'>
        <input
          type="text"
          placeholder="Namn"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="newsletter-form-input"
          />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="newsletter-form-input"
          />
        <button type="submit" className="newsletter-form-button">
          &rarr;
        </button>
      </form>
      
    </div>
  );
};


export default NewsletterSignup;
