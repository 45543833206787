import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import './css/modal.css'; // Add your styles here
import { useMediaQuery } from "react-responsive";
import { customofferform } from "../../../../static/urls";
import { mainSmall, secondaryMediumSmall } from "../../../../static/fonts";
let form =  {
    title: "Vi hjälper ditt företag – Hör av dig",
    text: "Lämna gärna dina kontaktuppgifter så kontaktar vi dig inom en timme för att diskutera din situation och dina juridiska behov. Den första konsultationen är helt gratis och du är inte bunden att anlita oss. Vi är även nästan alltid tillgängliga för ett samtal om du inte vill vänta.",
    name: "Namn",
    newsletter:"Intresserad av att hålla dig uppdaterad om vad som händer i juridiken?",
    telefon: "Telefonnummer",
    email: "Email",
    number:"Telefonnummer",
    foretag:"Företagsnamn",
    body: "Vill du lägga till något?",
    termstext: "Jag har läst och godkänner integritetspolicyn. ",
    url: "",
    termsLink: "Läs den här",
  }
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
}

const CustomOfferModal = (props) => {
    const isSmallScreen = useMediaQuery({ maxWidth: 600 });
    const csrftoken = getCookie("csrftoken");
     const [formData, setFormData] = useState({
        body: "",
        page:"",
        email: "",
        name:"",
        number:"",
        foretag:"",
        files:[],
        newsletter: false,
        terms: false,
        type:"asd"
        
      });
      const maxFiles = 10; // Max number of files

      useEffect(() => {
        if (props.type) {
          setFormData((prevData) => ({ ...prevData, type: props.type }));
        }
      }, [props.type]); 
      console.log(formData.type)
      const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const allowedTypes = [
          "application/pdf",
          "image/png",
          "image/jpeg",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ];
        const maxSize = 5 * 1024 * 1024; // 5MB per file
      
        // Prevent adding more than the allowed limit
        if (formData.files.length + selectedFiles.length > maxFiles) {
          alert(`You can only upload up to ${maxFiles} files.`);
          return;
        }
      
        const filteredFiles = selectedFiles.filter((file) => {
          if (!allowedTypes.includes(file.type)) {
            alert(`File ${file.name} has an invalid format.`);
            return false;
          }
          if (file.size > maxSize) {
            alert(`File ${file.name} is too large (max 5MB).`);
            return false;
          }
          return true;
        });
      
        setFormData((prevData) => ({
          ...prevData,
          files: [...prevData.files, ...filteredFiles], // Merge new files with existing ones
        }));
      };
      
      // Remove a file from the list
const removeFile = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      files: prevData.files.filter((_, i) => i !== index), // Remove file by index
    }));
  };
  
      const [errorData, setErrorData] = useState({
        body: false,
        email: false,
        terms: false,
        number:false,
        foretag:false,

        name: false,
      });
    
      const handleSubmit = (e) => {
    
    
        e.preventDefault(); // Prevent default form submission behavior
        setFormData({ ...formData, type:props.type })

        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('event', 'conversion_event_submit_lead_form', {
        })
        let hasError = false;
        if (formData.terms === false) {
          setErrorData((prevState) => ({ ...prevState, terms: true }));
          hasError = true;
        }
    
        if (formData.number === "") {
          setErrorData((prevState) => ({ ...prevState, number: true }));
          hasError = true;
        }
        if (formData.name === "") {
          setErrorData((prevState) => ({ ...prevState, name: true }));
          hasError = true;
        }
        if (formData.email === "") {
          setErrorData((prevState) => ({ ...prevState, email: true }));
          hasError = true;
        }
        if (formData.foretag === "") {
            setErrorData((prevState) => ({ ...prevState, foretag: true }));
            hasError = true;
          }
    
        if (hasError) {
          return;
        }
        const formDataToSend = new FormData();
        formDataToSend.append("body", formData.body);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("name", formData.name);
        formDataToSend.append("number", formData.number);
        formDataToSend.append("foretag", formData.foretag);
        formDataToSend.append("newsletter", formData.newsletter);
        formDataToSend.append("terms", formData.terms);
        formDataToSend.append("type", formData.type);
        formDataToSend.append("page", formData.page);
      
        // Append multiple files
        formData.files.forEach((file) => {
          formDataToSend.append("files", file);
        });
        const requestOptions = {
          method: "POST",
          headers: {
            "X-CSRFToken": csrftoken,
          },
    
          body: formDataToSend,
        };
    

        
            fetch(customofferform, requestOptions)
                .then((response) => {
                    if (response.ok) {
                        window.location.href = props.sprak.tack.url;
                    } else {
                        console.error('Form submission failed');
                    }
                    return response.json();
                })
                .then((data) => console.log(data))
                .catch(error => console.error('Error submitting form:', error));
            
                
      };
      if (!props.isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button onClick={props.onClose} className="modal-close">X</button>
        <div
style={{padding:10}}
>
{props.type=="tvist" && (
    <>
<h4
style={{
  ...secondaryMediumSmall,
  margin: " 0 0 15px 0",
  padding: 0,
  textAlign: "left",
  color:"rgb(26, 118, 194)"
}}
>
Kostnadsfri analys av tvist
</h4>
<div style={{ ...mainSmall, textAlign: "left", margin: 0, padding: 0, widt:"90%" }}>
<span>
    Vi behöver lite information för att kunna hjälpa till.
    </span>
<ul className="custom-modal-ul"> 
<li >När uppstod tvisten? Ex. Vid köp när gjordes köpet och till vilken kostnad?</li>
<li>Vilka parter är det mellan? Ex. Företag till företag, privatperson eller myndighet.  </li>
<li>Vilka slutresultat du ok med? Ex. Retur, rabatt, lagning.</li>
<li>Om det finns, bifoga avtal nedan - är sekretess ett problem kan vi skriva ett NDA först. </li>
<li>Vilken dialog har funnits hittills? Ex. Mail där säljaren vägrar erkänna fel. </li>
<li>Om det finns annan viktig information </li>

</ul>
<span style={{marginTop:5,color:"rgb(26, 118, 194)"}}>Vi går igenom informationen som skickas in och återkommer med en lägesanalys och vår rekommendation. </span>
<br></br>
<br></br>
<br></br></div>

</>
)}
{props.type=="audit" && (
    <>
<h4
style={{
  ...secondaryMediumSmall,
  margin: " 0 0 15px 0",
  padding: 0,
  textAlign: "left",
  color:"rgb(26, 118, 194)"
}}
>
Kostnadsfri avtalsaudit
</h4>
<div style={{ ...mainSmall, textAlign: "left", margin: 0, padding: 0, width:"90%" }}>
<span>Vi behöver lite information för att kunna hjälpa till.</span>
<ul className="custom-modal-ul"> 
<li ><b>Sälj:</b> Vad säljer ni? Vilka länder? Säljer ni online? Till privatpersoner, företag eller båda</li>
<li><b>Ägare:</b> Om ni är flera ägare, finns aktieägaravtal?</li>
<li><b>Inköp:</b> Har ni satta inköpsvillkor eller hur hanteras större inköp? </li>
<li><b>Compliance:</b> Har ni koll på vilka compliance krav som finns i er bransch? Arbetar organisationen aktivt med compliance? </li>
<li><b>GDPR:</b> Finns integritetspolicy och PuB-avtal? Dokumenterar ni löpande hur persondata hanteras?  </li>
<li><b>Annat:</b> Har ni något annat ni vill att vi kollar på? Ex. Licensavtal, användarvillkor eller allmänna villkor.</li>

</ul>
<span style={{marginTop:5,color:"rgb(26, 118, 194)"}}>Vi går igenom informationen som skickas in och återkommer med en lägesanalys och vår rekommendation. </span>
<br></br>
<br></br>
<br></br>
</div>

</>
)}
<div
style={{ padding: "0 20px", display: "flex", flexDirection: "column" }}
>

<form onSubmit={handleSubmit} noValidate style={{ padding: "0 20px", display: "flex", flexDirection: "column"}}>

<TextField
  style={{ flex: "1", marginRight: isSmallScreen ? 40 : "unset" }}
  error={errorData.name}
  helperText={errorData.name ? "Skriv in ditt namn." : ""}
  id="standard-basic"
  label={form.name}
  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
  variant="standard"
/>

<br></br>

<TextField
  style={{ flex: "1", marginRight: isSmallScreen ? 40 : "unset" }}
  
  error={errorData.email}
    helperText={errorData.email ? "Fyll i din mail." : ""}
    
  id="standard-basic"
  label={form.email}
  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
  variant="standard"
/>
<br></br>
<TextField
  style={{ flex: "1", marginRight: isSmallScreen ? 40 : "unset" }}
  
  error={errorData.foretag}
    helperText={errorData.number ? "Fyll i företagsnamn." : ""}
    
  id="standard-basic"
  label={form.foretag}
  onChange={(e) => setFormData({ ...formData, foretag: e.target.value })}
  variant="standard"
/>
<br></br>
<TextField
  style={{ flex: "1", marginRight: isSmallScreen ? 40 : "unset" }}
  
  error={errorData.number}
    helperText={errorData.number ? "Fyll i ditt telefonnummer." : ""}
    
  id="standard-basic"
  label={form.number}
  onChange={(e) => setFormData({ ...formData, number: e.target.value })}
  variant="standard"
/>
<br></br>
{formData.files.length < maxFiles && (
<input
  type="file"
  accept=".pdf, .png, .jpeg, .jpg, .doc, .docx"
  multiple
  onChange={handleFileChange}
  disabled={formData.files.length >= maxFiles} // Disable input when limit is reached

/>
  )}
{formData.files.length >= 15 && (
    <p style={{ color: "red" }}>Du kan inte bilägga fler filer.</p>
  )}
{formData.files.length > 0 && (
    <ul style={{textAlign:"left", fontSize:13}}>
      {formData.files.map((file, index) => (
        <li key={index}>
          {file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)
          <button onClick={() => removeFile(index)} style={{ all:"unset",marginLeft: "10px", color: "red" }}>
            ❌ 
          </button>
        </li>
      ))}
    </ul>
  )}

<br></br>

<TextField
  style={{ flex: "1", marginRight: isSmallScreen ? 40 : "unset" }}
  id="standard-basic"
  onChange={(e) => setFormData({ ...formData, body: e.target.value })}
  label={form.body}

  error={errorData.body}
  helperText={errorData.body ? "Skriv ett meddelande." : ""}

  multiline
  maxRows={4}
  variant="standard"
/>

<br></br>

    
<FormControl
required
error={errorData.terms}   
     helperText={errorData.terms ? "You must agree to terms." : ""}

component="fieldset"
sx={{ m: 0 }}
variant="standard"
>

<FormGroup>
 
  <FormControlLabel
    control={
      <Checkbox

        checked={formData.terms}
        onChange={(e) =>
          setFormData({ ...formData, terms: e.target.checked })
        }
      />
    }
    label={
      <div style={{ textAlign: "left" }}>
        <Typography>
          {form.termstext}
          <a href={props.sprak.privacy.url}>
            {form.termsLink}
          </a>
        </Typography>
      </div>
    }
  />
</FormGroup>
<FormHelperText>
    
{errorData.terms && <>
Integritetspolicyn måste godkännas
</>
}
</FormHelperText>
</FormControl>

{isSmallScreen && (
  <>
    <br></br>
    <br></br>
  </>
)}

           
<FormControl


component="fieldset"
sx={{ m: 0}}
variant="standard"
>

<FormGroup>

  <FormControlLabel
    control={
      <Checkbox
         checked={formData.newsletter}
        onChange={(e) =>
          setFormData({ ...formData, newsletter: e.target.checked })
        }
      />
    }
    label={
      <div style={{ textAlign: "left" }}>
        <Typography>{form.newsletter}</Typography>
      </div>
    }
  />
</FormGroup>
</FormControl>

{isSmallScreen && (
  <>
    <br></br>
    <br></br>
  </>
)}
<Button
  variant="outlined"
  onClick={handleSubmit}
  endIcon={<ArrowForwardIcon />}
  style={{
    width: "100px",
    alignSelf: "end",
    color: "black",
    borderColor: "black",
    marginRight: "20px",
    marginBottom: "20px",
  }}
>
 Skicka
</Button>
</form>
</div>

</div>
      </div>
    </div>
  );
};

export default CustomOfferModal;
