import DesktopComponent from "../../main";

import Gallery from "../../components/generalwidgets/imageCard";
import Affaren from "../screencomponents/affaren";
import ThirdHero from "../screencomponents/widget/heroes/thirdhero";
import { useEffect } from "react";
import CanonicalTag from "../../components/canonicalTag";
import { canonUrl } from "../../../static/urls";
import falt from "../../../static/newimgs/falt.webp"
import faltliten from "../../../static/newimgs/mobile/falt.webp"
import { setMeta } from "../screencomponents/widget/metadesc";

import ImageHero from "../screencomponents/widget/heroes/imagehero";
import { useMediaQuery } from "react-responsive";
let cta ={
  first_cta: "Ring Direkt",
first_cta_url
: 
"tel:+46703557548",
second_cta
: 
"Fyll i formulär",
second_cta_url
: 
"/kontakt"

}
function FaltLista(props) {
  const canonicalUrl = canonUrl+ props.sprak.faltLista.url +"/"

  useEffect(() => {
    window.scrollTo(0, 0);
  
    let data1={
      meta:{
        seo_title:props.sprak.faltLista.pagetitle,
        search_description:props.sprak.faltLista.pagedesc
      }
    }
    // Set meta description
    setMeta(data1)

// Set the attributes of the meta element
  // Create a new meta element
  
}, []);



const isMobile = useMediaQuery({ maxWidth: 768 });

return (
  <DesktopComponent sprak={props.sprak}>
                                  <CanonicalTag canonicalUrl={canonicalUrl} />
                                  {!isMobile?
                                    <ImageHero title={"Branscher "}
                                      h2={"Expertis inom flera olika branscher"}
                                      text={"Varje bransch har sina unika utmaningar och behov. Här får du en överblick över de branscher vi arbetar med och de utmaningar vi ser som unika för varje bransch. Vi har omfattande erfarenhet inom flera olika branscher och kan anpassa våra tjänster för att möta specifika krav inom just din bransch."}
                                       cta={cta}   img={falt} sprak={props.sprak}/>
          :
<ImageHero title={"Branscher "}
                                      h2={"Expertis inom flera olika branscher"}
                                      text={"Varje bransch har sina unika utmaningar och behov. Här får du en överblick över de branscher vi arbetar med och de utmaningar vi ser som unika för varje bransch. Vi har omfattande erfarenhet inom flera olika branscher och kan anpassa våra tjänster för att möta specifika krav inom just din bransch."}
                                       cta={cta}   img={faltliten} sprak={props.sprak}/>
    
  }


  
         
     <div
    style={{
      maxWidth: "800px",
      width: "90%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center", // Center vertically
      alignItems: "center", // Center horizontally
      margin: "20px auto 75px"
    }}
  >
  
    </div>
    <Gallery sprak={props.sprak}/>    
    <br></br>
    <br></br>
    <div
    style={{
      maxWidth: "800px",
      width: "90%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center", // Center vertically
      alignItems: "center", // Center horizontally
      margin: "20px auto 75px"
    }}
  >

  </div>
  <Affaren sprak={props.sprak} small={false}/>
  
      </DesktopComponent>
    );

  
}

export default FaltLista;
