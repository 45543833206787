
import React from "react";


import "../../../../components/generalwidgets/css/oneline.css"
import "../../../../../static/css/hero1.css";
import "../css/heroimg.css";

import "../css/hero-image.css"
import "../../../../../static/css/colors.css";
import "../../css/hero.css"

import OneLineContactForm from "../../../../components/generalwidgets/oneLineContact";

const ImageHero = (props) => {


  return (
      <div className="hi-hero-img-container">


<div className="hi-hero-img-hero" style={{marginTop:0, backgroundImage: `url(${props.img})` }}>
  <div className="hi-hero-img-hero-overlay" style={{backgroundColor:"transparent"}}>
    <h1 className="hi-hero-img-title">    {props.title}</h1>
    <h2 className="hi-hero-h2">{props.h2}</h2>
    <p className="hi-hero-p" style={{fontSize:18, fontWeight:500}}>{props.text}</p>
    <br></br>
    <OneLineContactForm oneline={false} sprak={props.sprak} 
        CTA_hardsell_url={props.cta.first_cta_url} CTA_hardsell={props.cta.first_cta}
        CTA_softsell_url={props.cta.second_cta_url} CTA_softsell={props.cta.second_cta} cta={true} hideBenefits={true} white={true}/>
  </div>
</div>
</div>
  );
};
export default ImageHero;




