import { Link } from "react-router-dom";
import { colors } from "../../static/colors";
import Kontakt from "../screens/screencomponents/kontakt";
import { cookiesUrl } from "../../static/urls";
import "./css/footer.css"
import logo from "../../static/img/logo-full.svg";
import NewsletterSignup from "./generalwidgets/newslettersignup";


const DesktopFooter = (props) => {
   const trackLinkClick = () => {


        if (window && window.gtag) {
          // Add your specific event
          window.gtag('event', 'conversion_event_contact', {
            // Add event parameters if needed
          })}
        
        };
    return (
<div className="desktop-footer" >
          
<div className="desktop-footer-sub2" >
            <Link to="/">
                <img
                  src={logo}
                  alt="our logo"
                  height={"75px"}
                  className="footer-logo"
                /></Link>
<div style={{alignSelf:"start", textAlign:"left", maxWidth:550}}>

<i ><h3 style={{margin:0, padding:0}}>För företags långsiktiga framgång</h3></i>                
<i><h5 style={{margin:" 5px 0px 0px 0", padding:0}}>Framåtsträvan, förtroende, hjälpsamhet & ansvar </h5></i>                
      <i><p style={{fontSize:13, fontWeight:500,margin:0, padding:0}} >
        Genom strategisk företståelse av både juridik och företagande, hjälpa företag med balansera juridiken, risker och expansion för långsiktig framgång
      </p>
      </i>
</div>
</div>
<br></br>
<NewsletterSignup dark={false}/>
<br></br>
<div style={{borderBottom:"1.5px solid white",  width:"80%", margin:"5px auto"}}/>
                <div className="desktop-footer-sub">
                <div className="desktop-footer-col">
          <h3 className="desktop-footer-item">      
          <Link className="desktop-footer-item" to={"/omraden/"} >Områden</Link>
          </h3>
          <Link className="desktop-footer-item" to={"/omraden/arbetsratt"} >Avtalsrätt</Link>
           <Link className="desktop-footer-item" to={"/omraden/avtalsratt"} >Arbetsrätt</Link>
           <Link className="desktop-footer-item" to={"/omraden/bolagsratt"}  >Bolagsrätt</Link>
           <Link className="desktop-footer-item" to={"/omraden/dataskydd"} >Dataskydd & GDPR</Link>
           <Link className="desktop-footer-item" to={"/omraden/expansion"} >Expansion</Link>
           <Link className="desktop-footer-item" to={"/omraden/fastighetsratt"}  >Fastighetsrätt</Link>
           <Link className="desktop-footer-item" to={"/omraden/foretagsoverlatelse"} >Företagsöverlåtelse</Link>
           <Link className="desktop-footer-item" to={"/omraden/forhandlingsstod"} >Förhandlingsstöd</Link>
           <Link className="desktop-footer-item" to={"/omraden/immaterialratt"}  >Immaterialrätt</Link>

           <Link className="desktop-footer-item" to={"/omraden/konkurrensratt"} >Konkurrensrätt</Link>


        </div>
        <div className="desktop-footer-col">
        <h3 className="desktop-footer-item">      
          <Link className="desktop-footer-item" to={"/tjanster/"} >Tjänster</Link>
          </h3>
          <Link className="desktop-footer-item" to={"/tjanster/aktieagaravtal"} >Aktieägaravtal</Link>
           <Link className="desktop-footer-item" to={"/tjanster/allmanna-villkor"} >Allmänna villkor</Link>
           <Link className="desktop-footer-item" to={"/tjanster/aktieoverlatelseavtal"}  >Aktieöverlåtelseavtal</Link>
           <Link className="desktop-footer-item" to={"/tjanster/gdpr-utbildning"} >GDPR Utbildning</Link>
           <Link className="desktop-footer-item" to={"/tjanster/inkramsoverlatelseavtal"} >Inkråmsöverlåtelseavtal</Link>
           <Link className="desktop-footer-item" to={"/tjanster/integritetspolicy"}  >Integritetspolicy</Link>
           <Link className="desktop-footer-item" to={"/tjanster/konsultavtal"} >Konsultavtal</Link>
           <Link className="desktop-footer-item" to={"/tjanster/licensavtal"} >Licensavtal</Link>

           <Link className="desktop-footer-item" to={"/tjanster/uppdragsavtal"} >Uppdragsavtal</Link>
           <Link className="desktop-footer-item" to={"/tjanster/"}  ><b>Mer</b></Link>
        </div>
        <div className="desktop-footer-col">
        <h3 className="desktop-footer-item">      
          <Link className="desktop-footer-item" to={"/blogg/"} >Blogg</Link>
          </h3>
          <Link className="desktop-footer-item" to={"/blogg/hur-funkar-akteagaravtal"} >Hur funkar aktieägaravtal</Link>
           <Link className="desktop-footer-item" to={"/blogg/hur-funkar-allmanna-villkor"} >Hur funkar allmänna villkor</Link>
           <Link className="desktop-footer-item" to={"/blogg/obligatoriska-policyer-for-foretag"}  >obligatoriska policyer för företag</Link>
           <Link className="desktop-footer-item" to={"/blogg/en-guide-till-avtal-och-avtalsratt"} >En guide till avtal och avtalsrätt</Link>
           <Link className="desktop-footer-item" to={"/blogg/grundlaggande-bolagsratt-for-foretagare"} >Vad är Due Diligence?
           </Link>
           <Link className="desktop-footer-item" to={"/blogg/grundlaggande-bolagsratt-for-foretagare"} >Grundläggande bolagsrätt för företagare</Link>
           <Link className="desktop-footer-item" to={"/blogg/grundlaggande-konkurrensratt-for-foretag-foretagsjurister"} >Grundläggande konkurrensrätt för företag</Link>
           <Link className="desktop-footer-item" to={"/blogg/"}  ><b>Mer</b></Link>



        </div>
    <div className="desktop-footer-col">
          <h3 className="desktop-footer-item">Kontakta oss</h3>
          <Link onClick={trackLinkClick} className="desktop-footer-item"  href="tel:031527100" >031-527 100</Link>
           <Link onClick={trackLinkClick} className="desktop-footer-item" to={`mailto:${props.sprak.mail}`} >{props.sprak.mail}</Link>
           <p className="desktop-footer-item"  >{props.sprak.address}</p>
           <h3 className="desktop-footer-item">Villkor och policy</h3>

           <Link className="desktop-footer-item" to={props.sprak.cookies.url} >{props.sprak.cookies.title}</Link>
           <Link className="desktop-footer-item" to={props.sprak.gtc.url} >{props.sprak.gtc.title}</Link>
           <Link className="desktop-footer-item" to={props.sprak.privacy.url}  >{props.sprak.privacy.title}</Link>


        </div>
    </div>
        <br></br>
        <br></br>
        <br></br>
</div>


    );
  }

  export default DesktopFooter;
