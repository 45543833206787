


import { useMediaQuery } from "react-responsive";
import ReusableLottieControl from "../../../../static/animations/reusableAnimator.js";
import { mainSmall, mainMicro, secondarySmall} from "../../../../static/fonts.js";
import "./css/benefits.css"

const BenefitsItem = (props) => {
    return (
        <div style={{ flexBasis: "30%", marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <ReusableLottieControl size={props.size} animationData={props.icon} />
            <h3 style={{ ...secondarySmall, textAlign: "center", width: "190px", paddingLeft: "5px", paddingRight: "5px", marginBottom: "10px", minHeight:"35px" }}>{props.title}</h3>
            <p style={{ ...mainMicro, textAlign: "left", width: "190px", paddingLeft: "5px", paddingRight: "5px", marginBottom: "10px" }}>{props.text}</p>
        </div>
    );
}
const BenefitsSmallItem = (props) => {

    return (
        <div className="benefits-small-item" style={{  marginBottom: "10px",width:"90px", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <ReusableLottieControl size={props.size} animationData={props.icon} />
            <p style={{width:"100%", ...secondarySmall, textAlign: "center",padding:0,margin:0 ,... mainMicro  } }>{props.title}</p>
        </div>
    );
}


const Benefits = (props) => {

    if(props.small){

        return(    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop:"0", padding:"0 15px" }}>
        <div style={{ display: "flex", flexWrap:"wrap", justifyContent: "center", width: "100%", maxWidth: "1440px", gap: "3px" }}>
            {Object.values(props.sprak.benefits.list).map((item, index) => (
                    <BenefitsSmallItem key={item.title} index={index} {...item} size={40} />
            ))}
        </div>
    </div>);
    }else{
        return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop:"100px" }}>
            <h2 style={{marginBottom:"50px"}}>{props.sprak.benefits.title}</h2>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", width: "90%", maxWidth: "1440px", gap: "10px" }}>
                {Object.values(props.sprak.benefits.list).map((item, index) => (
                    <div key={item.title} style={{ display: "flex", marginBottom: "20px" }}>
                        <BenefitsItem index={index} {...item} size={60} />
                        {index !== 5 && <div style={{ width: '1px', margin: "5px", borderRight: '1px solid black', height: "100 %" }}></div>}
                    </div>
                ))}
            </div>
        </div>
    );
}}



export default Benefits;
