
import React, { useEffect, useState} from "react";


import DesktopComponent from "../../main";

import { canonUrl, tjansteListaUrl } from "../../../static/urls";
import CanonicalTag from "../../components/canonicalTag";
import ThirdHero from "../screencomponents/widget/heroes/thirdhero";
import { Link } from "react-router-dom";
import "./css/avtallista.css"
import tjansterimg from "../../../static/newimgs/tjanster.webp"

import ImageHero from "../screencomponents/widget/heroes/imagehero";
import { setMeta } from "../screencomponents/widget/metadesc";
import PrisKomponent from "../screencomponents/priskomponent";
let cta ={
  first_cta: "Ring Direkt",
first_cta_url
: 
"tel:+46703557548",
second_cta
: 
"Fyll i formulär",
second_cta_url
: 
"/kontakt"

}
const avtal = {
  loadtjanster:{
    title:"Juridik till fast pris.",
    text:"Här kan du hitta fasta priser för många av tjänsterna ett företag behöver. Hittar du inte vad du söker får du gärna höra av dig till david@lundbergandassociates.com.",
}
}
const meta = {
  title:"Våra tjänster | Företagsjuridik | Lundberg & Associates",
  desc: "Vi har lång erfarenhet av att hjälpa företag med juridik. Läs mer om vilka tjänster vi erbjuder. "
}


function AvtalLista(props) {



  const canonicalUrl = canonUrl+ "tjanst"


  const [selectedFilter, setSelectedFilter] = useState(null);

  const [activeIndex, setActiveIndex] = useState(null);
const [data, setData] = useState({});
const [kategorier, setKategorier] = useState({});


useEffect(() => {

  window.scrollTo(0, 0);

  let data1={
    meta:{
      seo_title:meta.title,
      search_description:meta.desc
    }
  }
  // Set meta description
  setMeta(data1)
 

  const fetchDataFromAPI = async () => {
      try {
          const response = await fetch(tjansteListaUrl);
          const datan = await response.json();
            setData(datan.tjanster);
            setKategorier(datan.kategorier)

      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  fetchDataFromAPI();
}, []);

// Example filter

let loadTjanster = () => {
  if (data && data.length > 0) {
    // Filter items based on the selected filter

// Handle item click to set the active filter
const handleItemClick = (index, kategori) => {
  // Toggle active state: if the clicked item is already active, deselect it
  if (activeIndex === index) {
    setActiveIndex(null);
    setSelectedFilter(null);  // Reset the filter if the same item is clicked again
  } else {
    setActiveIndex(index);
    setSelectedFilter(kategori);  // Set the selected filter to the clicked item's category
  }
};

// Filter items based on the selected filter
const filteredItems = selectedFilter
  ? data.filter(item => {
    const result = item.ApiKategori.some(kategori => kategori.id === selectedFilter);
    return result;
  })
  : data; //
  const itemsToSkip = ['Konkurrensrätt', 'Konsumenträtt', 'Förhandlingsstöd']; // Replace with the IDs of items you want to skip
 // const itemsToSkip = ['Konkurrensrätt', 'Konsumenträtt', 'Förhandlingsstöd']; // Replace with the IDs of items you want to skip

    return (
      <div className="avtallista-tjanster">

        <h2>{avtal.loadtjanster.title}</h2>
        <p>{avtal.loadtjanster.text}</p>
        <div className="avtallista-tjanster-filter">
        { kategorier
    .filter(item => !itemsToSkip.includes(item.ApiKategori)) // Exclude items with specified IDs
    .map((item, index) => (
      <div
        className={`box ${activeIndex === index ? 'avtallista-tjanster-filter-child-selected' : 'avtallista-tjanster-filter-child'}`}
        key={index}
        onClick={() => handleItemClick(index, item.id)}  // Pass category as a parameter
      >
        <p>{item.ApiKategori}</p>
        {/* Add more fields as necessary */}
      </div>
    ))
}

          </div>
        <div className="avtallista-tjanster-div">

       {filteredItems.length > 0 ? (
      filteredItems.map((item, index) => (
        <Link  className="avtallista-tjanster-div-child" to={item.slug} key={index}>
          <h3>{item.title}</h3>
          <p>{item.intro}</p>
          {item.pris && <p>Pris {item.pris} kr</p>}
          <p style={{fontSize:16}} className="avtalslista-link" >Läs mer &rarr;	</p>
        </Link>
      ))
    ) : (
      <p style={{height:200}}>No items found.</p>
    )}
        </div>
      </div>
    );
  } else {
    return <div>No data available</div>;
  }
}


  return (
    <DesktopComponent sprak={props.sprak}>
                              <CanonicalTag canonicalUrl={canonicalUrl} />
                              <ImageHero  title={"Våra tjänster "}
                                      h2={"Skräddarsydda lösningar för dina juridiska behov "}
                                      text={"Vi strävar att vara tydliga i vår kommunikation, därför finner du nedan ett antal vanliga tjänster som vi erbjuder till fast pris. "}
                                      cta={cta}   img={tjansterimg} sprak={props.sprak}/>

  
<div>
</div>
    <div>
      {loadTjanster()}
    </div>


    </DesktopComponent>
  );

}


export default AvtalLista;
