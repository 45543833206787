export const colors= {
    main:'white',
    second:"black",
    lightgray:'	#D3D3D3',
    tint:'#d3d3d3',
    btn:"blue",
    heroGradOne:"white",
    lightAccent:"#EAF1F7",
    secondAccent:"#004d99",

    lighttint:"#ECECEC",
    heroGradTwo:"#EAF1F7", 
    theme:"#1A76C2",
    accent:"#1A76C2",
    darkaccent:"#1A76C2",
    footer:"#1A76C2",
    textMain:"black",
    textSecondary:"white",
     
    orange:"ff9d33",
    normal:"20px"
}

//old
//#367cb3
//background 
//rgb(255, 250, 245) #fffaf5
//blue
//1A76C2
