import { colors } from "../../../static/colors";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import "./css/navbtn.css"
const NavBtn = (props) => {

  const isHovering = props.showDropdownText === props.navlink;
  return (
<Link 

onMouseEnter={() => props.onEnter(props.navlink)} 
className="header-navbtn"
onClick={() => {
  props.setNavHovering(false); // Or whatever state you want to update
}}
style={{backgroundColor: isHovering ? "white" : "transparent",
  color:isHovering ? "#1A76C2":"white",
}}
to={`${props.url}/`}>{props.title}</Link>

         

  );
};

export default NavBtn;
