
import { Link } from "react-router-dom";
import { colors } from "../../../static/colors";
import React, { useState } from 'react';
import { secondarySmall } from "../../../static/fonts";

const Btn = (props) => {
 const btnStyle ={
    textAlign:'center', padding:'8px 16px', backgroundColor: props.inverted ? colors.main:colors.footer, color:props.inverted? colors.accent : colors.main
    ,borderRadius:"22px",
    borderStyle:"solid",
    textDecoration:"none",
    borderWidth:"2px",
    borderColor:colors.footer,
    ...secondarySmall
  }
  return (
    props.navigate 
      ? <Link
      onClick={() => {
        props.setNavHovering(false); // Or whatever state you want to update
      }}
       to={props.navigate} style={btnStyle}>{props.btnText}</Link>
      : <Link
      onClick={() => {
        props.setNavHovering(false); // Or whatever state you want to update
      }}
      style={btnStyle}>{props.btnText}</Link>
  );
}

  
  export default Btn;
