import React, { useState } from 'react';
import './css/accordion.css'; // Assuming your CSS is in Arrow.css
import { Link } from 'react-router-dom';

function AccordionDangerous(props) {

  const [activeIndex, setActiveIndex] = useState(null);
  const [isUp, setIsUp] = useState(false);

  const handleToggle = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
    setIsUp(!isUp);

  };



  return (
    <div className='accordiondang'>
      {props.questions.map((item, index) => (
        <div className={` ${props.col ? 'accordion-div-col' : 'accordion-div'}`} key={item.id}>
          <h3 className={` ${props.col ? 'accordion-question-left-col' : 'accordion-question-left'}`} onClick={() => handleToggle(index)}>
            {item.fraga || `Fråga ${index + 1}`} 
            <span>
            <span 
              className={`accordion-arrow ${activeIndex === index ? 'accordion-up' : 'accordion-down'}`}
            ></span>  </span>        </h3>
          {activeIndex === index && (
            <>
            <div className='accordion-question-left'
            dangerouslySetInnerHTML={{ __html: item.svar }} ></div>

            <Link className='dang-link' to="/kontakt">Låt oss ta en dialog</Link>
          </>
          )}
        </div>
      ))}
    </div>
  );
}

export default AccordionDangerous;