
const mainUrl = "https://backend.lundberglaw.com:443/api/"
//const mainUrl = "http://127.0.0.1:8000/api/"
export const cookiesUrl = mainUrl+"cookies/"
export const testimonialsUrl = mainUrl+"testimonials"
export const questionsUrl = mainUrl+"questions"
export const articleUrl = mainUrl+"articleUrl"
export const falt = mainUrl +""
export const pages = mainUrl +"api/v2/pages/"
export const findSlugPage = mainUrl + "api/v2/pages/find/?html_path="
export const blogs = mainUrl+"blogs/"
export const tjansteListaUrl = mainUrl+"tjanst/"
export const exportMainUrl = mainUrl;
export const pricingform = mainUrl +"pricingform/"
export const fragor= mainUrl+"fragor/"
export const fetchforspecificpage = mainUrl+"fetchforspecificpage/"
export const sendform = mainUrl +"sendform/"
export const customofferform = mainUrl+"customofferform/"
export const onelineForm = mainUrl+"oneline/"
export const starttillslutUrl= mainUrl +"steg/?start_till_slut="
export const newsletter = mainUrl+"newsletter/"

export const media ="https://backend.lundberglaw.com:443/"
//tabort
export const findSlug = mainUrl + "api/v2/pages/find/?html_path="
export const sendFormUrl=mainUrl+ "send-email/"
export const alldata=mainUrl + "alldata/"
export const headers = {
    'Content-Type': 'application/json',
}
export const canonUrl = "https://lundberglaw.com"
export const sitemap = "https://backend.lundberglaw.com:443/api/sitemap/"
export const sitemapLoc = "https://lundberglaw.com"
