import React, { useState } from "react";
import { colors } from "../../../static/colors";
import { mainSmall } from "../../../static/fonts";
const CustomButton = (props) => {
    console.log(props.title)

    return (
      <button
        onClick={props.onClick}
        style={{
          borderWidth: "1px",
          marginBottom:"0px",
          borderStyle: "solid",
          height:"24px",
          backgroundColor: props.active ? colors.accent : "transparent",
          borderRadius: "10px",
          padding: "2px 8px",
          borderColor: colors.accent,
          ...mainSmall,
          margin: "1px",
          color: props.active ? "white" : colors.accent,
        }}
      >
        {props.title}
      </button>
    );
  };
const Filter = (props) => {
  const handleItemClick = (item) => {
    props.setSelectedItem(item);
  };
  const updatedList = [...props.list];
  updatedList.push({title:props.sprak.alla});


  return (
    <div style={{ display: "flex", flexWrap: "wrap", marginBottom: "5px" }}>
      {updatedList.map((item, index) => (
        <CustomButton
          key={index}
          active={props.selectedItem.title == item.title}
          title={item.title}
          onClick={() => handleItemClick(item)}
        />
      ))}
    </div>
  );
};
export default Filter;
