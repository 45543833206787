import DesktopComponent from "../../main";

import React, { useEffect, useState } from "react";

import {
  mainMedium,
  mainSmall,
  secondaryMediumLarge,
} from "../../../static/fonts";
import { canonUrl } from "../../../static/urls";
import { colors } from "../../../static/colors";
import CanonicalTag from "../../components/canonicalTag";
import './css/omoss.css'; // External CSS file for styling
import jumbo from "../../../static/img/jumbo3.webp";
import heroImg from "../../../static/img/hero.webp";
import { setMeta } from "../screencomponents/widget/metadesc";

import omraden from "../../../static/newimgs/omraden.webp"

import ImageHero from "../screencomponents/widget/heroes/imagehero";
let cta ={
  first_cta: "Ring Direkt",
first_cta_url
: 
"tel:+46703557548",
second_cta
: 
"Fyll i formulär",
second_cta_url
: 
"/kontakt"

}
const omoss = {
  title:"Om Lundberg & Associates",
  img:jumbo,
  ord:{title:"Tillgänglighet, effektivitet och kundfokus",text:"Lundberg & Associates jobbar med kunden i fokus. Vi strävar efter att alltid finnas tillgängliga när våra kunder är i behov av rådgivning och genom ett effektivt arbete ser vi till att kunders juridiska problem tar upp så liten del av företagandet som möjligt."},
  heroText:"Lätttillgänglig affärsjuridik",
  overviewText:"",
  hist:{title:"HISTORIK",text:[{title:"",text:"Lundberg & Associates grundades 2017 av Cathrin Lundberg som 2014 klev av sin roll som Global Head of Expansion på IKEA med målet att ha mer tid med familj. Efter att ha spenderat många år i globala förhandlingar med stora bolag möttes hon dock av en disonans av hur hon varit van att arbeta med juridiken och hur den vanligen arbetas med i Sverige. Därför, efter några år som lönedelägare på Wåhlins Advokatbyrå i Göteborg, startades Lundberg & Associates."},{title:"Målet?",text:"", citat:"”Juridiken är ju en service funktion, den ska förenkla, förbättra och tjäna pengar för företag men det saknas insikten att man måste bidra. <br> <br> Det var faktiskt vad som gjorde mig till en av de yngsta medlemmarna i styrelser och ledningsgrupp på Inter IKEA.”"}]},

  LA:{title:"Om Cathrin",text:[{title:"Uppväxt",text:"De historiska rötterna till finns i 1980-talets Skellefteå i Västerbotten. Cathrin Lundberg växte upp i en entreprenörsfamilj. Att växa upp i entreprenörsfamilj och därtill på en gård, gjorde att Cathrin tidigt lärde sig tycka om att leva med arbetet. Uppmuntrad av sina föräldrar att ingenting är omöjligt och att “bara för att ingen har plockat ner månen ännu, innebär det inte att det möjligt” klev hon ut i världen. "},{title:"Studierna",text:"Från Skellefteå, via Zermatt, jobb som lärare i SFI och jobb på resebyrå, landade hon på juristprogramet i Uppsala med ytterligare drygt 1 års studier på juristprogrammet i Tyskland. ", citat:"”Jag var ingen stjärnstudent, jag kuggade hela första terminen… och hade hoppat av om det inte var för det satans brevet ‘Du bör ställa din plats till förfogande för någon bättre lämpad’. <br><br> Nej, jag ska visa de där stropparna – ni är inte bättre än mig.”"}
    ,{title:"Arbetslivet",text:"Det är nu en termin kvar, Cathrin är 26 år när hon inser att hon vill jobba internationellt – inte gå samma bana som alla andra och studerar 1.5 år på juristprogrammet i Tyskland. Istället för att sitta i tingsrätten, som 1996 praktiskt taget var ett tvång. Jaja, ska man gå sin egen väg kan man inte konformera. Besluten lönade sig och var hur hon som 29 år gammal befann sig ensam i Ryssland med ett rum fullt av ryska 50 åriga gubbar i en förhandling om telenät värda miljarder.", citat:"”De kommer tro att du är en dum liten flicka – låt dem. Vänta på att de gör bort sig, sen slår du till” – Kjell Lundberg, Cathrins far och fd. VD och delägare på Valutec.",textEfterCitat:"De kommer tro att du är en korkad liten flicka – låt dem. Vänta på att de gör bort sig, sen slår du till” – Kjell Lundberg, Cathrins far och fd. VD och delägare på Valutec.",textEfterCitat:"Från Ericsson Radio Systems, Inter IKEA, Borealis till Wåhlins och sedan Lundberg & Associates. Med roller som bolagsjurist, chefsjurist, expansionschef och lönedelägare. "},
]},
  CL:{title:"Om Lundberg & Associates",text:[
    {title:"",text:"Viljan att förenkla för bolag att använda jurister genom att vara tillgänglig och lättbegriplig har alltid präglat arbetssättet. Tydliga råd och transparens i pris. Allt i en positiv anda så det är roligt att jobba! ",citat:"”Om juridiken ska användas praktiskt kan den inte vara svårbegriplig, då kommer den ignoreras.<br> <br> Därför klär vi ner den, så att den anställda börjar använda den.”",textEfterCitat:"Vi kombinerar vår kompetens med insikter i kundernas utmaningar. Allt för att skapa så bra förutsättningar som möjligt. <br><br>Lundberg & Associates, med säte i Stenungsund, har anställda på spridda platser i Sverige. Detta då det viktigaste är att hitta rätt människor, med rätt inställning och en hjärna med kapacitet. <br><br>Lundberg & Associates samarbetar med kvalificerade partners och specialiserade leverantörer inom och utom Sverige – därav namnet Lundberg & Associates. Vi fortsätter i liten skala som bolag, för att kunna plocka in de personer som bäst kan bidra till att hjälpa våra företagare – i ett stort, väbeprövat och internationellt nätverk. Vi använder de bästa för att våra kunder ska få gott resultat och för att allt är mycket roligare då! <br> <br> <br>"}
    
  ]},
  vi:{
vi:{title:"Vi",
text:"Vi som jobbar på Lunderg & Associates"},
konsulter:{
  title:"Konsulter",
  text:"Våra konsulter"
}
  }
}
const VarderingarComponent = ({ title, subtitle, varderingar,text}) => {
  return (
    <div style={{ maxWidth: "760px" }}>
      <h2 style={secondaryMediumLarge}>{title}</h2>
  <p style={{...mainSmall, textAlign:"left", paddingLeft:"40px", paddingRight:"40px"}}>{text}</p>    
      <br></br>
    </div>
  );
};
const associates={title:"", text:"",
   employees:[
  {name:"Cathrin Lundberg", img:heroImg},
  {name:"Cornelia Wårhem", img:heroImg},
  {name:"David Lundberg", img:heroImg}
], 
associateImg:"",
associateText:"",
associateTitle:""


}
const Imgs = (props) => {
  return (
    <div className="aboutus-hero-section">
      {associates.employees.map((employee, index) => (
        <div className="aboutus-hero-item" key={index} >
          <img src={employee.img} loading="lazy" alt={employee.name} className="aboutus-circle" />
          <p className="aboutus-name">{employee.name}</p>
        </div>
      ))}
      <p></p>
    </div>
  );
};const AboutUs = (props) => {
  return (
    <div className="aboutus-container">

   

      <section className="aboutus-overview about-us-text-width">
        <div dangerouslySetInnerHTML={{ __html: omoss.overviewText }} />
      </section>

      <section className="aboutus-la about-us-text-width">
        <h2>{omoss.hist.title}</h2>
        {omoss.hist.text.map((item, index) => (
          <div key={index} className="aboutus-la-item">
            <h3 className="about-us-h3">{item.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: item.text }} />
            {item.citat &&
            ( <p className="blockquote" dangerouslySetInnerHTML={{ __html: item.citat }} />
            )}
            {item.textEfterCitat && <div dangerouslySetInnerHTML={{ __html: item.textEfterCitat }} />}
          </div>
        ))}
      </section>

      <section className="aboutus-cl ">
        <div className="about-us-text-width">
        <h2>{omoss.CL.title}</h2>
        {omoss.CL.text.map((item, index) => (
          <div key={index} className="aboutus-la-item">
            <h3 className="about-us-h3">{item.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: item.text }} />
            {item.citat && <p className="blockquote blockquote-y" dangerouslySetInnerHTML={{ __html: item.citat }} />}
            {item.textEfterCitat && <div dangerouslySetInnerHTML={{ __html: item.textEfterCitat }} />}
          </div>
        ))}
        </div>
      </section>

      <section className="aboutus-la about-us-text-width">
        <h2>{omoss.LA.title}</h2>
        {omoss.LA.text.map((item, index) => (
          <div key={index} className="aboutus-la-item">
            <h3 className="about-us-h3">{item.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: item.text }} />
            {item.citat && <p className="blockquote" dangerouslySetInnerHTML={{ __html: item.citat }} />}
            {item.textEfterCitat && <div dangerouslySetInnerHTML={{ __html: item.textEfterCitat }} />}
          </div>
        ))}
      </section>
      
     
    </div>
  );
};



function OmOss(props) {
  const canonicalUrl = canonUrl+props.sprak.omoss.url
  
  const { title, subtitle, varderingar, text } = props.sprak.omoss.main.varderingar;


  useEffect(() => {
    window.scrollTo(0, 0);


    let data1={
      meta:{
        seo_title:props.sprak.omoss.pagetitle,
        search_description:props.sprak.omoss.pagedesc
      }
    }
    // Set meta description
    setMeta(data1)

}, []);

return(
  <DesktopComponent sprak={props.sprak}>
            <CanonicalTag canonicalUrl={canonicalUrl} />
            <ImageHero title={omoss.title} h2={"Lättillgänglig affärsjuridik för företag i alla storlekar "} cta={cta}   img={omoss.img}  sprak={props.sprak}/>
  


  <AboutUs omoss={omoss} img={heroImg} />
  <div
        style={{
          minHeight: "300px",
          marginTop: "50px",
          marginBottom: "50px",
          backgroundColor: `${colors.heroGradTwo}`,
          paddingTop: "20px",
        }}
      >
        <div
          style={{
            backgroundColor: `${colors.lightAccent}`,
            paddingTop: "10px",
          }}
        >
          <h3 style={{ textAlign: "center", ...mainMedium }}>
            {props.sprak.omoss.main.vadsagerkunder}
          </h3>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <VarderingarComponent
          title={title}
          subtitle={subtitle}
          varderingar={varderingar}
          text={text}
        />
        
      </div>
      <div
        style={{
          minHeight: "300px",
          marginTop: "50px",
          marginBottom: "50px",
          backgroundColor: `${colors.heroGradTwo}`,
          paddingTop: "20px",
          paddingBottom: 0,
          marginBottom: "-12px",
        }}
      >
        <div
          style={{
            backgroundColor: `${colors.lightAccent}`,
            paddingTop: "10px",
            marginBottom: 0,
          }}
        >
          <h3 style={{ textAlign: "center", ...mainMedium }}>
            {props.sprak.omoss.main.vadsagerfolk}
          </h3>
        </div>
      </div>
  </DesktopComponent>
)

}

export default OmOss;
