
import "../../../../../static/css/hero1.css";
import OneLineContactForm from "../../../../components/generalwidgets/oneLineContact";

import "../css/thirdhero.css"
const ThirdHero = (props) => {

  return (
    <div
      className="hero-main third-hero-main"
     
    >
      <div className="third-hero-div-one">
        <h1  className="third-hero-h1">
          {props.title}
        </h1>
        {props.text && (
          <p className="third-hero-p">{props.text}</p>
        )}
        
        <br></br>
      

        {props.cta ?
        <OneLineContactForm oneline={true} sprak={props.sprak} 
        CTA_hardsell_url={props.CTA_hardsell_url} CTA_hardsell={props.CTA_hardsell}
        CTA_softsell_url={props.CTA_softsell_url} CTA_softsell={props.CTA_softsell} cta={true}/>
        :
        <OneLineContactForm oneline={true}  sprak={props.sprak} 
        cta={false} />
      }
      </div>
    </div>
  );
};
export default ThirdHero;
