import React, { useState } from 'react';
import './css/accordion.css'; // Assuming your CSS is in Arrow.css

function Accordion(props) {

  const [activeIndex, setActiveIndex] = useState(null);
  const [isUp, setIsUp] = useState(false);

  const handleToggle = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
    setIsUp(!isUp);

  };



  return (
    <div className='accordion'>
        <h2>Vanliga frågor</h2>
      {props.questions.map((item, index) => (
        <div className='accordion-div' key={item.id}>
          <h3 className='accordion-question' onClick={() => handleToggle(index)}>
            {item.fraga || `Fråga ${index + 1}`} 
            <span 
              className={`accordion-arrow ${activeIndex === index ? 'accordion-up' : 'accordion-down'}`}
            ></span>          </h3>
          {activeIndex === index && (
            <div>
              <p className='accordion-p'>{item.svar}</p>
              {/* Add more details or components here if needed */}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Accordion;