import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import Benefits from '../../screens/screencomponents/widget/benefits';
import "./css/oneline.css"


function OneLineContactForm(props) {
 if(props.small){
  return (   <>
  <div style={{ textAlign: 'center' }}>

  <div style={{ width: '350px', margin: 'auto' }}>
<br></br>


<br></br>
    {props.cta ? ( 
        <div className='oneline-contact-two-cta'>
         {props.CTA_hardsell &&
         <Link
          component="button"
          variant="body1"
          to={props.CTA_hardsell_url}
          className='oneline-two-link-btn oneline-active-link-btn-color'
      >
          {props.CTA_hardsell}
      </Link>}
      {props.CTA_softsell &&

        <Link
        component="button"
        variant="body1"
        to={props.CTA_softsell_url}
        className='oneline-two-link-btn oneline-inactive-link-btn-color'
    >
        {props.CTA_softsell}
    </Link>}
    </div>
      ):(
      <Link
          component="button"
          variant="body1"
          to={props.sprak.kontakt.url}
          className='oneline-two-link-btn oneline-active-link-btn-color'
      >
          Prata med en jurist
      </Link>)}
  </div>
  
</div>
</>
);
 }
  return (
    <>
            <div className='oneline-main-div' >
            {props.hideBenefits?(
            <></>):(
                <Benefits sprak={props.sprak} small={true} />
            )}




<div className='oneline-sub-div'>
  
<br></br>
{props.cta ? ( 
        <div className='oneline-contact-two-cta'>
            
         {props.CTA_hardsell &&
         <Link
          component="button"
          variant="body1"
          to={props.CTA_hardsell_url}
          className='oneline-two-link-btn oneline-active-link-btn-color'
      >
          {props.CTA_hardsell}
      </Link>}
      {props.CTA_softsell &&

        <Link
        component="button"
        variant="body1"
        to={props.CTA_softsell_url}
          className={`oneline-two-link-btn  ${props.white ? 'white-style' : 'blue-style'}`}

    >
        {props.CTA_softsell}
    </Link>}
    </div>
      ):(
      <Link
          component="button"
          variant="body1"
          to={props.sprak.kontakt.url}
          className='oneline-two-link-btn '
      >
          Prata med en affärsjurist
      </Link>)}

            </div>
            
        </div>
    </>
  );

}


export default OneLineContactForm;
