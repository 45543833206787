import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

const Card = ({ name, text, rating, img, title }) => {
  const missingStars = "☆".repeat(5 - rating);
  const stars = "★".repeat(rating) + missingStars;

  return (
    <div style={{
      backgroundColor: "white",
      listStyleType: "none",
      boxShadow: "2px 4px 8px rgba(0,0,0,0.3)",
      padding: "15px",
      paddingBottom: "40px",
      width: "90%",
      margin: "10px",
      borderRadius: "10px"
    }}>
      <img src={img} height="40" style={{ display: "block", alignSelf: "flex-start" }} />
      <h5 style={{ color: '#4a4a4a', marginBottom: '5px', marginTop: "15px", textAlign: "left" }}>{name} | {title}</h5>
      <div style={{ color: 'gold', textAlign: "left" }}>
        {stars} {/* Show stars */}
      </div>
      <p style={{ textAlign: "left", color: '#6a6a6a', fontSize: '14px', marginBottom: '5px' }}>{text}</p>
    </div>
  );
};

const CompanyReviews = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 599 });  // Mobile view
  const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 899 });  // Tablet view

  const [currentIndex, setCurrentIndex] = useState(0);
  const cardsToShow = isMobile ? 1 : isTablet ? 2 : 3;  // Cards to show based on screen size

  const totalCards = props.items.length;

  // Function to go to the next card
  const nextCard = () => {
    if (currentIndex < totalCards - cardsToShow) {
      setCurrentIndex(currentIndex + cardsToShow);
    }
  };

  // Function to go to the previous card
  const prevCard = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - cardsToShow);
    }
  };

  return (
    <>
    <div style={{ display: "flex", flexDirection: "column",minHeight:350, marginTop:40, alignItems: "center" }}>
      <div style={{ display: "flex", overflow: "hidden", width: "100%", justifyContent: "center" }}>
        {props.items.slice(currentIndex, currentIndex + cardsToShow).map((item, index) => (
          <Card key={index} name={item.name} text={item.review} rating={item.stars} img={item.img} title={item.title} />
        ))}
      </div>
    </div>
   
    </>
  );
};

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ArrowButton = styled.button`
  background-color: transparent;
  border: none;
  font-weight:700;
  font-size: 29px; /* Adjust size as needed */
  color: #1A76C2; /* Blue color for the arrows */
  cursor: pointer;
  transition: color 0.3s; /* Transition for color change */
  margin: 0 10px; /* Space between buttons */

  &:hover {
    color: darkblue; /* Darker blue on hover */
  }

  &:disabled {
    color: lightgray; /* Light gray when disabled */
    cursor: not-allowed;
  }
`;

export default CompanyReviews;
