import { Link } from 'react-router-dom';
import "./css/homeomrade2.css";

const Omrade = (props) => {
  const size = "36px"; // Define the size of the icon here, if needed elsewhere
  return (
    <Link to={props.url} className="home-omrade2-item">
      <div className="home-omrade2-container">
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          fill="none" 
          viewBox="0 0 24 24" 
          strokeWidth="1.5" 
          stroke="currentColor" 
          className="home-omrade2-icon"
          style={{ width: size, height: size }} // Keep colors from props
        >
          {props.img}
        </svg>
        <div className="home-omrade2-content">
          <h3 className="home-omrade2-title">{props.title}</h3> {/* Add title class for styling */}
        </div>
        <div className="home-omrade2-arrow">→</div> {/* Adjust the arrow as needed */}
      </div>
    </Link>
  );
};

const HomeOmraden2 = (props) => {
  return (
    <div className="home-omrade2-grid">
      {props.list.map(item => (
        <Omrade img={item.img} key={item.id} url={item.url} title={item.title} text={item.text} />
      ))}
    </div>
  );
};

export default HomeOmraden2;
