import React, { useEffect, useState } from "react";

import DesktopComponent from "../../main";
import { Link, useParams } from "react-router-dom";
import {
  canonUrl,
 
} from "../../../static/urls";
import CanonicalTag from "../../components/canonicalTag";
import "./css/priser.css";
import PriserForm from "../screencomponents/widget/priserform";
import { selectedOption } from "./pricingOptions";

const Order = ({ onClick, text, isSelected }) => (
  <button
    onClick={onClick}
    className={isSelected ? "priser-order-selected" : "priser-order"}
  >
    {text}
  </button>
);
//används för korten till vänster

const PrisOption = (props) => {
  return (
    <>
      <div
        className={
          props.selected === props.option.name
            ? "priser-option-desktop-selected priser-option-desktop"
            : "priser-option priser-option-desktop"
        }
      >
        <h2>{props.option.title}</h2>
          <p>{props.option.pris}</p>
       
        <p>{props.option.txt}</p>

        <ul>
          {Object.values(props.option.li).map((item) => (
            <li className="priser-li">{item}</li>
          ))}
        </ul>

        
        <div className="right-bottom">
          {props.option.name !== selectedOption.basic.name ? (
            
              
                <p>{props.option.timmar}</p>
   
          ) : (
            <>
              {props.option.title == "Idea" ? (
                <>
                  <p>{props.option.timmar}</p>
                </>
              ) : (
                <p className="priser-none">Ett ärende</p>
              )}
            </>
          )}
          {props.option.name == selectedOption.basic.name &&
          props.option.title !== "Idea" ? (
            <Order
              text={"Se vår prislista"}
              option={props.option.name}
              isSelected={props.selected === props.option.name}
              onClick={() => props.selectOption(props.option.name)}
            />
          ) : (
            <Order
              text={"Välj " + props.option.title}
              option={props.option.name}
              isSelected={props.selected === props.option.name}
              onClick={() => props.selectOption(props.option.name)}
            />
          )}
        </div>
      </div>

      <div
        className={
          props.selected === props.option.name
            ? "priser-option-phone-selected priser-option-phone"
            : "priser-option priser-option-phone"
        }
      >
 <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
  <h2 style={{ margin: 0, padding: 0 }}>{props.option.title}</h2>
  <p style={{ margin: 0, padding: 0 , fontSize:16}}>{props.option.pris}</p>
</div>



        <ul className="priser-ul-phone">
          {Object.values(props.option.li).map((item) => (
            <li className="priser-li-phone">{item}</li>
          ))}
        </ul>
        <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
  <div className="right-bottom">
    {props.option.name !== selectedOption.mikro.name ? (
      <p>{props.option.timmar}</p>
    ) : (
      <p className="priser-none">Per ärende</p>
    )}
  </div>
  
  <Order
    text={"Välj " +props.option.title}
    option={props.option.name}
    isSelected={props.selected === props.option.name}
    onClick={() => props.selectOption(props.option.name)}
  />
</div>

      </div>
    </>
  );
};

function Priser(props) {
  const [selected, setSelected] = useState("liten");
  const canonicalUrl = canonUrl + "/priser";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const selectOption = (option) => {
    setSelected(option);
  };

  return (
    <DesktopComponent sprak={props.sprak} locationRemoved={true} light={true}>
      <CanonicalTag canonicalUrl={canonicalUrl} />

      <div className="priser-container">
        <div className="priser-sub-container">
          <div className="priser-flex1">
            <h1 style={{margin:0}}>Priser och paketlösningar</h1>
            <p>
 
För att säkerställa att juridiken får det fokus den behöver i ert företag, erbjuder vi flera paketlösningar att välja mellan. Timmar som inte utnyttjas under en månad ackumuleras och kan användas vid behov.
<br></br>
<br></br>
Vi tror på flexibilitet – därför har vi ingen bindningstid. Om du inte är nöjd, behöver du inte fortsätta.
<br></br>
<br></br>
Behöver du endast ett avtal erbjuder vi många avtal till fasta priser <Link to="/tjanster"> du finner vår prislista här.</Link>
            </p>
            <div className="pris-option-container">
              <PrisOption
                selected={selected}
                selectOption={selectOption}
                option={selectedOption.mikro}
              />
              <PrisOption
                selected={selected}
                selectOption={selectOption}
                option={selectedOption.liten}
              />
              <PrisOption
                selected={selected}
                selectOption={selectOption}
                option={selectedOption.medium}
              />
              <PrisOption
                selected={selected}
                selectOption={selectOption}
                option={selectedOption.mediumlarge}
              />
              <PrisOption
                selected={selected}
                selectOption={selectOption}
                option={selectedOption.stor}
              />
            </div>
          </div>

          <div className="priser-flex1">
            <PriserForm
              sprak={props.sprak}
              obj={selectedOption[selected]}
              basic={selectedOption.basic}
              selected={selected}
            />
          </div>
        </div>
      </div>
    </DesktopComponent>
  );
}
export default Priser;
