import React, { useEffect, useState } from "react";
import DesktopComponent from "../../main";
import { Link, useParams } from "react-router-dom";
import {
  canonUrl,
  exportMainUrl,

} from "../../../static/urls";
import {
  mainSmall,
  secondaryMediumSmall,
} from "../../../static/fonts";
import Form from "../../components/generalwidgets/form";
import CanonicalTag from "../../components/canonicalTag";
import "./css/tjanst.css";
import heroImg from "../../../static/img/hero.webp";

import "./css/omrade.css";
import { setMeta } from "../screencomponents/widget/metadesc";
import Accordion from "../screencomponents/widget/accordion";

import Modal from "../screencomponents/widget/modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { sendform } from "../../../static/urls";
import { FormControl, FormHelperText, Zoom } from "@mui/material";
import AccordionDangerous from "../screencomponents/widget/accordionDangerous";
import CompanyReviews from "../../components/generalwidgets/slider/companyReviews";
import avtalsratt from "../../../static/newimgs/avtalsratt.webp";

import { useMediaQuery } from "react-responsive";
import FokusGrid from "../screencomponents/widget/FokusGrid";
import CustomOfferModal from "../screencomponents/widget/customoffermodal";
const omrade = {
  fetchUrlAddon: "omraden/",
  canonurladdon: "/omraden/",

  modal: {
    title: "Boka möte",
    text: "Fyll i dina detaljer så återkommer vi med förslag på mötestid. Det gör vi oftast inom en timme!",
  },
  hur: {
    steg1: {
      title: "1. Boka möte",
      text: "Innan vi börjar har vi ett introduktionsmöte där vi går igenom eventuella frågor och hur du vill att slutprodukten ser ut.",
      img: "",
    },
    steg2: {
      title: "2. Beställ",
      text: "När mötet är avslutat skickar vi över ett uppdragsavtal, om du väljer att beställa tjänsten börjar vi jobba när vi fått din signatur.",
      img: "",
    },
    steg3: {
      title: "3. Utför",
      text: "Vi utför jobbet du beställt.",
      img: "",
    },
    steg4: {
      title: "4. Leverans",
      text: "När vi är färdiga levererar vi produkten och tar ett kort möte och går igenom leveransen, om du vill.",
      img: "",
    },
  },
};


const avtal = {
  fetchUrlAddon: "tjanst/",
  canonurladdon: "/tjanster/",

  modal: {
    title: "Boka möte",
    text: "Fyll i dina detaljer så återkommer vi med förslag på mötestid. Det gör vi oftast inom en timme!",
  },
  hur: {
    steg1: {
      title: "1. Boka möte",
      text: "Innan vi börjar har vi ett introduktionsmöte där vi går igenom eventuella frågor och hur du vill att slutprodukten ser ut.",
      img: "",
    },
    steg2: {
      title: "2. Beställ",
      text: "När mötet är avslutat skickar vi över ett uppdragsavtal, om du väljer att beställa tjänsten börjar vi jobba när vi fått din signatur.",
      img: "",
    },
    steg3: {
      title: "3. Utför",
      text: "Vi utför jobbet du beställt.",
      img: "",
    },
    steg4: {
      title: "4. Leverans",
      text: "När vi är färdiga levererar vi produkten och tar ett kort möte och går igenom leveransen, om du vill.",
      img: "",
    },
  },
};
let form = {
  title: "Vi hjälper ditt företag – Hör av dig",
  text: "Lämna gärna dina kontaktuppgifter så kontaktar vi dig inom en timme för att diskutera din situation och dina juridiska behov. Den första konsultationen är helt gratis och du är inte bunden att anlita oss. Vi är även nästan alltid tillgängliga för ett samtal om du inte vill vänta.",
  name: "Namn",
  newsletter:
    "Intresserad av att hålla dig uppdaterad om vad som händer i juridiken?",
  telefon: "Telefonnummer",
  email: "Email",
  number: "Telefonnummer",
  body: "Beskriv din situation kort",
  termstext: "Jag har läst och godkänner integritetspolicyn. ",
  url: "",
  termsLink: "Läs den här",
};
const scrollToForm = () => {
  const formElement = document.getElementById("form-id");
  if (formElement) {
    formElement.scrollIntoView({ behavior: "smooth" });

    window.history.replaceState(null, document.title, window.location.pathname);
  }
};

const HowItem = (props) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h3 style={{ margin: 0 }}>{props.title}</h3>
        </div>
        <p  style={{ margin: 0,fontSize:16 }}>{props.text}</p>
      </div>
    );
  };
const CTA = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >

      
        
      {props.place && <i style={{color:"white"}}>Nationell affärsjuridik från Göteborg</i>}
      <div className="omrade-row">
        <button
          onClick={scrollToForm}
          style={{ margin: 0 }}
          className="omrade-cta-button"
        >
          Hör av dig
        </button>

        <a
          href="tel:031527100"
          style={{ margin: 0 }}
          className="omrade-cta-button"
        >
          031-527 100
        </a>
      </div>
      {props.shadow ? (
        <i className="omrade-main-herov3-p" style={{color:"white"}}>
          Första konsultationen är kostnadsfri
        </i>
      ) : (
        <i className="omrade-main-herov3-p-no-shadow" style={{color:"white"}}>
          Första konsultationen är kostnadsfri
        </i>
      )}
    </div>
  );
};
const InfoMedBild = ({
  blocks,
  sprak,
  customOfferModalIsOpen,
  setCustomOfferIsModalOpen,
}) => {
  const openModalWithData = (i) => {
    setCustomOfferIsModalOpen(i); // Open the modal for a specific item
  };

  const closeModal = () => {
    setCustomOfferIsModalOpen(null);
  };
  if (!blocks) {
    return null; // or some loading spinner
  }
 
  const renderBlock = (block) => {
    switch (block.type) {
      case "heading":
        return (
          <div className="avtal-info-heading">
            <h1 dangerouslySetInnerHTML={{ __html: block.value }} />
          </div>
        );
        case "old_version":
          return(
            <div className="avtal-second-container">
            <div className="avtal-second-column">
              <h2>Varför?</h2>
              <p style={{fontSize:16}} dangerouslySetInnerHTML={{ __html: block.value  }} />
              <h2>Pris</h2>
              <p style={{fontSize:16}} >
                Den tjänst vi utför baserar vi på våra allmänna villkor.
                Ändringar eller tillägg till en leveransen påverkar det
                överenskomna priset. Då säger vi till. All tid vi lägger i tid
                utöver den överenskomna leveransen debiterar vi på löpande
                timtaxa.
              </p>
            </div>
            <div className="avtal-second-side">
              {Object.values(avtal.hur).map((item, index) => (
                <>
                  <br></br>
                  <HowItem key={index} title={item.title} text={item.text} />
                </>
              ))}
              <br></br>
              <br></br>
            </div>
          </div>
          );
        case "paragraph":
            return (
              <div className="avtal-info-paragraph">
                <p style={{fontSize:16}} dangerouslySetInnerHTML={{ __html: block.value }} />
              </div>
            );
    case "hur_gar_det_till":
        return(
            <div className="avtal-second-side-v2">
                
            {Object.values(avtal.hur).map((item, index) => (
              <>
                <br></br>
                <HowItem key={index} title={item.title} text={item.text} />
              </>
            ))}
            <br></br>
            <br></br>
            <div className="avtal-second-column">
              <h2 style={{margin:0}}>*Pris</h2>
              <p style={{fontSize:16}} >
                Den tjänst vi utför baserar vi på våra allmänna villkor.
                Ändringar eller tillägg till en leveransen påverkar det
                överenskomna priset . Då säger vi till. All tid vi lägger i tid
                utöver den överenskomna leveransen debiterar vi på löpande
                timtaxa.
              </p>
            </div>
          </div>
        )


      case "cta":
        return (
          <div className="omrade-offer">
            <div style={{ flex: 1 }}>
              <h2>{block.value.heading}</h2>
              <p
                dangerouslySetInnerHTML={{ __html: block.value.paragraph }}
              ></p>
            </div>
            <div className="omrade-offer-sub">
              <br></br>
              {block.value.subblocks.map((subblock, i) => (
                <div key={i} className="omrade-cta-block">
                  <h2 style={{ margin: 0 }}>{subblock.heading}</h2>
                  <div
                    style={{ marginBottom: 0 }}
                    dangerouslySetInnerHTML={{ __html: subblock.paragraph }}
                  />
                  <button
                    onClick={() => openModalWithData(i)}
                    className="omrade-vemhur-link"
                  >
                    Skicka en förfrågan
                  </button>

                  {/* Modal Component */}
                  {customOfferModalIsOpen === i && (
                    <CustomOfferModal
                      type={subblock.type}
                      isOpen={true}
                      onClose={closeModal}
                      title={omrade.modal.title}
                      text={omrade.modal.text}
                      notext={true}
                      sprak={sprak}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        );
      case "vem_och_hur":
        return (
          <div className="omrade-vemhur">
            <div style={{ flex: 1 }}>
              <h2>{block.value.heading}</h2>
              <p
                dangerouslySetInnerHTML={{ __html: block.value.paragraph }}
              ></p>
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                gap: 10,
              }}
            >
              <br></br>
              {block.value.subblocks.map((subblock, i) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#f2f0ef",
                    padding: "20px 20px",
                  }}
                >
                  <h2 style={{ margin: 0 }}>{subblock.subheading}</h2>
                  <div
                    style={{ marginBottom: 0 }}
                    dangerouslySetInnerHTML={{ __html: subblock.subparagraph }}
                  />
                  <button
                    onClick={scrollToForm()}
                    className="omrade-vemhur-link"
                  >
                    Låt oss ta en dialog &rarr;
                  </button>
                </div>
              ))}
            </div>
          </div>
        );
      case "alla_tjanster":
        const alla_tjanster = block.value.subblocks.map((subblock, i) => ({
          title: subblock.subheading,
        }));
        return (
          <div
            className=""
            style={{ background: "#f2f0ef", width: "100%", padding: "30px 0" }}
          >
            <div className="omrade-alla-tjanster-text-col">
              <h2 dangerouslySetInnerHTML={{ __html: block.value.heading }} />
              <p style={{fontSize:16}} dangerouslySetInnerHTML={{ __html: block.value.paragraph }} />
            </div>
            <div className="omrade-alla-tjanster-item-container">
              {alla_tjanster.map((subblock, index) => (
                <h3 key={index} className=" omrade-alla-tjanster-item">
                  {subblock.title}
                </h3>
              ))}
            </div>
          </div>
        );
      case "references":
        return (
          <div className="omrade-ref-container">
            <h2 dangerouslySetInnerHTML={{ __html: block.value.heading }} />
            <p style={{fontSize:16}} dangerouslySetInnerHTML={{ __html: block.value.paragraph }} />
            <CompanyReviews items={sprak.listOfTitledReviews} />
          </div>
        );
      case "image":
        const { image, heading, paragraf, caption, url, imgurl, img_left } =
          block.value;
        return (
            
            <div className={`omrade-info-image-row ${img_left ? "" : "reverse"}`}>
            <div className="omrade-info-image-container">
              <img
                loading="lazy"
                src={`https://backend.lundbergandassociates.com${imgurl}`}
                alt={heading}
                className="omrade-info-image"
              />
              {caption && <p style={{fontSize:16}} className="omrade-info-caption">{caption}</p>}
            </div>
            <div className="omrade-info-text-container">
              <h2 className="omrade-info-image-heading">{heading}</h2>
              <div
                className="omrade-info-image-paragraph"
                dangerouslySetInnerHTML={{ __html: paragraf }}
              />
            </div>
            <br></br>
          </div>
       
          
        );
      case "tips":
        return (
          <div className="avtal-info-tips">
            <h2 className="avtal-tips-heading">{block.value.heading}</h2>
            <div
              className="avtal-tips-paragraph"
              dangerouslySetInnerHTML={{ __html: block.value.paragraph }}
            />
            <div className="avtal-tips-subblocks">
              {block.value.subblocks.map((subblock, index) => (
                <div key={index} className="avtal-tips-subblock">
                  <h3 className="avtal-subblock-heading">
                    {subblock.subheading}
                  </h3>
                  <div
                    className="avtal-subblock-paragraph"
                    dangerouslySetInnerHTML={{ __html: subblock.subparagraph }}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      case "fordelar":
        return (
          <div className="omrade-advantages-grid">
            <h2 dangerouslySetInnerHTML={{ __html: block.value.heading }} />
            <p style={{fontSize:16}} dangerouslySetInnerHTML={{ __html: block.value.paragraph }} />
            {block.value.subblocks.map((subblock, index) => (
              <div key={index} className="omrade-advantage-card">
                <h3 className="omrade-advantage-heading">
                  {subblock.subheading}
                </h3>
                <div
                  className="omrade-advantage-paragraph"
                  dangerouslySetInnerHTML={{ __html: subblock.subparagraph }}
                />
              </div>
            ))}
          </div>
        );
      case "fokus_tjanster":
        // Transform subblocks to match Accordion's expected props structure
        const fokus_tjanster = block.value.subblocks.map((subblock, i) => ({
          id: i, // or any unique identifier
          title: subblock.subheading,
          description: subblock.subparagraph,
          imgurl: subblock.imageurl,
        }));

        return (
          <div className="omrade-info-expert-accordion">
            <div className="omrade-expert-accordion-left">
              <h2 className="omrade-expert-accordion-heading">
                {block.value.heading}
              </h2>
              <div
                className="omrade-expert-accordion-paragraph"
                dangerouslySetInnerHTML={{ __html: block.value.paragraph }}
              />
            </div>
            <FokusGrid items={fokus_tjanster} />
            
            <CTA place={true} showdow={false}/>
          </div>
        );
      case "expert_accordion":
        // Transform subblocks to match Accordion's expected props structure
        const expert_accordion_item = block.value.subblocks.map(
          (subblock, i) => ({
            id: i, // or any unique identifier
            fraga: subblock.subheading,
            svar: subblock.subparagraph,
          })
        );

        return (
          <div className="omrade-info-expert-accordion">
            <div className="omrade-expert-accordion-left">
              <h2 className="omrade-expert-accordion-heading">
                {block.value.heading}
              </h2>
              <div
                className="omrade-expert-accordion-paragraph"
                dangerouslySetInnerHTML={{ __html: block.value.paragraph }}
              />
            </div>
            <div className="omrade-expert-accordion-right">
              {/* Pass the transformed data into your Accordion */}
              <AccordionDangerous
                questions={expert_accordion_item}
                col={true}
                cta={true}
              />
            </div>
          </div>
        );

      case "accordion":
        // Transform subblocks to match Accordion's expected props structure
        const questionsData = block.value.subblocks.map((subblock, i) => ({
          id: i, // or any unique identifier
          fraga: subblock.subheading,
          svar: subblock.subparagraph,
        }));

        return (
          <div className="avtal-info-accordion">
            <div className="avtal-accordion-left">
              <h2 className="avtal-accordion-heading">{block.value.heading}</h2>
              <div
                className="avtal-accordion-paragraph"
                dangerouslySetInnerHTML={{ __html: block.value.paragraph }}
              />
            </div>
            <div className="avtal-accordion-right">
              {/* Pass the transformed data into your Accordion */}
              <AccordionDangerous questions={questionsData} />
            </div>
          </div>
        );

      default:
        return null;
    }
  };
  blocks = JSON.parse(blocks)

  return (
    <div className="omrade-info-med-bild">
      {blocks.map((block, index) => (
        <div
          key={index}
          className={`avtal-info-block avtal-info-block-${block.type}`}
        >
          {renderBlock(block)}
        </div>
      ))}
    </div>
  );
};



function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}
function Avtal2(props) {
  const { tjanstId } = useParams(); // Anta att 'omrade' är URL-parametern du vill fånga upp
  const [data, setData] = useState({});
  const [showAll, setShowAll] = useState(false);
  const csrftoken = getCookie("csrftoken");
  const [customOfferModalIsOpen, setCustomOfferIsModalOpen] = useState(null);
  const [formData, setFormData] = useState({
    body: "",
    page: "",
    email: "",
    name: "",
    number: "",

    newsletter: false,
    terms: false,
  });

  const [errorData, setErrorData] = useState({
    body: false,
    email: false,
    terms: false,
    number: false,
    name: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("event", "conversion_event_submit_lead_form", {});
    let hasError = false;
    if (formData.terms === false) {
      setErrorData((prevState) => ({ ...prevState, terms: true }));
      hasError = true;
    }

    if (formData.number === "") {
      setErrorData((prevState) => ({ ...prevState, number: true }));
      hasError = true;
    }
    if (formData.name === "") {
      setErrorData((prevState) => ({ ...prevState, name: true }));
      hasError = true;
    }
    if (formData.email === "") {
      setErrorData((prevState) => ({ ...prevState, email: true }));
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },

      body: JSON.stringify(formData),
    };

    fetch(sendform, requestOptions)
      .then((response) => {
        if (response.ok) {
          window.location.href = props.sprak.tack.url;
        } else {
          console.error("Form submission failed");
        }
        return response.json();
      })
      .then((data) => console.log(data))
      .catch((error) => console.error("Error submitting form:", error));
  };

  const isSmallScreen = useMediaQuery({ maxWidth: 600 });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setFormData({ ...formData, page: data.herotitle });

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const canonicalUrl = canonUrl + avtal.canonurladdon + tjanstId;

  //let pageurl = fetchforspecificpage + "?kategori="+tjanstId+"&language="+props.sprak.sprak;
  let pageurl = exportMainUrl + avtal.fetchUrlAddon + tjanstId;

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchDataFromAPI = async () => {
      try {
        const response = await fetch(pageurl);
        const data = await response.json();
        setData(data);
        setMeta(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataFromAPI();
  }, [pageurl]);

const HeroCardV2 = (props) => {
  let tjList = JSON.parse(data.tjansteargument)
  if (!props.data) {
    return null; // or some loading spinner
  }
  return (
    <div className="avtal-hero-container">
    <div
      className="avtal-herov3-background"
      style={{
          backgroundImage: `url(${props.bg_img || avtalsratt})`,
                    backgroundSize: "cover",
      }}
    ></div>
      <div style={{zIndex:15, color:"white",textAlign:"center", marginBottom:-30}} className="avtal-hero-left">
        <h1 className="avtal-main-hero-h1"
         style={{}}>
          {props.data.herotitle}
          <span className="accent-text">{props.titleaccent}</span>
          {props.titlelast}
        </h1>

        <p style={{fontSize:16}} className="avtal-main-hero-p">{props.data.herotext}</p>
        <br></br>
        <br></br>
        {tjList && (
          <ul className="avtal-custom-list">
            {tjList.map((arg) => (
              <li key={arg.id}>{arg.value}</li>
            ))}
          </ul>
        )}
        <br></br>
        {props.data.readMoreUrl && (
          <div className="avtal-cta-row">
             <br></br>
             <br></br> <br></br>
             <br></br>
          <Link to={props.data.readMoreUrl} className="avtal-readmore-btn">
          Beställ {props.data.title}
        </Link>
          <Link to={props.data.readMoreUrl} className="avtal-readmore-link">
            Läs mer om {props.data.title}
          </Link>
          </div>
        )}
      </div>
      <div className="avtal-hero-right" style={{zIndex:15}}>
        <div className="avtal-buy-now-card">
          <img
            src={props.img}
            className="avtal-hero-main-img"
            alt={props.img}
          />

          <p  className="avtal-pris-p" style={{ textAlign: "center",fontSize:16 }}>
            <b>{props.data.herotitle}</b>
          </p>
          {props.data.pris &&
          <p style={{fontSize:16}} className="avtal-pris">{props.data.pris} kr</p>}
          <p style={{fontSize:16, textAlign:"center"}} className="avtal-pris-p" >
            Har du en fråga kring {props.data.title.toLowerCase()} eller behöver du hjälp? Hör av dig så hjälper vi dig, första konsultation är alltid gratis.
          </p>

          <br></br>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={props.sprak.nav.omoss.url}
          >
            {props.sprak.kundersbetyg} &rarr;
          </Link>
          <span style={{ color: "gold" }}>★★★★★</span>
          <br></br>
          <div className="avtal-button-container">
            <button onClick={props.openModal} className="avtal-cta-button">
              Hör av dig!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
  
const Hero = (props) => {
  if (!props.data) {
    return null; // or some loading spinner
  }
  return (
    <div className="avtal-hero-container">
      <div className="avtal-hero-left">
        <h1 className="avtal-main-hero-h1">
          {props.data.herotitle}
          <span className="accent-text">{props.titleaccent}</span>
          {props.titlelast}
        </h1>

        <p style={{fontSize:16}} className="avtal-main-hero-p">{props.data.herotext}</p>
        {props.data.tjansteargument && (
          <ul className="avtal-custom-list">
            {props.data.tjansteargument.map((arg) => (
              <li key={arg.id}>{arg.text}</li>
            ))}
          </ul>
        )}
        <br></br>
        {props.data.readMoreUrl && (
          <Link to={props.data.readMoreUrl} className="avtal-readmore-button">
            Läs mer om {props.data.herotitle}
          </Link>
        )}
      </div>
      <div className="avtal-hero-right">
        <div className="avtal-buy-now-card">
          <img
            src={props.img}
            className="avtal-hero-main-img"
            alt={props.img}
          />

          <p  className="avtal-pris-p" style={{fontSize:16, textAlign: "center" }}>
            <b>{props.data.herotitle}</b>
          </p>
          {props.data.pris &&
          <p style={{fontSize:16}} className="avtal-pris">{props.data.pris} kr</p>}
          <p style={{fontSize:16}} className="avtal-pris-p">
            Få råd och vägledning under en första kostnadsfri konsultation
          </p>

         
          <div className="avtal-button-container">
            <button onClick={props.openModal} className="avtal-cta-button">
              Hör av dig!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
/**
 * <Herov3
          data={data}
          img={data.heroImg
          }
          pris={data.pris}
          sprak={props.sprak}
          openModal={openModal}
        />
 * 
 */
  return (
    
        <DesktopComponent sprak={props.sprak} light={data.new_is_not_active ? false:true}>
        <CanonicalTag canonicalUrl={canonicalUrl} />
    {data.info_med_bild ?  <HeroCardV2
                    data={data}
                    img={heroImg}
                    sprak={props.sprak}
                    openModal={openModal}
                    bg_img={data.heroImg
                    }
                  />:     <Hero
                    data={data}
                    img={heroImg}
                    sprak={props.sprak}
                    openModal={openModal}
                  />}
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <div style={{ padding: 10 }}>
            <h4
              style={{
                ...secondaryMediumSmall,
                margin: " 0 0 5px 0",
                padding: 0,
                textAlign: "left",
              }}
            >
              {omrade.modal.title}
            </h4>
            {!props.notext && (
              <>
                <p
                  style={{
                    ...mainSmall,
                    textAlign: "left",
                    margin: 0,
                    padding: 0,
                    widt: "90%",
                  }}
                >
                  {omrade.modal.text}
                  <br></br>
                  <br></br>
                </p>

                <div style={{ marginLeft: "-50px" }}></div>
              </>
            )}
            <div
              style={{
                padding: "0 20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <form
                onSubmit={handleSubmit}
                noValidate
                style={{
                  padding: "0 20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TextField
                  style={{
                    flex: "1",
                    marginRight: isSmallScreen ? 40 : "unset",
                  }}
                  error={errorData.name}
                  helperText={errorData.name ? "Skriv in ditt namn." : ""}
                  id="standard-basic"
                  label={form.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  variant="standard"
                />

                <br></br>

                <TextField
                  style={{
                    flex: "1",
                    marginRight: isSmallScreen ? 40 : "unset",
                  }}
                  error={errorData.email}
                  helperText={errorData.email ? "Fyll i din mail." : ""}
                  id="standard-basic"
                  label={form.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  variant="standard"
                />
                <br></br>
                <TextField
                  style={{
                    flex: "1",
                    marginRight: isSmallScreen ? 40 : "unset",
                  }}
                  error={errorData.number}
                  helperText={
                    errorData.number ? "Fyll i ditt telefonnummer." : ""
                  }
                  id="standard-basic"
                  label={form.number}
                  onChange={(e) =>
                    setFormData({ ...formData, number: e.target.value })
                  }
                  variant="standard"
                />
                <br></br>

                <TextField
                  style={{
                    flex: "1",
                    marginRight: isSmallScreen ? 40 : "unset",
                  }}
                  id="standard-basic"
                  onChange={(e) =>
                    setFormData({ ...formData, body: e.target.value })
                  }
                  label={form.body}
                  error={errorData.body}
                  helperText={errorData.body ? "Skriv ett meddelande." : ""}
                  multiline
                  maxRows={4}
                  variant="standard"
                />

                <br></br>

                <FormControl
                  required
                  error={errorData.terms}
                  helperText={
                    errorData.terms ? "You must agree to terms." : ""
                  }
                  component="fieldset"
                  sx={{ m: 0 }}
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.terms}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              terms: e.target.checked,
                            })
                          }
                        />
                      }
                      label={
                        <div style={{ textAlign: "left" }}>
                          <Typography>
                            {form.termstext}
                            <a href={props.sprak.privacy.url}>
                              {form.termsLink}
                            </a>
                          </Typography>
                        </div>
                      }
                    />
                  </FormGroup>
                  <FormHelperText>
                    {errorData.terms && (
                      <>Integritetspolicyn måste godkännas</>
                    )}
                  </FormHelperText>
                </FormControl>

                {isSmallScreen && (
                  <>
                    <br></br>
                    <br></br>
                  </>
                )}

                <FormControl
                  component="fieldset"
                  sx={{ m: 0 }}
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.newsletter}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              newsletter: e.target.checked,
                            })
                          }
                        />
                      }
                      label={
                        <div style={{ textAlign: "left" }}>
                          <Typography>{form.newsletter}</Typography>
                        </div>
                      }
                    />
                  </FormGroup>
                </FormControl>

                {isSmallScreen && (
                  <>
                    <br></br>
                    <br></br>
                  </>
                )}
                <Button
                  variant="outlined"
                  onClick={handleSubmit}
                  endIcon={<ArrowForwardIcon />}
                  style={{
                    width: "100px",
                    alignSelf: "end",
                    color: "black",
                    borderColor: "black",
                    marginRight: "20px",
                    marginBottom: "20px",
                  }}
                >
                  {props.sprak.skicka}
                </Button>
              </form>
            </div>
          </div>
        </Modal>

        {data.info_med_bild ? (
          <InfoMedBild
            blocks={data.info_med_bild}
            sprak={props.sprak}
            customOfferModalIsOpen={customOfferModalIsOpen}
            setCustomOfferIsModalOpen={setCustomOfferIsModalOpen}
          />
        ):(<>
            <div className="avtal-second-container">
            <div className="avtal-second-column">
              <h2>Varför?</h2>
              <p style={{fontSize:16}} dangerouslySetInnerHTML={{ __html: data.whyText }} />
              <h2>Pris</h2>
              <p style={{fontSize:16}} >
                Den tjänst vi utför baserar vi på våra allmänna villkor.
                Ändringar eller tillägg till en leveransen påverkar det
                överenskomna priset . Då säger vi till. All tid vi lägger i tid
                utöver den överenskomna leveransen debiterar vi på löpande
                timtaxa.
              </p>
            </div>
            <div className="avtal-second-side">
              {Object.values(avtal.hur).map((item, index) => (
                <>
                  <br></br>
                  <HowItem key={index} title={item.title} text={item.text} />
                </>
              ))}
              <br></br>
              <br></br>
            </div>
          </div>

          {data.tjanstefraga && data.tjanstefraga.length > 0 && (
            <Accordion questions={data.tjanstefraga} />
          )}</>
        )}
        <br></br>
        <br></br>
        <br></br>
        <div
          id="form-id"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "50px 0",
          }}
        >
          <Form sprak={props.sprak} />
        </div>
        <br></br>
      </DesktopComponent>
    
  );
}

export default Avtal2;
