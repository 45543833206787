import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

export default class ReusableLottieControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isStopped: false, isPaused: false };
    this.animationTimeout = null;
  }

  componentDidMount() {
    this.playAnimationWithDelay();
  }

  componentWillUnmount() {
    clearTimeout(this.animationTimeout);
  }

  playAnimationWithDelay = () => {
    // Set a timeout to start the animation after 5 seconds
    this.animationTimeout = setTimeout(() => {
      this.setState({ isStopped: false });
    },3500); // 5000 milliseconds = 5 seconds
  };

  handleAnimationComplete = () => {
    // Reset animation state and play again after a delay
    this.setState({ isStopped: true }, () => {
      this.playAnimationWithDelay();
    });
  };

  render() {
    const { animationData } = this.props;

    const defaultOptions = {
      loop: false,
      autoplay: false,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <div>
        <Lottie
          options={defaultOptions}
          height={this.props.size}
          width={this.props.size}
          isStopped={this.state.isStopped}
          isPaused={this.state.isPaused}
          eventListeners={[
            {
              eventName: 'complete',
              callback: this.handleAnimationComplete,
            },
          ]}
        />

      </div>
    );
  }
}

ReusableLottieControl.propTypes = {
  animationData: PropTypes.object.isRequired,
};
