import DesktopComponent from "../../main";

import React, { useEffect ,useState} from 'react';
import Form from '../../components/generalwidgets/form';

import HomeKunder from "../screencomponents/homeKunder";
import "./css/home.css"
import Gallery from "../../components/generalwidgets/imageCard";
import { mainSmall, secondaryMedium,  } from "../../../static/fonts";
import { alldata, canonUrl } from "../../../static/urls";
import Affaren from "../screencomponents/affaren";
import CanonicalTag from "../../components/canonicalTag";
import HomeOmraden2 from "../screencomponents/homeomrade2";
import { setMeta } from "../screencomponents/widget/metadesc";

import homeimg from "../../../static/newimgs/home.webp"
import litenhomeimg from "../../../static/newimgs/mobile/home.webp"


import ImageHero from "../screencomponents/widget/heroes/imagehero";
import { useMediaQuery } from "react-responsive";
let cta ={
  first_cta: "Ring Direkt",
first_cta_url
: 
"tel:+46703557548",
second_cta
: 
"Fyll i formulär",
second_cta_url
: 
"/kontakt"

}
function Home(props) {
    const canonicalUrl= canonUrl+"/"

    let pageurl = alldata + `?language=${props.sprak.sprak}`

    useEffect(() => {
      window.scrollTo(0, 0);
      const fetchDataFromAPI = async () => {
          try {
              const response = await fetch(pageurl);
              const data = await response.json();

          } catch (error) {
              console.error('Error fetching data:', error);
          }
      };
   
  

// Set the attributes of the meta element
    // Create a new meta element

    let data1={
      meta:{
        seo_title:props.sprak.home.pagetitle,
        search_description:props.sprak.home.pagedesc
      }
    }
    // Set meta description
    setMeta(data1)
   
  }, [pageurl]);

  const isMobile = useMediaQuery({ maxWidth: 535 });

  return (
    <DesktopComponent sprak={props.sprak}>
                                    <CanonicalTag canonicalUrl={canonicalUrl} />
                                    {!isMobile?
            <ImageHero title={props.sprak.home.homeslogan}  cta={cta}   text={"Från nystartade till storskaliga bolag – vi finns här för att hjälpa dig lyckas"} img={homeimg}  sprak={props.sprak}/>
            :
            <ImageHero title={props.sprak.home.homeslogan}  cta={cta}   text={"Från nystartade till storskaliga bolag – vi finns här för att hjälpa dig lyckas"} img={litenhomeimg}  sprak={props.sprak}/>
      
    }
      
           <HomeKunder simple={true} sprak={props.sprak} />
<div style={{maxWidth:1200, width:"95%", margin:"0 auto"}}> 

           <h2 style={{textAlign:"center",marginTop:"100px",...secondaryMedium}}>
            {props.sprak.whatwedoh2}</h2>
            <p style={{textAlign:"left",width:"90%",maxWidth:"1000px", margin: "0 auto", marginBottom:"50px",...mainSmall}}>
{props.sprak.home.omraden.text}             </p>
         <HomeOmraden2 sprak={props.sprak} list={Object.values(props.sprak.falt)}/>

       <h2 style={{textAlign:"center" ,marginTop:"150px",marginBottom:"50px",...secondaryMedium}}>{props.sprak.home.branscher.title}</h2>
       <p style={{textAlign:"left",width:"90%",maxWidth:"1000px", margin: "0 auto", marginBottom:"50px",...mainSmall}}>
            {props.sprak.home.branscher.text} </p>

        <Gallery sprak={props.sprak}/>     


   

         <h2 style={{textAlign:"center" ,marginBottom:"20px",marginTop:"0",...secondaryMedium}}>
           {props.sprak.home.affaren.title}</h2>
           <p style={{textAlign:"left",width:"80%",maxWidth:"1000px", margin: "0 auto", marginBottom:"50px",...mainSmall}}>
         {props.sprak.home.affaren.text}</p>
           <Affaren sprak={props.sprak}/>

        <br></br>
        <br></br>
        <br></br>
        
         <Form sprak={props.sprak}/>
   
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        </div>
    </DesktopComponent>
  );
}

export default Home;
