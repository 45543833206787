import { useEffect } from "react";
import DesktopComponent from "../../main";
import CanonicalTag from "../../components/canonicalTag";
import { canonUrl } from "../../../static/urls";
import { setMeta } from "../screencomponents/widget/metadesc";

function Privacy(props) {
  useEffect(()=>{
    window.scrollTo(0, 0);
    
            let data1={
                    meta:{
                      seo_title:props.sprak.integritet.pagetitle,
                      search_description:props.sprak.integritet.pagedesc
                    }
                  }
                  // Set meta description
                  setMeta(data1)
  },[])
  const canonicalUrl = canonUrl+ "/integritet"

  /*


  privacy: { title: "Integritetspolicy", url: "/integritet", items:[
    {title:'', text:'Lundberg & Associates AB (”LA”) är mån om den personliga integriteten och att skydda de personuppgifter som behandlas. All behandling av personuppgifter sker i enlighet med gällande personuppgiftslagstiftning.Besök på vår hemsida kan ske utan att besökaren behöver lämna några personuppgifter eller acceptera cookies. Den som väljer att lämna in en anställningsansökan eller annars kontakta oss kommer däremot att lämna personuppgifter till oss. När olika personer har kontakt med oss eller förekommer i samband med de uppdragvi hanterar, inbegriper det att personuppgifter lämnas till oss eller inhämtas av oss. Även inom ramen för vår supportorganisation sker insamling och behandling av personuppgifter avseende kontaktpersoner hos leverantörer och andra externa parter. I det följande beskrivs hur vi samlar in, behandlar och delar personuppgifter i dessa fall. Nedan finns också information om de rättigheter som registrerade personer har i relation till oss som personuppgiftsansvarig samt våra kontaktuppgifter.'},
    {title:'Vilka personuppgifter behandlar vi?', text:'En stor del av vår kommunikation sker med hjälp av telefon och e-post, vilket i princip alltid innebär en personuppgiftsbehandling. Genom att skicka e-postmeddelanden eller ringa till oss lämnas i regel alltid personuppgifter som går att hänföra till personer.Vi samlar in de personuppgifter som lämnas till oss i samband med uppdrag eller som annars behandlas när uppdraget förbereds eller administreras. I huvudsak samlar vi in personuppgifter direkt från berörda personer, men det förekommer att vii uppdrag får information om involverade personer utan att informationen kommer direkt från dem. Vi kan även komma att komplettera personuppgifterna genom inhämtning av information från privata och offentliga register och källor. Normalt föreligger ingen skyldighet att lämna personuppgifter till oss, men utan att det sker kan vi inte åta oss ett uppdrag eftersom vi i så fall inte kan efterleva våra skyldigheter att t.ex. utföra jävs- och penningtvättskontroll. Insamling och behandling av personuppgifter sker även i vår supportorganisations verksamhet, bland annat i kontakter med leverantörer och andra externa parter.De personuppgifter vi behandlar kan röra kontaktuppgifter (t.ex. namn, titel, arbetsadress, telefonnummer och e-postadress), identifieringsinformation (t.ex. passuppgifter och födelsedatum/personnummer) samt faktureringsinformation (t.ex.kontonummer och skatteinformation). I specifika uppdrag kan personuppgifterna också avse annan information, t.ex. händelseförlopp eller andra omständigheter eller uppgifter som är relevanta för uppdraget.'},
    {title:'Vad är ändamålen med vår behandling av personuppgifter?', text:'Vi behandlar personuppgifter som lämnas eller inhämtas i samband med uppdrag föratt vi ska kunna fullgöra våra åtaganden och tillvarata våra uppdragsgivares intressen, sköta administrationen i samband med de uppdrag vi åtar oss samt uppfylla sådana krav som ställs i lag. Personuppgiftsbehandling sker också inom ramen för vår supportorganisation, för attvi ska kunna hantera och administrera våra relationer med leverantörer och andra externa parter.Vi kan även komma att använda personuppgifter som underlag för marknads- och klientanalyser, affärs- och metodutveckling, statistikändamål, riskhantering samt för marknadsföringsändamål.'},
    {title:'Vad är den lagliga grunden för vår behandling?', text:'Den lagliga grunden för behandlingen av personuppgifter är, i relation till information om fysiska personer, att vi ska kunna fullgöra det avtal som är grunden för det uppdrag vi åtagit oss. I relation till företrädare, samarbetspartners och konsulter, utländska ombud, vittnen, motparter, motpartsföreträdare och motpartsombud osv. grundar sig vår personuppgiftsbehandling normalt på en intresseavvägning. Det innebär att vi bedömer att behandlingen av personuppgifternaär nödvändig för de ändamål som rör vår uppdragsgivares eller, i förekommande fall, våra berättigade intressen och att dessa väger tyngre än eventuella motstående intressen eller grundläggande rättigheter och friheter.Behandlingen av personuppgifter som vi gör i samband arkiveringen av handlingar efter att ett uppdrag avslutats, grundas på vår skyldighet att fullgöra våra rättsliga förpliktelser (t.ex. bokförings- och penningtvättslagstiftning).Det kan också förekomma att vi i samband med de olika uppdrag vi åtagit oss har ytterligare grunder för behandlingen.Behandling av personuppgifter hänförliga till leverantörer eller dess företrädare och andra externa parter grundas på vårt berättigade intresse av att administrera relationen och fullgöra våra avtalsförpliktelser.När vi behandlar personuppgifter i syfte att analysera och utveckla vår verksamhet och för marknadskommunikation, sker behandlingen på grundval av vårt berättigade intresse av att förbättra och att marknadsföra vår verksamhet.'},
    {title:'Vem har tillgång till de personuppgifter vi behandlar?', text:'Vi har vidtagit lämpliga tekniska och organisatoriska säkerhetsåtgärder för att skyddade personuppgifter vi behandlar mot bl.a. förlust och obehörig åtkomst. Överföringar av uppgifter utanför EU/EES sker i enlighet med tillämplig personuppgiftslagstiftning och för de ändamål som anges ovan. I samband med överföring till byråns utlandskontor kan personuppgifter komma att överföras till länder utanför EU/EES. Denna typ av överföringar grundas normalt på EU-kommissionens standardavtalsklausuler. I övrigt kan överföring till länder utanför EU/EES ske inom ramen för ett visst uppdrag, i den mån det är nödvändigt för att fastställa, göra gällande eller försvara vår uppdragsgivares rättsliga anspråk.LA kommer inte att lämna ut personuppgifter till någon utanför LA annat än i de fall då', items:["det överenskommits mellan oss och den vars personuppgifter vi behandlar,","det inom ramen för ett visst uppdrag är nödvändigt för att vi ska kunna tillvarata våra uppdragsgivares rättigheter och intressen,","det är nödvändigt för att vi ska fullgöra en lagstadgad skyldighet, efterkomma ett myndighetsbeslut eller ett beslut av domstol,","vi anlitar en utomstående tjänsteleverantör eller samarbetspartner som utför tjänster för vår räkning. Sådana tjänsteleverantörer och samarbetspartners får endastbehandla personuppgifter i enlighet med våra instruktioner och får inte använda personuppgifter för egna ändamål; eller","det annars tillåts enligt lag."]},
    {title:'Hur länge sparas personuppgifter?', text:'Vi sparar inte personuppgifterna längre än nödvändigt med hänsyn till ändamålet för behandlingen, om inte uppgifterna måste eller får sparas längre enligt lag.De personuppgifter som kan komma att behandlas inför och i samband med utförande av uppdrag sparas under uppdragets handläggning och bevaras efter uppdragets slutförande. Det innebär att personuppgifterna sparas under minst tio år från dagen för uppdragets slutförande, eller den längre tid som påkallas av uppdragets natur.'},
    {title:'Vilka rättigheter har den vars personuppgifter behandlas av oss?', text:'Lundberg & Associates AB, org nr 559131-8802, med adress Bäckmans väg 19, 444 55Stenungsund, är personuppgiftsansvarig för den behandling av personuppgifter som beskrivs ovan. Det innebär att vi ansvarar för att personuppgifterna behandlas korrekt och i enlighet med gällande personuppgiftslagstiftning.Registrerade personer har rätt att få veta vilka personuppgifter vi behandlar om dem. En registrerad person har också rätt att begära att felaktiga eller ofullständiga personuppgifter om denne rättas eller att vi raderar dessa personuppgifter (t.ex. om personuppgifterna inte längre är nödvändiga för ändamålet eller om ett lämnat samtycke återkallas). Registrerade personer har vidare rätt att invända mot viss behandling av personuppgifter och begära att behandlingen av personuppgifter begränsas. Slutligen har registrerade personer rätt att få ut sådana personuppgifter som de tillhandahållit i ett maskinläsbart format och att överföra dessa till en annan personuppgiftsansvarig.En begränsning eller radering av personuppgifter kan också innebära att vi inte har möjlighet att fullfölja våra åtaganden.Den som har invändningar eller synpunkter på hur vi behandlar personuppgifter har rätt att kontakta eller lämna in klagomål hos Datainspektionen, som är tillsynsmyndighet för vår personuppgiftsbehandling.Vid frågor om eller klagomål på hur vi behandlar personuppgifter eller begäran om utövande av rättigheter enligt ovan, vänligen kontakta oss via e-post på kontakt@lundbergandassociates.com eller per brev till adressen ovan.'},
  ] },
  a :{title:"asd", items:[
    {title:"", text:""}
  ]}
  */

       const containerStyle = {
        maxWidth: '1000px',    minHeight:"100vh",
    paddingTop: '150px',
    margin: "auto",
    paddingBottom:"150px"
  };

  const listStyle = {
    listStyleType: 'none', // Removes bullets
    paddingLeft: 0, // Removes default padding
  };

  const textStyle = {
    textAlign: 'left', // Aligns text to the left
  };
  return (
    <DesktopComponent sprak={props.sprak} light={true}>
                                      <CanonicalTag canonicalUrl={canonicalUrl} />

 
    <div style={containerStyle}>
      <h1>{props.sprak.privacy.title}</h1>
      <ul style={listStyle}>
        {props.sprak.privacy.items.map((item, index) => (
          <li key={index}>
            <h2 style={textStyle}>{item.title}</h2>
            <p style={textStyle}>{item.text}</p>
          </li>
        ))}
      </ul>
    </div>
  
        
    </DesktopComponent>
  );
}

export default Privacy;
