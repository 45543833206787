export const mainLarge = {fontWeight:"400", fontSize:"40px",  fontFamily: "Montserrat"}
export const mainMedium = {fontWeight:"400", fontSize:"25px"}
export const mainMediumSmall ={fontWeight:"400", fontSize:"20px"}
export const mainSmall = {fontWeight:"400", fontSize:"18px"}
export const mainMicro= {fontWeight:"400", fontSize:"15px",lineHeight:"23px",}
export const secondaryLarge = {fontWeight:"600", fontSize:"40px"}
export const secondaryMediumLarge = {fontWeight:"600", fontSize:"35px"}

export const secondaryMedium = {fontWeight:"600", fontSize:"25px"}
export const secondaryMediumSmall = {fontWeight:"600", fontSize:"20px"}
export const secondarySmall = {fontWeight:"600", fontSize:"18px"}



