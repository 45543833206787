import React, { useEffect } from 'react';

const CanonicalTag = ({ canonicalUrl }) => {
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'canonical';
    link.href = canonicalUrl;
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, [canonicalUrl]);

  return null; // Since we're dynamically adding the tag, no need to render anything
};

export default CanonicalTag;