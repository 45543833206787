import { colors } from "../../../static/colors";
import prefab from "../../../static/img/kunder/prefab.webp";
import COC from "../../../static/img/kunder/COC.webp";

import lnc from "../../../static/img/kunder/lnc.webp";
import f57 from "../../../static/img/kunder/57.webp";
import z from "../../../static/img/kunder/z.webp";
import foretags from "../../../static/img/kunder/foretags.webp";
import seniorproffs from "../../../static/img/kunder/SeniorProffs.webp";
import lvira from "../../../static/img/kunder/lvira.webp";
import jarisafe from "../../../static/img/kunder/jari-safe.webp";
import lyckebohus from "../../../static/img/kunder/lyckebohus.webp";
import dagusbygg from "../../../static/img/kunder/dagusbygg.webp";
import se360 from "../../../static/img/kunder/se360.webp";
import bostadsregistret from "../../../static/img/kunder/bostadsregistret.webp";
import { useMediaQuery } from "react-responsive";
import Marquee from "react-fast-marquee";
import "./css/homekunder.css";
import React, { useState } from "react";





const KundImg = (props) => {
  const aspectRatio = (props.width || 1) / (props.height || 1);
  // Set the width and height based on the aspect ratio
  const width = aspectRatio > 1 ? "100%" : "auto";
  const height = aspectRatio > 1 ? "auto" : "100%";
  const contrastALot = "contrast(50%)";
  const contrastALittle = "contrast(70%)";
  const isSmall = useMediaQuery({ maxWidth: 961 });
  const isMegaSmall = useMediaQuery({ maxWidth: 660 });

  let maxWidth = "200px"; // Adjusted based on isSmall
  let maxHeight = "100px"; // Adjusted based on isSmall
  if (isSmall && !isMegaSmall) {
    maxWidth = "150px";
    maxHeight = "75px";
  } else if (isMegaSmall) {
    maxWidth = "100px";
    maxHeight = "60px";
  }

  return (
    <div
      className="kund-card  grayscale"
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
    
        justifyContent: "center",
        alignItems: "center",
      }}
      
    >
      <img
        src={props.src}
        loading="eager"
        alt={props.alt}
        style={{
          backgroundColor: props.backgroundColor
            ? props.backgroundColor
            : "transparent",
          transition: "filter .4s ease",
          filter: props.invert ? "invert(100%) " : "invert(0%) ",

          maxHeight: maxHeight,
          maxWidth: maxWidth,
          width: "95%",
          height: "90%",
          objectFit: "contain", // Maintain aspect ratio while fitting within the container
        }}
      />
    </div>
  );

};

const HomeKunder = (props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" }); // ✅ Define here

  return (
    <div className="marquee-container">
      <Marquee         gradient={!isMobile} // ✅ Fixed logic
 gradientWidth={isMobile? 0:200} speed={50}>
    <KundImg src={foretags} alt={"Företagsförmedlingen"} />
            <KundImg contrastALot src={lnc} alt={"Lynk & Co"} />
            <KundImg contrastALittle src={COC} alt={"Care of Carl"} />
            <KundImg invert src={f57} alt={"57 Footwear"} />

            <KundImg
              contrastALittle
              backgroundColor={"#08445c"}
              src={prefab}
              alt={"Prefabsystem "}
            />
                        <KundImg svg src={seniorproffs} alt={"SeniorProffs"} />
                        <KundImg svg src={lvira} alt={"Lvira"} />
                        <KundImg svg src={jarisafe} alt={"Jari Safe"} />
                        <KundImg svg src={lyckebohus} alt={"lyckebohus"} />
                        <KundImg svg src={dagusbygg} alt={"Dagus Byggnads AB"} />
                        <KundImg svg src={se360} alt={"se360"} />
                        <KundImg svg src={bostadsregistret} alt={"bostadsregistret"} />


            <KundImg svg src={z} alt={"Zeppelin"} />
            </Marquee>

      </div>
  );
};

export default HomeKunder;
