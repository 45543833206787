import DesktopHeader from "./components/header";
import DesktopFooter from "./components/footer";
import { useEffect } from "react";
import MapComponent from "./screens/screencomponents/widget/mapcomponent";

function DesktopComponent(props) {
  useEffect(() => {
    const metaTag = document.createElement('meta');
    metaTag.setAttribute('name', 'og:site_name');
    metaTag.setAttribute('content', "Lundberg & Associates");
  
    document.head.appendChild(metaTag);
  }, []);


  return (
    <div className="desktop-root">
        <DesktopHeader sprak={props.sprak} light={props.light}/>

        {props.children} 
        {!props.locationRemoved &&<MapComponent/>}
        <DesktopFooter sprak={props.sprak}/>

    </div>
  );
}

export default DesktopComponent;
