import React, { useEffect } from 'react';
import { canonUrl } from "../../../static/urls";
import DesktopComponent from "../../main";
import CanonicalTag from '../../components/canonicalTag';
import "./css/kontakt.css";
import Formlight from '../../components/generalwidgets/formlight';
import homeimg from "../../../static/newimgs/home.webp"
import { useMediaQuery } from 'react-responsive';
import { setMeta } from '../screencomponents/widget/metadesc';

function Kontakt(props) {
  const canonicalUrl = canonUrl + "/kontakt";

  useEffect(() => {
    window.scrollTo(0, 0);
    let data1={
      meta:{
        seo_title:props.sprak.kontakt.pagetitle,
        search_description:props.sprak.kontakt.pagedesc
      }
    }
    // Set meta description
    setMeta(data1)
  }, []);
/*

*/  const isMobile = useMediaQuery({ maxWidth: 1100 });
    
  return (
    <DesktopComponent sprak={props.sprak} light={true} locationRemoved={true}>
      <CanonicalTag canonicalUrl={canonicalUrl} />

      <div className="kontakt-container">
        {!isMobile ? (
      <div className='kontakt-sub-container-kontakt-desktop'>
   
   
   <div className="kontakt-info">
     <>
     <br></br>
     <h3 className='kontakt-info-header'>Ställ en fråga</h3>
     <p className='kontakt-info-p'> Tveka inte att ställa en fråga, det är kostnadsfritt! Vi återkopplar inom en timme och svarar på din fråga inom 24h - vissa frågor 
       kräver lite betänktetid för att det ska bli rätt.
       </p>
       <br></br>
     </>
     <br></br>
     <img className="kontakt-info-img" src={homeimg} alt="En bild på oss"/>

 
   </div>
   <div className='kontakt-info-2'>
 <h3 className='kontakt-info-header'>Hör av dig</h3>

   <Formlight sprak={props.sprak} />
   </div>
 </div>
        ):(
 <div className='kontakt-sub-container-kontakt-mobile'>
   
   
   <div className="kontakt-info">
 
     <>
     <img className="kontakt-info-img" src={homeimg} alt="En bild på oss"/>
 <br></br>
 <h3 className='kontakt-info-header'>Ställ en fråga</h3>
     <p className='kontakt-info-p'> Tveka inte att ställa en fråga, det är kostnadsfritt! Vi återkopplar inom en timme och svarar på din fråga inom 24h - vissa frågor 
       kräver lite betänktetid för att det ska bli rätt.
       </p>
     </>

 
   </div>
   <div className='kontakt-info-2'>

   <Formlight sprak={props.sprak} />
   </div>
 </div>
        )}
      </div>
      <br></br>
        <br></br>
        <div className='kontakt-star-container'>
          <h3 className='kontakt-star-container-h3'>
        {props.sprak.kundersbetyg}
        </h3>
        
        <span style={{color:"gold"}}>★★★★★</span>
        </div>

      <br></br>       


    </DesktopComponent>
  );
}

export default Kontakt;
