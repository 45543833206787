const legalcore = {
    li: [
      "Avtalshantering",
      "GDPR-hantering",
      "Enkel tillgång till företagets juridik",
      "Juridisk Riskanalys",
      "Enkel tillgång till juridisk rådgivning",
    ],
  };
   const options = {
    basic: {
      name: "basic",
      title: "Idea",
      pris: "395 kr/mån",
      txt: "Få tillgång till LegalCore, en platform för att hålla koll på hela företagets juridik. När du väl behöver oss får du ta del av vår rabatt som kommer med platformen.",
      li: ["25% rabatt på allt", "Fasta priser på vanliga tjänster", "LegalCore"],
      legalcore: legalcore,
    },
    mikro: {
      name: "mikro",
      title: "Start",
      txt: "För mindre företag som vill ha koll på sina avtal. Med Start betalar du en fast månadskostnad för en egen husjurist är insatt i din situation.",
      li: ["50% rabatt på avtalstimmar", "25% rabatt därefter", "LegalCore"],
  
      legalcore: legalcore,
      timmar: "5h/år",
      pris: "729 /mån",
    },
    liten: {
      name: "liten",
      title: "Growth",
      txt: "För småföretag som har ett litet behov av juridik. Med Growth hjälper vi dig aktivt att hålla dig uppdaterad, så att du kan fokusera på er tillväxt.",
      li: ["50% rabatt på avtalstimmar", "25% rabatt därefter", "LegalCore"],
      legalcore: legalcore,
      timmar: "från 15h/år",
      pris: "2187/mån",
    },
    medium: {
      name: "medium",
      title: "Scale",
      txt: "För företag som har kommit en bit påvägen. Med Scale har du en chefsjurist som känner bolagets situation, ledningen och nyckelpersoner - så att allt flyter på som det ska. Ni får självklart tillgång till en juristavdelning med bredkompetens.",
      li: ["50% rabatt på avtalstimmar", "25% rabatt därefter", "LegalCore"],
      legalcore: legalcore,
      timmar: "från 33h/år",
      pris: " 4812kr/mån",
    },
    stor: {
      name: "stor",
      title: "Enterprise",
      txt: "För stora företag. Med Enterprise, jobbar vi aktivt i flera avdelningar ofta samtidigt. Vi har direkt dialog med ledning, mellanchefer och nyckelpersoner för att undvika flaskhalsar. Vi tar ett aktivt grepp om företagets juridik för att hjälpa er undvika problem i döda vinklar.",
      li: [
        "50% rabatt på avtalstimmar",
        "25% rabatt därefter",
        "Fasta priser på vanliga tjänster",
        "LegalCore",
      ],
      legalcore: legalcore,
      pris: "10937kr/mån",
      timmar: "från 75h/år",
    },
  };
  export const selectedOption = {
    basic: {
      name: "basic",
      title: "Avtalspaket",
      pris: "",
      txt: "Medan du fokuserar på att få fart på fartyget hjälper vi dig punktmarkera där det behövs, till ett fast pris.",
      li: ["Fasta priser på vanliga tjänster"],
    },
    mikro: {
      name: "mikro",
      title: "Standard",
      txt: " För företag som är i behov juridisk rådgivning vid enstaka tillfällen. Betala per timme och få tillgång till kvalificerad juridisk hjälp efter behov. ",
      li: [
        "Konkurrenskraftiga standardpriser på löpande taxa",
        "3500kr/h för våra experter",
        "1750kr/h för juniora jurister",
	
      ],
    },
    liten: {
      name: "liten",
      title: "Lilla Start Paketet",
      txt: " För mindre företag som önskar en flexibel juridisk lösning för att hantera avtal och enklare juridiska ärenden.",
      li: [
        "0.5h rådgivning/månad - till 50% rabatt",
        "25% rabatt på tilläggstimmar",
	" Outnyttjade timmar sparas",

  
      ],
      
      
      timmar: "6h/år",
      pris: " 975kr/mån",
    },
    medium: {
      name: "medium",
      title: "Start Paketet",
      txt: " För företag som vill säkerställa kontinuerlig rådgivning och proaktivt hantera juridiska utmaningar.",
      li: [
        "1h rådgivning/månad - till 50% rabatt",
        "25% rabatt på tilläggstimmar",
	" Outnyttjade timmar sparas",

      ],
      timmar: "12h/år",
      pris: " 1750kr/mån",
    },
    mediumlarge: {
      name: "mediumlarge",
      title: "Stora Start Paketet",
      txt: " För växande företag med återkommande juridisk rådgivning. Med 24 timmar per år får du tillgång till juridisk expertis anpassad efter din verksamhets behov.",
      li: [
        "2h rådgivning/månad - till 50% rabatt",
        "25% rabatt på tilläggstimmar",
	" Outnyttjade timmar sparas",
      ],
      timmar: "24h/år",
      pris: " 3750kr/mån",
    },
    stor: {
      name: "stor",
      title: "Enterprise",
      txt: " Ett skräddarsytt juridiskt paket för större företag med komplexa behov. Från 66 timmar per år – en flexibel lösning som säkerställer kontinuerlig juridisk rådgivning och strategiskt stöd.",
      li: [
        "66h à 30% rabatt",
        "120h à 35% rabatt",
        "200h à 40% rabatt",
	" Outnyttjade timmar sparas",

      ],
      pris: "",
      timmar: "från 66h/år",
    },
  };

  
  const options3 = {
    basic: {
      name: "basic",
      title: "Avtalspaket",
      pris: "",
      txt: "Medan du fokuserar på att få fart på fartyget hjälper vi dig punktmarkera där det behövs, till ett fast pris.",
      li: [
          "Fasta priser på vanliga tjänster",
          "Inga dolda avgifter",
          "Perfekt för företag som vill ha förutsägbara kostnader",
          "Trygghet med juridiskt stöd anpassat efter behov",
      ],
    },
    mikro: {
      name: "mikro",
      title: "Standard",
      txt: "Engångs- och projektbaserat juridiskt stöd för alla företag",
      li: [
        "Ingen månadsavgift – betala endast för det du använder",
        "Konkurrenskraftiga standardpriser på löpande taxa",
        "Flexibel tillgång till erfarna jurister",
        "3500kr/h för våra experter",
        "1750kr/h för alla andra",
      ],
    },
    medium: {
      name: "medium",
      title: "Start Paketet",
      txt: "Juridisk stödplan utformad för nystartade företag och mindre företag.",
      li: [
          "Perfekt för startups och små företag",
        "1 timme per månad",
        "50% rabatt tilläggstimmar (1750kr/h)",
        "Prisvärd juridisk rådgivning för tillväxt",
        "LegalCore",
      ],
      legalcore: legalcore,
      timmar: "1h/mån",
      pris: " 2000kr/mån",
    },
    stor: {
      name: "stor",
      title: "Enterprise",
      txt: "Fullständigt kontohanterad tjänst för företag med mer komplexa juridiska behov.",
      li: [
          "För etablerade företag med större behov",
          "Skräddarsydd juridisk support och prioriterad service",
          "Dedikerad kontaktperson och juridiskt team",
        "66h à 30% rabatt",
        "120h à 35% rabatt",
        "200h à 40% rabatt",
        "LegalCore",
      ],
      legalcore: legalcore,
      pris: "från 12833kr/mån",
      timmar: "från 66h/år",
    },
  };