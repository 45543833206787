import { useEffect, useState } from "react";
import DesktopComponent from "../../main";
import { blogs, canonUrl } from "../../../static/urls";
import ThirdHero from "../screencomponents/widget/heroes/thirdhero";
import Filter from "../../components/generalwidgets/filter";
import { mainSmall, secondaryMedium, secondaryMediumSmall } from "../../../static/fonts";
import { Link } from "react-router-dom";
import { colors } from "../../../static/colors";
import CanonicalTag from "../../components/canonicalTag";
import bloggimg from "../../../static/newimgs/blogg.webp"
import bloggimgliten from "../../../static/newimgs/mobile/blogg.webp"
import { setMeta } from "../screencomponents/widget/metadesc";

import ImageHero from "../screencomponents/widget/heroes/imagehero";
import { useMediaQuery } from "react-responsive";
let cta ={
  first_cta: "Ring Direkt",
first_cta_url
: 
"tel:+46703557548",
second_cta
: 
"Fyll i formulär",
second_cta_url
: 
"/kontakt"

}
function BlogLista(props) {
  let pageurl = blogs + "?language=" + props.sprak.sprak;
  const [blogData, setBlogData] = useState([])
  const [displayFilter, setDisplayFilter] = useState(false);
  const canonicalUrl = canonUrl+ props.sprak.blogLista.url

  const [kategori, setKategori] = useState(props.sprak.alla);
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchDataFromAPI = async () => {
      try {
        const response = await fetch(pageurl);
        const data = await response.json();

        setBlogData(data.results);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

   fetchDataFromAPI();
   let data1={
    meta:{
      seo_title:props.sprak.blogLista.pagetitle,
      search_description:props.sprak.blogLista.pagedesc
    }
  }
  // Set meta description
  setMeta(data1)
     
  
 
  }, [pageurl]);

  const renderBlogCards = () => {
    return blogData.map((blog) => (
      <Link    style={{
        border: "1px solid #ccc",
        borderRadius: "5px",
        height: "150px",
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        marginBottom: "10px",
        maxWidth: "400px",
        textDecoration:"none",
        color:colors.textMain,
        padding: "0 20px",
        width: "100%"
      }} key={blog.id} to={props.sprak.blogLista.url+blog.slug}>
       
          <h4 style={{ ...secondaryMediumSmall, marginBottom: "10px", textAlign: "left" }}>
            {blog.title} &rarr;
          </h4>
  
          <p style={{ alignSelf: "flex-start", justifySelf: "flex-end", marginTop: "auto" }}>
            {props.sprak.blogLista.article}
          </p>
          {/* Add more fields as needed */}
 
      </Link>
    ));
  };
  const isMobile = useMediaQuery({ maxWidth: 750 });

  return (
    <DesktopComponent sprak={props.sprak}>
                                    <CanonicalTag canonicalUrl={canonicalUrl} />
                       
     {!isMobile?
                                    <ImageHero title={props.sprak.blogLista.title} cta={cta}   img={bloggimg} h2={props.sprak.blogLista.subtitle} text={props.sprak.blogLista.text} sprak={props.sprak}/>
                                    :
                                    <ImageHero title={props.sprak.blogLista.title} cta={cta}   img={bloggimgliten} h2={props.sprak.blogLista.subtitle} text={props.sprak.blogLista.text} sprak={props.sprak}/>
      
    }

      
      {displayFilter && <Filter
        list={props.sprak.falt}
        sprak={props.sprak}
        setSelectedItem={setKategori}
        selectedItem={kategori}
      />}
     
      <div
        style={{

          margin:"50px auto",
          display: "flex",
          flexWrap:"wrap",
          width:"90%",
          maxWidth:"1200px",
          alignItems: "center",
          justifyContent: "center",
          gap:"20px",        }}
      >
         {blogData.length ==0 ? (
       <div style={{display:"flex", flexDirection:"column", marginTop:"-40px", marginBottom:"20px"}}>
       <h2>... Ehm, pinsamt.. Ursäkta!</h2>
       <p> Vår tekniker jobbar på det!</p>
       </div>
       ):
       renderBlogCards()}
        
      </div>
    </DesktopComponent>
  );
}

export default BlogLista;
