import React, { useState, useEffect } from "react";
import { colors } from "../../static/colors";

import NavBtn from "./generalwidgets/navBtn";
import Btn from "./generalwidgets/btn";
import { useMediaQuery } from "react-responsive";
import logo from "../../static/img/logo-full.svg";
import { Link } from "react-router-dom";
import "./css/header.css";
import GoogleReviewBanner from "../screens/screencomponents/widget/reviewbanner";
const nav= {
  tjanster: {
    show: true,

    title: "Tjänster",
    url: "/tjanster",
    navlink: "tjanster",
    rightcol:{heading:"Våra tjänster", text:"Vi gör det enkelt att få rätt juridisk hjälp när du behöver den. På denna sida hittar du ett urval av våra tjänster till fasta priser, designade för att lösa dina specifika juridiska behov snabbt och effektivt. "},
    children: [
      
        {
          title: "Aktieägaravtal",
          url: "/tjanster/aktieagaravtal",
          heading:"Våra tjänster", text:"Vi gör det enkelt att få rätt juridisk hjälp när du behöver den. På denna sida hittar du ett urval av våra tjänster till fasta priser, designade för att lösa dina specifika juridiska behov snabbt och effektivt. "
        },
             {
          title: "Aktieöverlåtelseavtal",
          url: "/tjanster/aktieoverlatelseavtal",
heading:"Våra tjänster", text:"Vi gör det enkelt att få rätt juridisk hjälp när du behöver den. På denna sida hittar du ett urval av våra tjänster till fasta priser, designade för att lösa dina specifika juridiska behov snabbt och effektivt. "
        },
        {
          title: "Allmänna villkor",
          url: "/tjanster/allmanna-villkor",
heading:"Våra tjänster", text:"Vi gör det enkelt att få rätt juridisk hjälp när du behöver den. På denna sida hittar du ett urval av våra tjänster till fasta priser, designade för att lösa dina specifika juridiska behov snabbt och effektivt. "
        },
        {
          title: "GDPR Utbildning",
          url: "/tjanster/gdpr-utbildning",
heading:"Våra tjänster", text:"Vi gör det enkelt att få rätt juridisk hjälp när du behöver den. På denna sida hittar du ett urval av våra tjänster till fasta priser, designade för att lösa dina specifika juridiska behov snabbt och effektivt. "
        },
        {
          title: "Inkråmsöverlåtelseavtal",
          url: "/tjanster/inkramsoverlatelseavtal",
                 heading:"Våra tjänster", text:"Vi gör det enkelt att få rätt juridisk hjälp när du behöver den. På denna sida hittar du ett urval av våra tjänster till fasta priser, designade för att lösa dina specifika juridiska behov snabbt och effektivt. "
        },{
          title: "Integritetspolicy",
          url: "/tjanster/integritetspolicy",
                 heading:"Våra tjänster", text:"Vi gör det enkelt att få rätt juridisk hjälp när du behöver den. På denna sida hittar du ett urval av våra tjänster till fasta priser, designade för att lösa dina specifika juridiska behov snabbt och effektivt. "
        },
    
        {
          title: "Licensavtal",
          url: "/tjanster/licensavtal",
               heading:"Våra tjänster", text:"Vi gör det enkelt att få rätt juridisk hjälp när du behöver den. På denna sida hittar du ett urval av våra tjänster till fasta priser, designade för att lösa dina specifika juridiska behov snabbt och effektivt. "
        },
        {
          title: "Offertmall",
          url: "/tjanster/offertmall",
         heading:"Våra tjänster", text:"Vi gör det enkelt att få rätt juridisk hjälp när du behöver den. På denna sida hittar du ett urval av våra tjänster till fasta priser, designade för att lösa dina specifika juridiska behov snabbt och effektivt. "
        },
        {
          title: "VD-avtal",
          url: "/tjanster/vd-avtal",
         heading:"Våra tjänster", text:"Vi gör det enkelt att få rätt juridisk hjälp när du behöver den. På denna sida hittar du ett urval av våra tjänster till fasta priser, designade för att lösa dina specifika juridiska behov snabbt och effektivt. "
        },
        {
          title: "Uppdragsavtal",
          url: "/tjanster/uppdragsavtal",
         heading:"Våra tjänster", text:"Vi gör det enkelt att få rätt juridisk hjälp när du behöver den. På denna sida hittar du ett urval av våra tjänster till fasta priser, designade för att lösa dina specifika juridiska behov snabbt och effektivt. "
        },
        {
          title: "Mer",
          url: "/tjanster",
         heading:"Våra tjänster", text:"Vi gör det enkelt att få rätt juridisk hjälp när du behöver den. På denna sida hittar du ett urval av våra tjänster till fasta priser, designade för att lösa dina specifika juridiska behov snabbt och effektivt. "
        },
      ],
    
  },
  branscher: {
    rightcol:{heading:"Bransch erfarenhet", text:"Varje bransch har sina egna juridiska utmaningar och krav. Här får du en översikt över de branscher vi har lång erfarenhet av att arbeta inom, samt de specifika behov som vi ser i varje sektor. Vår erfarenhet inom olika branscher gör att vi kan anpassa våra lösningar för att effektivt möta de unika förutsättningarna i din verksamhet."},

    show: true,
    title: "Branscher",
    url: "/branscher",
    navlink: "branscher",
    children: [
      {
        title: "Detaljhandel",
        url: "/",
               heading:"Detaljhandel",
        text:"Detaljhandeln innebär snabba förändringar och ett stort tryck på att hela tiden vara anpassningsbar. Juridiska frågor kring affärsavtal och digital handel kräver noggrant övervägande för att undvika risker och skapa långsiktig framgång. Oavsett om du är en fysisk butik eller en aktör på nätet, handlar det om att hitta juridiska lösningar som gör det möjligt att växa samtidigt som du säkerställer att affärsmodeller och kundupplevelse alltid följer marknadens och lagens krav."
      },
        {
          title: "Fastigheter",
          url: "/branscher/fastigheter",
          heading:"Fastigheter",
          text:"Fastighetsmarknaden är präglad av långsiktiga investeringar och komplexa transaktioner, där varje beslut har stor påverkan på verksamhetens framtid. Rättsliga frågor kring markanvändning, bygglov och fastighetsförvaltning är en självklar del av att driva en framgångsrik fastighetsportfölj. Effektiva lösningar krävs för att hantera den snabbt föränderliga marknaden och säkerställa att alla processer följer rätt juridiska ramverk."
        },
        {
          title: "Fordon",
          url: "/branscher/fordon",
                heading:"Fordonsindustri",
          text:"Fordonsindustrin är i ständig rörelse, där innovationer och tuffare säkerhetskrav ställer allt högre juridiska krav. I en global marknad där produktansvar och miljöregler är avgörande. Juridiska frågor kring försäljning, produktansvar och internationella avtal kräver en partner som förstår både branschens tempo och de rättsliga riskerna som kan uppstå."
        },
        {
          title: "Mode",
          url: "/branscher/mode",
                 heading:"Mode",
          text:"Modebranschen drivs av ständig förändring och innovation. Att skydda sina designidéer och varumärken medan man navigerar i en global marknad kräver en säker juridisk grund. Med snabba trender och ökade krav på immateriella rättigheter är det avgörande att ha en juridisk partner som är van vid att hantera de komplexa frågorna kring internationell handel och kreativa rättigheter, så att du kan fokusera på att utveckla dina kollektioner och expandera."
        },
        {
          title: "Petrokemi",
          url: "/branscher/mode",
                 heading:"Petrokemi",
          text:"Petrokemiska företag hanterar en ständig balans mellan affärsstrategi och strikt efterlevnad av miljö- och säkerhetsregler. En bransch där regleringar är tunga och risken för rättsliga utmaningar hög, krävs rättslig expertis för att driva verksamheten framåt utan att tappa fokus på de dagliga affärerna. Att optimera processer och samtidigt hålla sig inom de givna ramarna är en utmaning – en utmaning som kräver en pålitlig partner för att skydda företagets intressen"
        },
      
    ],
  },
  omraden: {
    rightcol:{heading:"Affärsområden vi täcker", text:"Vi erbjuder rådgivning och lösningar inom en rad juridiska områden, anpassade för att möta de specifika behov och utmaningar som din verksamhet står inför. Med vår erfarenhet kan vi skapa de rättsliga förutsättningarna för att du ska kunna fokusera på det som är viktigast för dig."},

    show: true,
    title: "Områden",
    url: "/omraden",
    navlink: "omraden",
    children: [
      
        {
          title: "Arbetsrätt",
          url: "/omraden/arbetsratt",
          heading:"Arbetsrätt ",
          text:"Trygga och tydliga anställningsförhållanden är avgörande för både arbetsgivare och anställda. Vi ger juridisk rådgivning inom arbetsrätt, från anställningsavtal och arbetsmiljöfrågor till omstruktureringar och tvister. Med vårt stöd kan du hantera personalrelaterade frågor på ett rättssäkert och strategiskt sätt."
        },
        {
          title: "Avtalrätt",
          url: "/omraden/avtalsratt",
                heading:"Avtalsrätt",
          text:"Vi rådgiver företag och organisationer i alla aspekter av avtalsrätt – från att upprätta och förhandla avtal till att säkerställa efterlevnad och hantera tvister. Vi hjälper till att skydda era affärsintressen genom tydliga, juridiskt hållbara avtal anpassade efter verksamhetens behov."
        },
        {
          title: "Bolagsrätt",
          url: "/omraden/bolagsratt",
                 heading:"Bolagsrätt",
          text:"Oavsett om det gäller att starta, driva eller omstrukturera ett bolag, erbjuder vi juridisk expertis inom bolagsrätt. Vi bistår med allt från bolagsbildning och aktieägaravtal till företagsstyrning och regelefterlevnad, alltid med fokus på att skydda och främja verksamhetens intressen."
        },
        {
          title: "Dataskydd & GDPR",
          url: "/omraden/dataskydd",
                 heading:"Dataskydd",
          text:"Vi hjälper till med alla frågor kring dataskydd – från att säkerställa efterlevnad av GDPR till att implementera strategier för säker hantering av personuppgifter. Vi hjälper ert företag att minimera risker och skydda verksamheten genom effektiva och juridiskt hållbara lösningar."
        },
   
        {
          title: "Expansion",
          url: "/omraden/expansion",
                 heading:"Expansion",
          text:"När företaget växer och söker nya marknader, är rätt juridisk rådgivning avgörande för en framgångsrik expansion. Vi hjälper till att navigera genom alla juridiska aspekter av internationalisering, inklusive etablering på nya marknader, juridiska krav och strategiska partnerskap. Oavsett om du vill expandera inom Sverige eller internationellt, säkerställer vi att tillväxten  sker på en trygg och hållbar grund."
        },
        {
          title: "Företagsöverlåtelse",
          url: "/omraden/foretagsoverlatelse",
                 heading:"Företagsöverlåtelse",
          text:"En företagsöverlåtelse innebär både möjligheter och utmaningar. Vi bistår med juridisk expertis genom hela processen – från inledande förhandlingar och due diligence till avtalsskrivning och genomförande. Oavsett om du köper, säljer eller omstrukturerar ett bolag ser vi till att affären blir trygg, smidig och strategiskt fördelaktig."
        },
        {
          title: "Förhandlingsstöd",
          url: "/omraden/forhandlingsstod",
                 heading:"Förhandlingsstöd",
          text:"Vår expertis inom förhandlingar hjälper er att navigera i komplexa affärstransaktioner och uppnå fördelaktiga avtal. Vi arbetar tillsammans med er för att skapa en förhandlingsstrategi som maximerar era chanser att nå ett gynnsamt resultat och undvika framtida tvister. Med vårt stöd kan ni känna er trygga i att alla viktiga aspekter är täckta."
        },
        {
          title: "Fastighetsrätt",
          url: "/omraden/fastighetsratt",
                 heading:"Fastighetsrätt",
          text:"Fastighetsrätt är en komplex och viktig del av affärslivet. Vi erbjuder rådgivning inom alla områden av fastighetsrätt, från köp och försäljning av fastigheter till hyresavtal, arrende och fastighetsförvaltning. Med vår hjälp kan du tryggt navigera de rättsliga aspekterna och fatta informerade beslut som skyddar och stärker din fastighetsportfölj"
        },
        {
          title: "Immaterialrätt",
          url: "/omraden/immaterialratt",
                 heading:"Immaterialrätt",
          text:"Immateriella rättigheter är en värdefull tillgång för företag och entreprenörer. Vi erbjuder strategisk rådgivning kring skydd och hantering av varumärken, patent, design och upphovsrätt. Från registrering till licensiering och tvistlösning hjälper vi er att säkerställa att era innovationer och kreativa verk är rättsligt skyddade och används på bästa sätt."
        },
        {
          title: "Konkurrensrätt",
          url: "/omraden/konkurrensratt",
                 heading:"Immaterialrätt",
          text:"Att navigera inom konkurrensrätt kan vara komplext, men med rätt rådgivning kan ni säkerställa att er verksamhet följer lagar och regler på en konkurrensutsatt marknad. Vi hjälper till att identifiera och hantera risker kopplade till konkurrensbegränsande avtal, missbruk av dominerande ställning och andra konkurrensrättsliga frågor. Vårt mål är att ge er de verktyg som behövs för att optimera er marknadsposition utan att bryta mot konkurrenslagstiftningen"
        },
    ],  },
  fragor: {
    rightcol:{heading:"Vanliga frågor vi får", text:"Har du en juridisk fråga? Du är inte ensam! Här har vi samlat svar på några av de vanligaste frågorna vi får. Om du inte hittar svaret på din fråga, tveka inte att skicka den till oss. Vi återkommer inom en timme under arbetstid."},

    show: true,
    title: "Frågor",
    url: "/vanliga-fragor",
    navlink: "fragor",
    
  },
  
 

  blogg: {
    rightcol:{heading:"Bloggar - Lite mer ingående om juridiken", text:"Håller du koll på alla förändringar inom affärsjuridik? Våra blogginlägg ger dig möjlighet att snabbt få en överblick över aktuella ämnen och lagändringar som påverkar din verksamhet. Läs våra inlägg för att hålla dig uppdaterad och fördjupa din kunskap inom specifika områden."},

    
    show: true,
    title: "Blogg",
    url: "/blogg",
    navlink: "blogg",
  },
  omoss: {
    rightcol:{heading:"Alla har en historia, detta är vår historia", text:"Här får du en inblick i Lundberg & Associates, vårt arbetssätt och vår expertis inom affärsjuridik. Här presenterar vi vår bakgrund, våra värderingar och hur vi tillhandahåller juridiska lösningar för företag av alla storlekar. Vår målsättning är att säkerställa att din verksamhet får den rådgivning och det stöd som krävs för att navigera juridiska utmaningar på ett effektivt sätt."},

  
    show: true,
    title: "Om Oss",
    url: "/omoss",
    navlink: "omoss",
  },

  paket:{
    rightcol:{heading:"Våra priser", text:"Vi erbjuder tydlig och transparent prissättning för våra tjänster. På denna sida hittar du våra prispaket samt information om vår standardtaxa, så att du enkelt kan hitta det alternativ som passar dina behov. Om du har några frågor eller behöver hjälp med att välja rätt paket, är du välkommen att kontakta oss."},

    children: [
      
        {
          title: "Standard",
          url: "/priser",
          heading:"Standard - per ärende",
          text:"För företag som är i behov juridisk rådgivning vid enstaka tillfällen. Betala per timme och få tillgång till kvalificerad juridisk hjälp efter behov."
        },
        {
          title: "Lilla Start Paketet",
          url: "/priser",
                heading:"Lilla Start Paketet - 975kr/mån",
          text:"För mindre företag som önskar en flexibel juridisk lösning för att hantera avtal och enklare juridiska ärenden."
        },
        {
          title: "Start Paketet",
          url: "/priser",
                 heading:"Start Paketet - 1750kr/mån",
          text:"För företag som vill säkerställa kontinuerlig rådgivning och proaktivt hantera juridiska utmaningar."
        },
        {
          title: "Stora Start Paketet",
          url: "/priser",
                 heading:"Stora Start Paketet - 3750kr/mån ",
          text:"För växande företag med återkommande juridisk rådgivning. Med 24 timmar per år får du tillgång till juridisk expertis anpassad efter din verksamhets behov."
        },
        {
          title: "Enterprise",
          url: "/priser",
                 heading:"Enterprise - från 66h/år ",
          text:"Ett skräddarsytt juridiskt paket för större företag med komplexa behov. Från 66 timmar per år – en flexibel lösning som säkerställer kontinuerlig juridisk rådgivning och strategiskt stöd."
        },
       
      ],
    
    show: true,
    title: "Priser",
    url: "/priser",
    navlink: "paket",
  }
}

const MobileCol = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {

    const handleScroll = () => {
      // Check if user has scrolled
    
       
        if (window.scrollY === 0) {
          setHasScrolled(false); // User is at the top
        } else {
          setHasScrolled(true); // User has scrolled
        }
      
    };

    // Add event listener for scroll outside the function
    window.addEventListener("scroll", handleScroll);

    // Cleanup: remove event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };


  return (
    <>
    
    <div
      className="desktop-header-top-row"
      style={{
        display: "flex",
        flexDirection: "row",
        height: "45px",
        padding:5,
        backgroundColor: isOpen ? "rgb(26, 118, 194)":"transparent",
        justifyContent: "space-between",
        marginTop: 0,
        color:"rgb(255, 250, 245)",
      }}
    >
      <Link to="/">
        <img
        onClick={() => {
          setIsOpen(false)
    }}          src={logo}
          alt="our logo"
          height={"45px"}
          className={isOpen ? "header-logo-white":"header-logo"}
        />
      </Link>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 2,
          backgroundColor: "transparent",
          height: "100%",
        }}
      >
        <Link 
        onClick={() => {
          setIsOpen(false)
    }}
        className="nav-kontakt-mobile" to={props.sprak.kontakt.url}>
          {props.sprak.kontakt.btn}
        </Link>
        <div 
          style={{
            color: isOpen ? "white" : "#1a76c2", // Black only when scrolled & not open
          }}        
        className={`nav-hamburger ${isOpen ? "open" : ""}`} onClick={toggleDropdown}>
  <span></span>
  <span></span>
  <span></span>
</div>
      </div>
    </div>
    {isOpen && (
   <div className="nav-header-menu-mobile">
   {Object.values(nav).map((navItem, index) =>
     navItem.title && navItem.show ? (
       <div key={index} className="nav-header-accordion" style={{ width: "100%" }}>
         
         {/* Parent with children (Dropdown) */}
         {navItem.children ? (
           <div
             
             onClick={(e) => {
               e.preventDefault();
               toggleAccordion(index);

             }}
             className="nav-header-menu-item-mobile nav-header-menu-item-mobile-w-children"
           >
             <span>{navItem.title}</span>
             <span style={{ fontSize: 24, marginRight: 30 }}>
               {openIndex === index ? "↑" : "↓"}
             </span>
           </div>
         ) : (   
           // Parent without children (Regular Link)
           <Link
             to={`${navItem.url}/`}
             onClick={() => {
              setIsOpen(false)
        }}
             className="nav-header-menu-item-mobile"
             style={{
   
             }}
           >
             {navItem.title}
           </Link>
         )}

         {/* Dropdown Content - Children */}
         {openIndex === index && navItem.children && (
           <div style={{ paddingLeft: "20px" }}>
                    <Link
                   onClick={() => {
                    setIsOpen(false)
              }}
                 className="nav-header-menu-item-mobile-child"
                 to={`${navItem.url}/`}
               
               >
                 {navItem.title} Översikt
               </Link>
             {navItem.children.map((child, childIndex) => (
               <Link
                 key={childIndex}
                 onClick={() => {
                  setIsOpen(false)
            }}
                 className="nav-header-menu-item-mobile-child"
                 to={`${child.url}/`}
                
               >
                 {child.title}
               </Link>
             ))}
           </div>
         )}
       </div>
     ) : null
   )}
   <Link className="mobile-nav-button" to="/kontakt">Hör av dig!</Link>
 </div>
    )}
  </>
  );
};

const DesktopHeader = (props) => {

  const [hasScrolled, setHasScrolled] = useState(false);


  useEffect(() => {
    // Function to handle scroll event
  
    const handleScroll = () => {
      // Check if user has scrolled
     
       
        if (window.scrollY === 0) {
          setHasScrolled(false); // User is at the top
        } else {
          setHasScrolled(true); // User has scrolled
        }
      
    };

    // Add event listener for scroll outside the function
    window.addEventListener("scroll", handleScroll);

    // Cleanup: remove event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures that effect runs only once on mount

  const [whichMenu, setWhichMenu] = useState("");
  const [showDropdownText, setShowDropdownText] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 1200 });
  const [hoveredItem, setHoveredItem] = useState(null); // Track hovered item

  const [navHovering, setNavHovering] = useState(false);
  return (
    <>
    <GoogleReviewBanner hasScrolled={hasScrolled}/>

    <div
      className="desktop-header"
      style={{
        display: "flex",
        zIndex: "200",
        marginTop:hasScrolled? 0:30,
        flexDirection: "column",
        width: "100%",
        position: "fixed",
        transition:"0.1s",
        top:0,
        backgroundColor: hasScrolled || navHovering? "hsl(31.2, 100%, 98%)" : "transparent", //boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Här kan du justera skuggans egenskaper
      }}
    >
    
      {isMobile ? (
        <MobileCol
          light={props.light}
          sprak={props.sprak}
          setShowDropdownText={setShowDropdownText}
          showDropdownText={showDropdownText}
          onEnter={setShowDropdownText}
        />
      ) : (
        <>
        
          <div
            className="desktop-header-top-row"
            style={{
              display: "flex",
              flexDirection: "row",
              height: "75px",
              backgroundColor: hasScrolled ? "hsl(31.2, 100%, 98%)" : "transparent",
              justifyContent: "center",
              borderTop: `1px solid ${
                hasScrolled ? colors.tint : "transparent"
              }`,
              borderBottom: `1px solid ${
                hasScrolled ? colors.tint : "transparent"
              }`,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                maxWidth: "1440px",
                height: "100%",
                width: "70%",
                backgroundColor: "transparent",
                alignSelf: "center",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Link
              
              to="/">
                <img
                  src={logo}
                  alt="our logo"
                  height={"75px"}
                  className="header-logo"
                />
              </Link>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "transparent",

                  height: "100%",
                }}
              >
                <Btn
      
                  setNavHovering={setNavHovering}
                  navigate={props.sprak.kontakt.url}
                  btnText={props.sprak.kontakt.btn}
                />
              </div>
            </div>
          </div>

          <div className={`header-bottom-row ${hasScrolled ||navHovering? 'header-scrolled' : ''}`}>
      <div
        className={`header-nav-container ${navHovering ?'expanded' : ''}`}
        style={{backgroundColor:props.light? "#1a76c2":""}}
        onMouseEnter={() => {
          setNavHovering(true);
          if (!showDropdownText) {
            setShowDropdownText("tjanster"); // Default to "Tjänster"
          }
        }}
        onMouseLeave={() => {
          setNavHovering(false);
          setShowDropdownText(""); // Clear on leave
        }}
      >
        <div className="header-inner-row">
                   {Object.values(nav).map(
                (navItem, index) =>
                  navItem.title &&
                  navItem.show && (
                    <NavBtn
                      key={index}
                      setIsHovering={setNavHovering}
                      setShowDropdownText={setShowDropdownText}
                      showDropdownText={showDropdownText}
                      onEnter={setShowDropdownText}
                      navlink={navItem.navlink}
                      title={navItem.title}
                      url={navItem.url}
                    />
                  )
              )}
              </div>
<div className={`header-dropdown ${navHovering ? 'shown' : ''}`} >
{showDropdownText &&
        <div className="header-dropdown-container" >
    
          <div className="header-dropdown-left-container">
            
            <Link 
            onClick={() => {
              setNavHovering(false); // Or whatever state you want to update
            }}
            className="dropdown-link" to={nav[showDropdownText].url}><b>{nav[showDropdownText].title}</b></Link>
            {nav[showDropdownText].children && (
  <>
    {nav[showDropdownText].children.map((navItem, subIndex) => (
      
        <Link 
          className="dropdown-link"
          onClick={() => {
            setNavHovering(false); // Or whatever state you want to update
          }}
          onMouseEnter={() => setHoveredItem(navItem)} 
          onMouseLeave={() => setHoveredItem(null)}
          to={navItem.url}
          key={{subIndex}} // Corrected key usage
        >
          {navItem.title} 
        </Link>
      )
    )}
    </>)}


          </div>
          <div className="header-dropdown-right-container">
        {hoveredItem ? (
          <>
            <h3>{hoveredItem.heading}</h3>
            <p>{hoveredItem.text}</p>
          </>
        ) : (
          <>
            <h3>{nav[showDropdownText].rightcol.heading}</h3>
            <p>{nav[showDropdownText].rightcol.text}</p>
          </>
        )}
      </div>
        </div>}
        </div>
            </div>
          </div>
        </>
      )}
    </div></>
  );
};

export default DesktopHeader;
