import DesktopComponent from "../../main";
import { Link, useParams } from 'react-router-dom';
import React,{ useEffect, useState } from "react";
import { canonUrl, findSlugPage } from "../../../static/urls";
import CanonicalTag from "../../components/canonicalTag";
import Form from "../../components/generalwidgets/form";
import heroImg from "../../../static/img/hero.webp";

import "../bolaget/css/avtal.css";


import "../../components/generalwidgets/css/oneline.css"
import "../../../static/css/hero1.css";
import "../screencomponents/widget/css/heroimg.css";
import HomeKunder from "../screencomponents/homeKunder";

import {
  
  secondaryMediumSmall,mainSmall
} from "../../../static/fonts";
import "../../../static/css/colors.css";
import "../screencomponents/css/hero.css";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { sendform } from "../../../static/urls";
import { FormControl, FormHelperText,  } from "@mui/material";
import { setMeta } from "../screencomponents/widget/metadesc";
import "./css/blog.css"
import Modal from "../screencomponents/widget/modal";
import { useMediaQuery } from "react-responsive";
import Benefits from "../screencomponents/widget/benefits";
import avtalimg from "../../../static/newimgs/avtalsratt.webp";

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;}

  let form =  {
    title: "Vi hjälper ditt företag – Hör av dig",
    text: "Lämna gärna dina kontaktuppgifter så kontaktar vi dig inom en timme för att diskutera din situation och dina juridiska behov. Den första konsultationen är helt gratis och du är inte bunden att anlita oss. Vi är även nästan alltid tillgängliga för ett samtal om du inte vill vänta.",
    name: "Namn",
    newsletter:"Intresserad av att hålla dig uppdaterad om vad som händer i juridiken?",
    telefon: "Telefonnummer",
    email: "Email",
    number:"Telefonnummer",
    body: "Vill du lägga till något?",
    termstext: "Jag har läst och godkänner integritetspolicyn. ",
    url: "",
    termsLink: "Läs den här",
  }
const avtal = {
  CTAs: {
    first_cta_url: "123",
    first_cta: "Beställ",
    second_cta: "Hör av dig!",
    second_cta_url: "asd",
  },
  tjanst:{text:"Första mötet är gratis och kommer inte med ett köpkrav. Priset är ex. moms."},
  neutral_hero:{right_title:"Hör av dig!",right_text:"Har du ett juridisk problem eller fundering? Hör av dig så hjälper vi dig med företagsjuridiken! Första konsultationen är kostnadsfri!",pris:""},
  modal:{
    title:"Hur kan vi hjälpa dig?",
    text:"Fyll i dina detaljer så återkommer oftast inom en timme!",

  },
  hur: {
    steg1: {
      title: "1. Boka möte",
      text: "Innan vi börjar har vi ett introduktionsmöte där vi går igenom eventuella frågor och hur du vill att slutprodukten ser ut.",
      img: "",
    },
    steg2: {
      title: "2. Beställ",
      text: "När mötet är avslutat skickar vi över ett uppdragsavtal, om du väljer att beställa tjänsten börjar vi jobba när vi fått din signatur.",
      img: "",
    },
    steg3: {
      title: "3. Utför",
      text: "Vi utför jobbet du beställt.",
      img: "",
    },
    steg4: {
      title: "4. Leverans",
      text: "När vi är färdiga levererar vi produkten och tar ett kort möte och går igenom leveransen, om du vill.",
      img: "",
    },
  },
};

const Herov2 = (props) => {
  if (!props.data) {
    return null; // or some loading spinner
  }
  return (
    <div
      className="blog-herov2-container"
      style={{
        backgroundImage: `url(${props.data.heroimg ? "https://backend.lundbergandassociates.com" + props.data.heroimg : avtalimg})`
   
      , // Replace `heroimg` with the correct key for your image URL from the backend
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="blog-herov2-left">
        <h1 className="blog-main-herov2-h1">
          {props.data.herotitle}{" "}
          <span className="accent-text">{props.titleaccent}</span>{" "}
          {props.titlelast}
        </h1>
        <p className="blog-main-herov2-p">{props.data.herotext}</p>
        {props.data.tjansteargument && (
          <ul className="blog-custom-list">
            {props.data.tjansteargument.map((arg) => (
              <li key={arg.id}>{arg.text}</li>
            ))}
          </ul>
        )}
        <br></br>
        {props.data.readMoreUrl && (
          <Link to={props.data.readMoreUrl} className="blog-readmore-button">
            Läs mer om {props.data.herotitle}
          </Link>
        )}
      </div>
    </div>
  );
};
function Blog(props) {
  const csrftoken = getCookie("csrftoken");
     

    
    const { blogId } = useParams();
    const [data, setData] = useState({});
    const canonicalUrl = canonUrl+ props.sprak.blogLista.url + blogId
    let pageurl = findSlugPage+props.sprak.blogLista.fetchUrlAddon+ blogId;
    const [lastHeadingInView, setLastHeadingInView] = useState(0); // Initialize with 0
    const [formData, setFormData] = useState({
      body: "",
      page:"",
      email: "",
      name:"",
      number:"",
      
      newsletter: false,
      terms: false,
      
    });
    const [errorData, setErrorData] = useState({
      body: false,
      email: false,
      terms: false,
      number:false,
      name: false,
    });





  

    useEffect(() => {
      const fetchDataFromAPI = async () => {
        try {
          const response = await fetch(pageurl);
          const data = await response.json();
          console.log(data)
          setData(data);
  
          setMeta(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchDataFromAPI();
      const headings = document.querySelectorAll("[data-heading-index]"); 
    
      if (!headings.length) return;
    
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const index = parseInt(entry.target.getAttribute("data-heading-index"), 10);
              setLastHeadingInView(index);
            }
          });
        },
        { threshold: 0.5 } // Adjust this value for sensitivity
      );
    
      headings.forEach((heading) => observer.observe(heading));
    
      return () => {
        headings.forEach((heading) => observer.unobserve(heading));
      };
    }, [data,pageurl]);
    

  const handleSubmit = (e) => {


    e.preventDefault(); // Prevent default form submission behavior
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('event', 'conversion_event_submit_lead_form', {
    })
    let hasError = false;
    if (formData.terms === false) {
      setErrorData((prevState) => ({ ...prevState, terms: true }));
      hasError = true;
    }

    if (formData.number === "") {
      setErrorData((prevState) => ({ ...prevState, number: true }));
      hasError = true;
    }
    if (formData.name === "") {
      setErrorData((prevState) => ({ ...prevState, name: true }));
      hasError = true;
    }
    if (formData.email === "") {
      setErrorData((prevState) => ({ ...prevState, email: true }));
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },

      body: JSON.stringify(formData),
    };

     
    
        fetch(sendform, requestOptions)
            .then((response) => {
                if (response.ok) {
                    window.location.href = props.sprak.tack.url;
                } else {
                    console.error('Form submission failed');
                }
                return response.json();
            })
            .then((data) => console.log(data))
            .catch(error => console.error('Error submitting form:', error));
        
            
  };

    // Render content
    const renderContent = () => {
      const { body } = data;
      if (!body) return <div style={{ height: "100vh" }}></div>; // Render nothing if body is not available
    
      let headingIndex = 0;
    
      const incrementHeadingIndex = () => {
        headingIndex++;
        return null;
      };
    
      return (
        <div style={{ width: "90%", maxWidth: "800px", margin: "auto", textAlign: "left" }}>
          {body.map((item, index) => (
            <div key={index}>
              {item.type === "heading" ? (
                <>
                  <h2 id={`heading-${headingIndex}`} data-heading-index={headingIndex}>
                    {item.value}
                  </h2>
                  {incrementHeadingIndex()} 
                </>
              ) : (
                <p dangerouslySetInnerHTML={{ __html: item.value }} />
              )}
            </div>
          ))}
        </div>
      );
    };
    
    
    // Render the table of contents (TOC)
    const renderTOC = () => {
      const { body } = data;
      if (!body) return null;
    
      const headings = body.filter((item) => item.type === "heading").map((item) => item.value);
      return (
        <>
        <div className="blog-toc-item">
          <ul>
            {headings.map((heading, index) => (
              <li key={index}>
                <a
                  href={`#heading-${index}`}
                  style={{ fontWeight: lastHeadingInView === index ? "bold" : "normal" }} 
                  >
                  &darr; {heading}
                </a>
              </li>
            ))}
          </ul>
          <br></br>

          <div className="blog-nav-card" >
        <img
        src={heroImg}
        className="avtal-hero-main-img"
        alt={"Bild på grundare"}
      />
      <p>Har du en fråga du behöver hjälp med? Hör av dig så svarar vi så snabbt vi kan!</p>
       <button onClick={props.openModal} className='avtal-cta-button'>
        Hör av dig
      </button>
</div>
        </div>
        
            </>
      );
    };


    const Hero =(props)=>{
      if (!props.data) {
        return null; // or some loading spinner
      }

      return(
        <div
        className="avtal-herov2-container"
        style={{
          backgroundImage: `url(${props.data.heroimg ? "https://backend.lundbergandassociates.com" + props.data.heroimg : avtalimg})`,
          marginTop:"0px"
        , // Replace `heroimg` with the correct key for your image URL from the backend
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >      <div className="avtal-hero-left">
        <h1 className="" style={{textAlign:"center",color:"white",  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}>
          {props.data.title} 
        </h1>
        {props.data.heroText&&
        <p className="avtal-main-hero-p" style={{textColor:"white",textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)"}}>
          
          {props.data.heroText}
        </p>
    }
 <br></br>
        </div>

      <div className="avtal-hero-right blog-buy-now-card-header">
        <div className="avtal-buy-now-card" style={{marginBottom:-100}}>
        <img
        src={heroImg}
        className="avtal-hero-main-img"
        alt={"Bild på grundare"}
      />
    
{props.data.right_title ? (
  <>
    
          <p className="avtal-pris-p" style={{textAlign:"center"}}><b>{props.data.right_title}</b></p>
          
          {props.data.right_pris &&

          <p className="avtal-pris">{props.data.right_pris} kr</p>
          }
          <p className="avtal-pris-p">{props.right_text}</p>
          </>
        ):(
          <>
          <p className="avtal-pris-p" style={{textAlign:"center"}}><b>{avtal.neutral_hero.right_title}</b></p>
 
        
          <p className="avtal-pris-p">{avtal.neutral_hero.right_text}</p>
          </>
        )}
          <br></br><Link style={{textDecoration:"none",color:"black"}} to={props.sprak.nav.omoss.url}>{props.sprak.kundersbetyg}  &rarr;</Link>
          <span style={{color:"gold"}}>★★★★★</span>
          <br></br>
          <div className="avtal-button-container">
            
          <button onClick={props.openModal} className='avtal-cta-button'>
        Hör av dig
      </button>

    
    
    
    </div>
    
    
        </div>
      </div>
    
    
     
    </div>
    
      )
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => {
      setIsModalOpen(false);
    };

    const isSmallScreen = useMediaQuery({ maxWidth: 600 });

    const openModal = () => {
      setFormData({...formData,page: data.title}) 

      setIsModalOpen(true);
    };
 

    return (
        <DesktopComponent sprak={props.sprak} light={false}>
                  <CanonicalTag canonicalUrl={canonicalUrl} />
   
                  <Hero data={data}  sprak={props.sprak} openModal={openModal}/>


                  <Modal isOpen={isModalOpen} onClose={closeModal}>

<div
style={{padding:10}}
>
<h4
style={{
  ...secondaryMediumSmall,
  margin: " 0 0 5px 0",
  padding: 0,
  textAlign: "left",
}}
>
{avtal.modal.title}
</h4>
{!props.notext &&
<>
<p style={{ ...mainSmall, textAlign: "left", margin: 0, padding: 0, widt:"90%" }}>
{avtal.modal.text}
<br></br>
<br></br>
</p>


<div style={{ marginLeft: "-50px" }}>
</div>
</>
}
<div
style={{ padding: "0 20px", display: "flex", flexDirection: "column" }}
>


<form onSubmit={handleSubmit} noValidate style={{ padding: "0 20px", display: "flex", flexDirection: "column"}}>

<TextField
  style={{ flex: "1", marginRight: isSmallScreen ? 40 : "unset" }}
  error={errorData.name}
  helperText={errorData.name ? "Skriv in ditt namn." : ""}
  id="standard-basic"
  label={form.name}
  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
  variant="standard"
/>

<br></br>

<TextField
  style={{ flex: "1", marginRight: isSmallScreen ? 40 : "unset" }}
  
  error={errorData.email}
    helperText={errorData.email ? "Fyll i din mail." : ""}
    
  id="standard-basic"
  label={form.email}
  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
  variant="standard"
/>
<br></br>
<TextField
  style={{ flex: "1", marginRight: isSmallScreen ? 40 : "unset" }}
  
  error={errorData.number}
    helperText={errorData.number ? "Fyll i ditt telefonnummer." : ""}
    
  id="standard-basic"
  label={form.number}
  onChange={(e) => setFormData({ ...formData, number: e.target.value })}
  variant="standard"
/>
<br></br>

<TextField
  style={{ flex: "1", marginRight: isSmallScreen ? 40 : "unset" }}
  id="standard-basic"
  onChange={(e) => setFormData({ ...formData, body: e.target.value })}
  label={form.body}

  error={errorData.body}
  helperText={errorData.body ? "Skriv ett meddelande." : ""}

  multiline
  maxRows={4}
  variant="standard"
/>

<br></br>

    
<FormControl
required
error={errorData.terms}   
     helperText={errorData.terms ? "You must agree to terms." : ""}

component="fieldset"
sx={{ m: 0 }}
variant="standard"
>

<FormGroup>
 
  <FormControlLabel
    control={
      <Checkbox

        checked={formData.terms}
        onChange={(e) =>
          setFormData({ ...formData, terms: e.target.checked })
        }
      />
    }
    label={
      <div style={{ textAlign: "left" }}>
        <Typography>
          {form.termstext}
          <a href={props.sprak.privacy.url}>
            {form.termsLink}
          </a>
        </Typography>
      </div>
    }
  />
</FormGroup>
<FormHelperText>
    
{errorData.terms && <>
Integritetspolicyn måste godkännas
</>
}
</FormHelperText>
</FormControl>

{isSmallScreen && (
  <>
    <br></br>
    <br></br>
  </>
)}

           
<FormControl


component="fieldset"
sx={{ m: 0}}
variant="standard"
>

<FormGroup>

  <FormControlLabel
    control={
      <Checkbox
         checked={formData.newsletter}
        onChange={(e) =>
          setFormData({ ...formData, newsletter: e.target.checked })
        }
      />
    }
    label={
      <div style={{ textAlign: "left" }}>
        <Typography>{form.newsletter}</Typography>
      </div>
    }
  />
</FormGroup>
</FormControl>

{isSmallScreen && (
  <>
    <br></br>
    <br></br>
  </>
)}
<Button
  variant="outlined"
  onClick={handleSubmit}
  endIcon={<ArrowForwardIcon />}
  style={{
    width: "100px",
    alignSelf: "end",
    color: "black",
    borderColor: "black",
    marginRight: "20px",
    marginBottom: "20px",
  }}
>
  {props.sprak.skicka}
</Button>
</form>
</div>

</div>
</Modal>
{data.selling_in_nature ? (
            <div className="blog-container-col" style={{width:"100%", maxWidth:"100%",flexDirection:"colum"}}>
      <div className="blog-content">
      <div style={{ width: "90%", maxWidth: "800px", margin: "auto", marginTop:"50px", marginBottom:"30px",textAlign: "left" }}>
      <p className="avtal-main-hero-p" dangerouslySetInnerHTML={{ __html: data.overview }} />
      <div className="blog-buy-now-card-body">
        <div className="avtal-buy-now-card blog-header-card-margin" >
        <img
        src={heroImg}
        className="avtal-hero-main-img"
        alt={"Bild på grundare"}
      />
    
{props.data.right_title ? (
  <>
    
          <p className="avtal-pris-p" style={{textAlign:"center"}}><b>{props.data.right_title}</b></p>
          
          {props.data.right_pris &&

          <p className="avtal-pris">{props.data.right_pris} kr</p>
          }
          <p className="avtal-pris-p">{props.right_text}</p>
          </>
        ):(
          <>
          <p className="avtal-pris-p" style={{textAlign:"center"}}><b>{avtal.neutral_hero.right_title}</b></p>
 
        
          <p className="avtal-pris-p">{avtal.neutral_hero.right_text}</p>
          </>
        )}
          <br></br><Link style={{textDecoration:"none",color:"black"}} to={props.sprak.nav.omoss.url}>{props.sprak.kundersbetyg}  &rarr;</Link>
          <span style={{color:"gold"}}>★★★★★</span>
          <br></br>
          <div className="avtal-button-container">
            
          <button onClick={props.openModal} className='avtal-cta-button'>
        Hör av dig
      </button>

    
    
    
    </div>
    
    
        </div>
      </div>
      </div>
      </div>

      
      <> 
      {data.trust ?
(
        <HomeKunder trust={data.trust}/>)

        :(
          <HomeKunder />

        )
      }
      </>
      
            <div className="blog-content">

      {renderContent()}
        <br></br>
        <br></br>
        <br></br>
        <Form sprak={props.sprak} />
        <br></br>
        <br></br>

      </div>
      
    </div>      )
    :
    (
      <div className="blog-container">
      <div className="blog-toc">
        {renderTOC()}
      </div>
      
      <div className="blog-content">
      <div style={{ width: "90%", maxWidth: "800px", margin: "auto", marginTop:"50px", marginBottom:"30px",textAlign: "left" }}>
      <p className="avtal-main-hero-p" dangerouslySetInnerHTML={{ __html: data.overview }} />
      <div className=" blog-buy-now-card-body">
        <div className="avtal-buy-now-card" >
        <img
        src={heroImg}
        className="avtal-hero-main-img"
        alt={"Bild på grundare"}
      />
    
{props.right_title ? (
  <>
    
          <p className="avtal-pris-p" style={{textAlign:"center"}}><b>{props.data.right_title}</b></p>
          
          {props.data.right_pris &&

          <p className="avtal-pris">{props.data.right_pris} kr</p>
          }
          <p className="avtal-pris-p">{props.right_text}</p>
          </>
        ):(
          <>
          <p className="avtal-pris-p" style={{textAlign:"center"}}><b>{avtal.neutral_hero.right_title}</b></p>
 
        
          <p className="avtal-pris-p">{avtal.neutral_hero.right_text}</p>
          </>
        )}
          <br></br><Link style={{textDecoration:"none",color:"black"}} to={props.sprak.nav.omoss.url}>{props.sprak.kundersbetyg}  &rarr;</Link>
          <span style={{color:"gold"}}>★★★★★</span>
          <br></br>
          <div className="avtal-button-container">
            
          <button onClick={props.openModal} className='avtal-cta-button'>
        Hör av dig
      </button>

      <br></br>
      <br></br>
      <br></br>
    
    
    
    </div>
    
    
        </div>
      </div>
      </div>
        {renderContent()}
        <br></br>
        <br></br>
        <br></br>
        <Form sprak={props.sprak} />
        <br></br>
        <br></br>

      </div>
      
    </div>   
    )}       

        </DesktopComponent>
    );
}

export default Blog;
