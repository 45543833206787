import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import "./css/imagecard.css"; // Import the CSS file

const ImageCard = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const [hover, setHover] = useState(false);

  return (
    <>
      {isMobile ? (
        <Link
          to={props.to}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className="gallery-imagecard-mobile-container"
        >
          <img
            src={props.img}
            loading="lazy"
            className="gallery-imagecard-mobile-img"
            alt="Automotive"
          />
          <p className="gallery-imagecard-mobile-text">
            {props.title}
            <br /> &rarr;
          </p>
        </Link>
      ) : (
        <Link
          to={props.to}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className="gallery-imagecard-container"
        >
          <img
            src={props.img}
            loading="lazy"
            className={`gallery-imagecard-img ${hover ? "hover" : ""}`}
            alt="Automotive"
          />
          <div className="gallery-imagecard-text">
            <p>{props.title}</p>
            <p className="gallery-imagecard-arrow">&rarr;</p>
          </div>
        </Link>
      )}
    </>
  );
};

const Gallery = (props) => {
  return (
    <div className="gallery-imagecard-wrapper">
      {Object.values(props.sprak.branscher).map((item) => (
        <ImageCard
          img={item.img}
          key={item.id}
          title={item.title}
          to={item.to}
        />
      ))}
    </div>
  );
};

export default Gallery;
