import DesktopComponent from "../../main";

import Omraden2 from "../screencomponents/omradelista/omraden2";
import ThirdHero from "../screencomponents/widget/heroes/thirdhero";
import Affaren from "../screencomponents/affaren";
import { useEffect } from "react";
import CanonicalTag from "../../components/canonicalTag";
import { canonUrl } from "../../../static/urls";
import Omraden3 from "../screencomponents/omradelista/omraden3";
import omraden from "../../../static/newimgs/omraden.webp"

import ImageHero from "../screencomponents/widget/heroes/imagehero";
import { setMeta } from "../screencomponents/widget/metadesc";
let cta ={
  first_cta: "Ring Direkt",
first_cta_url
: 
"tel:+46703557548",
second_cta
: 
"Fyll i formulär",
second_cta_url
: 
"/kontakt"

}
function OmradesLista(props) {
  const canonicalUrl = canonUrl+ props.sprak.omradesLista.url  +"/"

  useEffect(() => {
    window.scrollTo(0, 0);

    let data1={
      meta:{
        seo_title:props.sprak.omradesLista.pagetitle,
        search_description:props.sprak.omradesLista.pagedesc
      }
    }
    // Set meta description
    setMeta(data1)
       

}, []);


//cta={data.CTAs} 
//   <ThirdHero title={props.sprak.omradesLista.title} sprak={props.sprak} text={props.sprak.omradesLista.titleText}/>

  return (
    <DesktopComponent sprak={props.sprak}>
                                    <CanonicalTag canonicalUrl={canonicalUrl} />

                                    
        <ImageHero title={props.sprak.omradesLista.title}  
h2={"Bred kompetens inom flera juridiska områden"}  text={" Oavsett om det handlar om avtalsrätt, företagsöverlåtelse eller bolagsrätt erbjuder vi djupgående kunskap och praktisk rådgivning."}          cta={cta}   img={omraden}  sprak={props.sprak}/>


     


           

<Omraden2 sprak={props.sprak} list={Object.values(props.sprak.falt)} />

<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
       
    </DesktopComponent>
  );
}

export default OmradesLista;
