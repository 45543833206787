import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import './css/omraden2.css'; // Import the CSS file

const Omrade = (props) => {
  const isMobile = useMediaQuery({ maxWidth: '1100px' });
  const size = '55px';

  return (
    <Link to={props.url} className="omrade-link">
      <div className="omrade-container">
          {!isMobile && props.img&& (
        <div className="omrade-icon-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="omrade-icon"
              style={{ width: size, height: size }}
            >
              {props.img}
            </svg>
        </div>
          )}
        <div className="omrade-content">
          <h3 className="omrade-title">{props.title}</h3>
          <p className="omrade-text">{props.text}</p>
        </div>
        <p className="omrade-arrow">&rarr;</p>
      </div>
    </Link>
  );
};

const Omraden2 = (props) => {
  return (
    <div className="omraden2-wrapper">
       <br></br>
       <br></br>
      <div className="omraden2-grid">
        {props.list.map((item) => (
          <Omrade img={item.img} url={item.url} key={item.id} title={item.title} text={item.text} />
        ))}
      </div>
    </div>
  );
};

export default Omraden2;
