import React, { useState, useEffect } from 'react';
import { canonUrl, cookiesUrl, headers } from "../../../static/urls";
import DesktopComponent from "../../main";
import { Link } from 'react-router-dom';
import { colors } from '../../../static/colors';
import { secondaryMedium, secondarySmall } from '../../../static/fonts';
import CanonicalTag from '../../components/canonicalTag';

function NotFound(props) {
  const canonicalUrl=canonUrl +props.sprak.F404.url
  // Inline styles
  const containerStyle = {
    maxWidth: '1440px',
    paddingTop: '150px',
    paddingBottom: '150px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width:"90%",
    minHeight: "55vh",
    textAlign: 'center', // Aligns content to the center for the entire container
  };
  
  return (
    <DesktopComponent sprak={props.sprak} light={true}>
                  <CanonicalTag canonicalUrl={canonicalUrl} />

      <div style={containerStyle}>
        <h1>Nämen! 404?  </h1>
        <h2 style={{margin:0}}>Sidan hittas inte.</h2>
        <p style={{textAlign:"left"}}>Vi ber om ursäkt för besväret, vi har antingen flyttat eller tagit bort det du söker.</p>
        <br></br>
        
        <p style={{textAlign:"left",...secondarySmall} }> Låt oss försöka hjälpa dig hitta det du söker.</p>
        <br></br>
        <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" , textAlign:"left", gap:15}}>
          <div style={{ padding: 20, width: "450px",borderRadius:5, backgroundColor:colors.lightAccent }}>
            <h2 style={{...secondaryMedium, marginTop:0}}>Letar du efter något med en titel likt:</h2>
            <p> - Hur skriver jag avtal?</p>
            <p> - Vad är en aktiebok?</p>
            <p> - Hur säljer jag mitt företag?</p>
            <p>Kolla bland våra <Link to="/vanliga-fragor/">vanliga frågor</Link>.</p>
          </div>
          <div style={{ padding: 20, width: "450px" ,borderRadius:5, backgroundColor:colors.lightAccent}}>
            <h2 style={{...secondaryMedium, marginTop:0}}>Letar du efter något med en titel likt:</h2>
            <p> - Checklista för att skriva avtal</p>
            <p> - Ångerrätt för konsumenter</p>
            <p> - Guide till GDPR</p>
            <p>Kolla bland våra <Link to="/blogg/">bloggar</Link>.</p>
          </div>
        </div>
      </div>
    </DesktopComponent>
  
    );
  }
export default NotFound;
