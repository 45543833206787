import React, { useState, useEffect } from 'react';
import "./css/cookiebanner.css"



const CheckBoxCookies = ({ text, title, set, bool, disabled }) => {
  return (
    <div className="cookie-checkbox-container">
      <input
        disabled={disabled}
        className='cookie-banner-margin'
        type="checkbox"
        checked={bool}
        onChange={() => set(!bool)} 
      />
      <div className="cookie-checkbox-content">
        <h4 className='cookie-banner-left'>{title}</h4>
        <p className='cookie-banner-left'>{text}</p>
      </div>
    </div>
  );
};


const CookieBanner = (props) => {
  const [acceptedCookies, setAcceptedCookies] = useState(false);
  const [showAcceptSome , setShowAcceptSome] = useState(false)
  const [checkboxCheckedAnalyics, setCheckboxCheckedAnalytics] = useState(false);
  const [checkboxCheckedNecessary, setCheckboxCheckedNecessary] = useState(false);
  const [checkboxCheckedMarketing, setCheckboxCheckedMarketing] = useState(false);
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  useEffect(() => {
    gtag(
      'consent','default',{
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'denied',
      'functionality_storage':'denied',
      'personalization_storage':'denied',
      'security_storage':'granted',
    });
    const masterCookie = getCookie("cookiesAccepted");
    if (masterCookie) {

      setAcceptedCookies(true);
      
   
          // Parse the masterCookie to extract user preferences
          const parsedPreferences = JSON.parse(masterCookie);
   
          gtag('consent', 'update', {            ...parsedPreferences,
          })
    }

    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TP7527GG')
  

    }, []);
  

  const getCookie = (name) => {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);


    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return "";
  };

  const createCookie = (name, value, days) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `; expires=${date.toUTCString()}`;
    }
    
    // Convert the value (object) to a JSON string
    const valueString = JSON.stringify(value);
    document.cookie = `${name}=${valueString || ''}${expires}; path=/`;
  };
  
  // Usage Example:
 
  //Set a cookie that indicates that they have seen the banner 
  const createMasterCookie = (ads, analytics) => {
    const a = analytics ? "granted" : "denied";
    const b = ads ? "granted" : "denied";
    const userPreferences = {
      'ad_storage': b,
      'ad_user_data': b,
      'ad_personalization': b,
      'analytics_storage': a,
      'functionality_storage':b,
      'personalization_storage':b,
      'security_storage':'granted',
    };
    
    createCookie('cookiesAccepted', userPreferences, 1 * 365);
    setAcceptedCookies(true);
  };

  //set all other cookies and then createmastercookie
  const acceptCookies = (selection) => {
    if (selection === 'acceptNecessary') {
      createMasterCookie(false,false);

      gtag('consent', 'update', {   
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'analytics_storage': 'denied',
        'functionality_storage':'denied',
        'personalization_storage':'denied',
        'security_storage':'granted',
      });

    } else if (selection === 'acceptChosen') {
      createMasterCookie(checkboxCheckedMarketing,checkboxCheckedAnalyics);
      const a = checkboxCheckedAnalyics ? "granted" : "denied";
      const b = checkboxCheckedMarketing ? "granted" : "denied";
      gtag('consent', 'update', {   
        'ad_storage': b,
        'ad_user_data': b,
        'ad_personalization': b,
        'analytics_storage': a,
        'functionality_storage':b,
        'personalization_storage':b,
        'security_storage':'granted',
      });
  
      if (checkboxCheckedMarketing) {
        // Load Hotjar script if marketing checkbox is checked
        loadHotjarScript();
      }

    } else if (selection === 'acceptAll') {
      createMasterCookie(true,true);
      // Load both Hotjar and Google Analytics scripts
      loadHotjarScript();
     // Push preferences to the dataLayer

     gtag('consent', 'update', {
        'ad_storage': 'granted',
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'analytics_storage': 'granted',
      'functionality_storage':'granted',
      'personalization_storage':'granted',
      'security_storage':'granted',
        
      });
      
    }
  };
  const loadGoogleTagManagerBodyScript =()=>{
    

<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TP7527GG"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  }
  
  const loadHotjarScript = () => {
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:2890668,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  };
  

  if (acceptedCookies) {
    return null; // If cookies are accepted, don't render the banner
  }

  return (

<div className="cookie-modal" id="modal" tabIndex="-1" aria-labelledby="modalLabel" aria-hidden="true">
      <div className="cookie-modal-dialog">
        <div className="cookie-modal-content">
          <div className="cookie-modal-header">
            <h3 className="cookie-modal-title" id="modalLabel">{props.sprak.cookiebanner.title}</h3>
          </div>
          {loadGoogleTagManagerBodyScript()}
          <div className="cookie-modal-body">
          {!showAcceptSome ?  (  <p className='cookie-banner-left'>{props.sprak.cookiebanner.text1}</p>
          ):(
            <><p className='cookie-banner-left'>{props.sprak.cookiebanner.text2}</p>
            <CheckBoxCookies text={props.sprak.cookiebanner.analyticsText} title={props.sprak.cookiebanner.analyticsTitle}     set={setCheckboxCheckedAnalytics} 
      bool={checkboxCheckedAnalyics} disabled={false}/> 
            <CheckBoxCookies text={props.sprak.cookiebanner.advertisementText} title={props.sprak.cookiebanner.advertisementTitle} set={setCheckboxCheckedMarketing} bool={checkboxCheckedMarketing} disabled={false}/> 
            <CheckBoxCookies text={props.sprak.cookiebanner.necessaryText} title={props.sprak.cookiebanner.necessaryTitle} set={()=>setCheckboxCheckedNecessary()} bool={true} disabled={true}/> 

          </>
          )}
</div>
          <div className="cookie-modal-footer">
            {!showAcceptSome ? 
            (
              <>
            <div className="button-spacing">
              <button className="button" onClick={() => setShowAcceptSome(!showAcceptSome)}>Läs mer</button>
            </div>
       
            <div className="button-spacing">
              <button className="button" onClick={() => acceptCookies('acceptAll')}>Acceptera alla</button>
            </div>
            </>):
            (
              <>
       
            <div className="button-spacing">
              <button className="button" onClick={() => acceptCookies('acceptChosen')}>Acceptera valda</button>
            </div>
            <div className="button-spacing">
              <button className="button" onClick={() => acceptCookies('acceptAll')}>Acceptera alla</button>
            </div>
            </>
            )}
          </div>
        </div>
      </div>
    </div>

  );
};

export default CookieBanner;
