import React, { useState, useEffect } from 'react';
import { canonUrl, cookiesUrl, headers } from "../../../static/urls";
import DesktopComponent from "../../main";
import { colors } from '../../../static/colors';
import { mainLarge, mainSmall } from '../../../static/fonts';
import falt from "../../../static/newimgs/falt.webp"

import CanonicalTag from '../../components/canonicalTag';
import { useMediaQuery } from 'react-responsive';
import { setMeta } from '../screencomponents/widget/metadesc';

function Tack(props) {
  const canonicalUrl = canonUrl+ "/tack"

     /* window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('event', 'conversion_event_submit_lead_form_1', {
      })*/
         let data1={
           meta:{
             seo_title:props.sprak.tack.pagetitle,
             search_description:props.sprak.tack.pagedesc
           }
         }
         // Set meta description
         setMeta(data1)
      const isMobile = useMediaQuery({ maxWidth: 1100 });

    return (
      <DesktopComponent sprak={props.sprak}  light={true} locationRemoved={true}>
                                      <CanonicalTag canonicalUrl={canonicalUrl} />

                                      <div className="kontakt-container">
        {!isMobile ? (
      <div className='kontakt-sub-container-kontakt-desktop'>
   
   
   <div className="kontakt-info">
     <>
     <h3 className='kontakt-info-header'>Tack för att du hör av dig!</h3>
     <p className='kontakt-info-p'> Vi återkommer så snart vi kan! Vårt mål är att alltid svara inom en timme under arbetstid, om vi inte hinner svarar vi alltid inom 24h.
       </p>
       <br></br>
     </>
     <br></br>
     <img className="kontakt-info-img" src={falt} alt="En bild på oss"/>

     <br></br>
     <br></br>
 
   </div>

 </div>
        ):(
 <div className='kontakt-sub-container-kontakt-mobile'>
   
   
   <div className="kontakt-info">
 
     <>
     <img className="kontakt-info-img" src={falt} alt="En bild på oss"/>
 <br></br>
 <h3 className='kontakt-info-header'>Tack för att du hör av dig!</h3>
     <p className='kontakt-info-p'> Vi återkommer så snart vi kan! Vårt mål är att alltid svara inom en timme under arbetstid, om vi inte hinner svarar vi alltid inom 24h.
     </p>

     </>

 
   </div>
   <div className='kontakt-info-2'>

   </div>
 </div>
        )}
      </div>
      <br></br>
        <br></br>
         

</DesktopComponent>
    );
  }
export default Tack;
