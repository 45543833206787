import React, { useState, useEffect } from 'react';
import { canonUrl, cookiesUrl, headers } from "../../../static/urls";
import DesktopComponent from "../../main";
import CanonicalTag from '../../components/canonicalTag';
import { setMeta } from '../screencomponents/widget/metadesc';

function Cookies(props) {
  const canonicalUrl = canonUrl+ "/cookies"
  const [data, setData] = useState({});

  const { header, items, overviewTitle, overviewText } = props.sprak.cookies;


  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchDataFromAPI = async () => {
        try {
            const response = await fetch(cookiesUrl);
            const data = await response.json();
            setData(data);
        let data1={
                meta:{
                  seo_title:props.sprak.cookies.pagetitle,
                  search_description:props.sprak.cookies.pagedesc
                }
              }
              // Set meta description
              setMeta(data1)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    fetchDataFromAPI();
}, [cookiesUrl]);


  const containerStyle = {
    maxWidth: '1000px',
    paddingTop: '150px',

    paddingBottom: '150px',
    marginLeft: 'auto',
    width:"90%",
    marginRight: 'auto',
    minHeight:"80vh",
    textAlign: 'left', // Aligns text to the left for the entire container
  };

  const tableStyle = {
    width: '100%', // Ensures the table stretches to the width of the container
    borderCollapse: 'collapse', // Optional: for styling borders in tables
  };

  const headerStyle = {
    backgroundColor: '#f2f2f2', // Optional: Styles the header with a background color
    // Add more styles here as needed
  };

  const overviewStyle = {
    marginBottom: '20px', // Adds spacing between the overview text and the table
  };


    return (
      <DesktopComponent sprak={props.sprak} light={true}>
                                      <CanonicalTag canonicalUrl={canonicalUrl} />

        <div style={containerStyle}>
      <div style={overviewStyle}>
        <h1>{overviewTitle}</h1>
        <p>{overviewText}</p>
      </div>
      <table style={tableStyle}>
        <thead style={headerStyle}>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Time</th>

        </tr>
      </thead>
      <tbody>
        {data.keys && data.map((item) => (
          <tr key={item.id}>
            <td>{item.name}</td>
            <td>{item.description}</td>
            <td>{item.time}</td>
      
          </tr>
        ))}
        </tbody>
      </table>
    </div>
</DesktopComponent>
    );
  }
export default Cookies;
