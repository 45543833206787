import { useEffect, useState } from "react";
import DesktopComponent from "../../main";
import { canonUrl, fragor } from "../../../static/urls";
import ThirdHero from "../screencomponents/widget/heroes/thirdhero";
import Filter from "../../components/generalwidgets/filter";
import { mainSmall, secondaryMedium, secondaryMediumSmall } from "../../../static/fonts";
import { Link } from "react-router-dom";
import { colors } from "../../../static/colors";
import CanonicalTag from "../../components/canonicalTag";
import fragorimg from "../../../static/newimgs/fragor.webp"
import { useMediaQuery } from "react-responsive";

import litenfragorimg from "../../../static/newimgs/mobile/fragor.webp"

import ImageHero from "../screencomponents/widget/heroes/imagehero";
import { setMeta } from "../screencomponents/widget/metadesc";
let cta ={
  first_cta: "Ring Direkt",
first_cta_url
: 
"tel:+46703557548",
second_cta
: 
"Fyll i formulär",
second_cta_url
: 
"/kontakt"

}

function FrageLista(props) {
  let pageurl = fragor + "?language=" + props.sprak.sprak;
  const [blogData, setBlogData] = useState([])
  const [displayFilter, setDisplayFilter] = useState(false);
  const canonicalUrl = canonUrl+ props.sprak.frageLista.url

  const [kategori, setKategori] = useState(props.sprak.alla);
  useEffect(() => {
    const fetchDataFromAPI = async () => {
      window.scrollTo(0, 0);
      try {
        const response = await fetch(pageurl);
        const data = await response.json();

        setBlogData(data.results);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
   
    };

    fetchDataFromAPI();
    let data1={
      meta:{
        seo_title:props.sprak.frageLista.pagetitle,
        search_description:props.sprak.frageLista.pagedesc
      }
    }
  

    // Set meta description
    setMeta(data1)
  }, [pageurl]);

  const renderBlogCards = () => {
    return blogData.map((blog) => (
      <Link    style={{
        border: "1px solid #ccc",
        borderRadius: "5px",
        height: "150px",
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        marginBottom: "10px",
        maxWidth: "400px",
        textDecoration:"none",
        color:colors.textMain,
        padding: "0 20px",
        width: "100%"
      }} key={blog.id} to={props.sprak.frageLista.url+blog.slug}>
       
          <h4 style={{ ...secondaryMediumSmall, marginBottom: "10px", textAlign: "left" }}>
            {blog.title} &rarr;
          </h4>
  
        
 
      </Link>
    ));
  };

  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <DesktopComponent sprak={props.sprak}>
                                    <CanonicalTag canonicalUrl={canonicalUrl} />
      {!isMobile?
      <ImageHero title={props.sprak.frageLista.title} cta={cta} img={fragorimg} h2={props.sprak.frageLista.subtitle} text={props.sprak.frageLista.text} sprak={props.sprak}/>
      :
      <ImageHero title={props.sprak.frageLista.title} cta={cta} img={litenfragorimg} h2={props.sprak.frageLista.subtitle} text={props.sprak.frageLista.text} sprak={props.sprak}/>
      
    }
                                    

      
      {displayFilter && <Filter
        list={props.sprak.falt}
        sprak={props.sprak}
        setSelectedItem={setKategori}
        selectedItem={kategori}
      />}
                <div
        style={{
          margin:"100px auto",
          width:"80%",
          maxWidth:"800px", 
          display: "flex",

          flexDirection:"column",
          alignItems: "center",
          justifyContent: "center",
        }}
      > 
      
       
      </div>

      <div
        style={{
      
          margin:"50px auto",
          display: "flex",
          flexWrap:"wrap",
          width:"90%",
          maxWidth:"1200px",
          alignItems: "center",
          justifyContent: "center",
          gap:"20px",        }}
      >
 
        {blogData.length ==0 ? (
       <div style={{display:"flex", flexDirection:"column", marginTop:"-40px", marginBottom:"20px"}}>
       <h2>... Ehm, pinsamt.. Ursäkta!</h2>
       <p> Vår tekniker jobbar på det!</p>
       </div>
       ):
         renderBlogCards()}
      </div>
    </DesktopComponent>
  );
}


export default FrageLista;
